import { Grey } from '@/styles/Colors';
import styled from '@emotion/styled';

export const SelectContainer = styled.div`
  position: relative;
  width: 129px;
  user-select: none;
  z-index: 50;
`;

export const SelectedOption = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
  color: ${Grey[100]};

  svg {
    transition: transform 0.3s ease;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  }
`;

export const OptionsList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 11px;
  background-color: ${Grey[500]};
  border-radius: 8px;
  overflow: hidden;
  padding: 6px 0;

  visibility: hidden;
  transform-origin: top;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(-10px);
  opacity: 0;

  &.open {
    visibility: visible;
    transform: scaleY(1);
    opacity: 1;
  }
`;

export const Option = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${Grey[50]};
  &:hover {
    background-color: ${Grey[450]};
  }

  svg {
    width: 24px;
    height: 24px;
    fill: ${Grey[50]};
  }
`;
