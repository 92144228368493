import { StyledChip } from '@/components/Chip/StyledChip';

interface ChipProps {
  color: 'primary' | 'secondary';
  label: string;
  onClick?: () => void;
}

export default function Chip({
  color = 'secondary',
  label,
  onClick,
}: ChipProps) {
  return (
    <StyledChip color={color} onClick={onClick}>
      {label}
    </StyledChip>
  );
}
