import { Grey, Primary } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

const StyledPayModal = styled.section`
  .pay-modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 7.2rem;
    gap: 0.8rem;
    .title {
      font-size: 1.6rem;
      font-weight: ${FontWeight['SemiBold']};
    }
    .period {
      font-size: 1.1rem;
      color: ${Grey[400]};
    }
  }
  .pay-modal-body {
    width: 46rem;
    height: 8rem;
    display: flex;
    margin: 3rem 0;
    .left-side {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      border-right: 1px solid ${Grey[500]};
      font-size: ${FontSize['Xl']};
      line-height: ${FontSize['3Xl']};
      color: ${Grey[100]};
      gap: 1rem;
      padding: 0 1.6rem;

      .offer-desc {
        display: list-item;
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 1.4rem;
      }
    }
    .right-side {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      align-items: center;
      .original-price {
        font-size: 2.4rem;
        color: ${Grey[400]};
      }
      .current-price {
        font-size: 4.6rem;
        color: ${Grey[50]};
      }
      .price-description {
        font-size: 1rem;
        color: ${Primary[400]};
        display: flex;
        text-align: center;
        padding: 0 3.6rem;
      }
    }
  }

  .pay-modal-footer {
    display: flex;
    padding: 0 2rem 2rem 0;
    .left-side {
      display: flex;
      flex-basis: 50%;
      align-items: center;
      font-size: ${FontSize['Xl']};
      line-height: ${FontSize['3Xl']};
      color: ${Grey[100]};
      padding: 0 1.6rem;
      .link {
        cursor: pointer;
        color: ${Primary[400]};
        text-decoration: underline;
        &:hover {
          color: ${Primary[200]};
        }
      }
    }
    .right-side {
      display: flex;
      flex-basis: 50%;
      align-items: center;
      justify-content: end;
      .pay-button {
        display: flex;
        justify-items: center;
        align-items: center;
        width: 14rem;
        height: 3.6rem;
        font-size: 1.2rem;
      }
    }
  }
`;

export default StyledPayModal;
