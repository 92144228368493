import ConfirmModal from '@/components/Modal/ConfirmModal/ConfirmModal';
import useUserTier from '@/hooks/useUserTier';
import ErrorIcon from '@/pages/screenplay/assets/images/ErrorIcon.png';
import {
  currentProjectAtom,
  lineListAtom,
  takeListAtom,
} from '@/pages/screenplay/stores/atoms/project';
import { useVoiceLibraryQuery } from '@/query/useVoiceLibraryQuery';
import { Secondary } from '@/styles/Colors';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactComponent as ImportExportScriptIcon } from '../assets/icons/ImportExportScript.svg';
import { ReactComponent as LineDeleteIcon } from '../assets/icons/LineDeleteIcon.svg';
import { ReactComponent as LineDownLoadIcon } from '../assets/icons/LineDownloadIcon.svg';
import { ReactComponent as LineSelectAllIcon } from '../assets/icons/LineSelectAllIcon.svg';
import { useLog } from '../hooks/useLog/useLog';
import { useEditorContext } from '../providers/EditorContextProvider';
import { audioFileMapAtom } from '../stores/atoms/audio';
import {
  selectedLineIdsAtom,
  showPayModalAtom,
  showScriptModalAtom,
} from '../stores/atoms/ui';
import { AudioFile } from '../stores/audios';
import { Line } from '../stores/project';
import { convertAudioToDownloadList } from '../utils/files';
import StyledDeleteConfirmModal from './StyledDeleteConfirmModal';

const LineControl = () => {
  const { isTrial, isNeedPurchasing } = useUserTier();
  const setShowPayModal = useSetRecoilState(showPayModalAtom);
  const setShowScriptModal = useSetRecoilState(showScriptModalAtom);
  const { t } = useTranslation();
  const audioFileMap = useRecoilValue(audioFileMapAtom);
  const { removeSelectedLines, toggleAllLine, selectedTakeByLine } =
    useEditorContext();
  const { voiceProfileList } = useVoiceLibraryQuery();

  const selectedLineIds = useRecoilValue(selectedLineIdsAtom);
  const project = useRecoilValue(currentProjectAtom);
  const lineList = useRecoilValue(lineListAtom);
  const takeList = useRecoilValue(takeListAtom);
  const selectedLines = useMemo(
    () =>
      selectedLineIds.map((id) => {
        const index = lineList.findIndex((line) => line.id === id);
        const displayIndex =
          project?.lineOrder.findIndex((lineId) => lineId === id) ?? 0;
        const line = lineList[index];
        return {
          ...line,
          lineNumber: displayIndex + 1,
        };
      }),
    [selectedLineIds, lineList, project?.lineOrder]
  );
  const downloadList = useMemo(() => {
    return (selectedLines as (Line & { lineNumber: number })[]).map((line) => ({
      id: selectedTakeByLine[line.id] as string,
      lineId: line.id,
      name: `Line-${line.lineNumber}_${takeList
        .find((take) => take.id === selectedTakeByLine[line.id])
        ?.text.slice(0, 128)}_${new Date()}`,
    }));
  }, [selectedLines, selectedTakeByLine, takeList]);

  const list = downloadList.reduce((acc, next) => {
    if (audioFileMap[next.id]) {
      acc.push({ ...audioFileMap[next.id], ...next });
    }
    return acc;
  }, [] as (AudioFile & { id: string; lineId: string; name: string })[]);
  const { track } = useLog();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const onClickDelete = useCallback(() => {
    if (selectedLines.some((line) => selectedTakeByLine[line.id])) {
      return setIsDeleteModalOpen(true);
    } else {
      removeSelectedLines();
    }
  }, [selectedLines, selectedTakeByLine, removeSelectedLines]);
  const onClickDownload = useCallback(() => {
    if (!isTrial && isNeedPurchasing) {
      track('PURCHASE_DOWNLOAD', {});
      setShowPayModal(true);
      return;
    }

    const audioList = list.map((audio) => {
      return {
        ...audio,
        fileName: `${audio.name}`,
      };
    });
    const files = convertAudioToDownloadList(audioList);
    let num = 0;
    const interval = setInterval(() => {
      const file = files[num];
      const audio = audioList[num];
      const blob = new Blob([file.buffer], { type: 'audio/wav' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${file.name}`;
      a.click();
      const take = takeList.find((take) => take.id === audio.id);
      const profile = voiceProfileList?.find((profile) =>
        profile.voices.find((voice) => voice.id === take?.voiceId)
      );
      track('DOWNLOAD_TAKE', {
        lineId: audio.lineId,
        takeId: audio.id,
        resourceId: take?.resourceId,
        projectId: take?.projectId,
        text: take?.text,
        voiceId: take?.voiceId,
        language: take?.language,
        type: take?.type,
        voice_name: profile?.name,
        pitch_shift: take?.parameter?.pitch_shift,
        pitch_variance: take?.parameter?.pitch_variance,
        speed: take?.parameter?.speed,
        audio_length: audioFileMap[audio.id as string]?.audioBuffer.duration,
        style: profile?.voices.find((voice) => voice.id === take?.voiceId)
          ?.style,
      });
      num++;
      if (num >= files.length) clearInterval(interval);
    }, 200);
  }, [
    isTrial,
    isNeedPurchasing,
    list,
    track,
    setShowPayModal,
    takeList,
    voiceProfileList,
    audioFileMap,
  ]);
  return (
    <section className="sp-scene-control">
      <button
        className={classNames('button-reset btn-control btn-select-all', {
          active:
            selectedLineIds.length &&
            selectedLineIds.length === lineList.length,
        })}
        onClick={() => toggleAllLine()}
      >
        <LineSelectAllIcon />
        Select All
      </button>
      <button
        className="button-reset btn-control btn-import-export"
        onClick={(e) => {
          e.stopPropagation();
          setShowScriptModal(true);
        }}
      >
        Import or Export Script
        <ImportExportScriptIcon />
      </button>
      <div className="space" />
      <button
        className="button-reset btn-control btn-download"
        disabled={!selectedLineIds.length || !list.length}
        onClick={onClickDownload}
      >
        Download
        <LineDownLoadIcon />
      </button>
      <button
        className="button-reset btn-control btn-delete"
        disabled={!selectedLineIds.length}
        onClick={onClickDelete}
      >
        Delete
        <LineDeleteIcon />
      </button>
      {isDeleteModalOpen && (
        <StyledDeleteConfirmModal>
          <ConfirmModal
            isPortal={false}
            isOpen={isDeleteModalOpen}
            title={
              <>
                <img
                  src={ErrorIcon}
                  alt="Error"
                  style={{
                    width: '1.125rem',
                    height: '1.125rem',
                    marginRight: '0.5rem',
                  }}
                />
                <span>{t('Are you sure to Delete?')}</span>
              </>
            }
            message={t(
              "The selected line contains Takes you've created. If you continue, the generated Takes will be deleted."
            )}
            onConfirm={() => {
              removeSelectedLines();
              setIsDeleteModalOpen(false);
            }}
            onCancel={() => setIsDeleteModalOpen(false)}
            confirmText={t('Delete Anyway') as string}
            confirmButtonColor={Secondary[300]}
          />
        </StyledDeleteConfirmModal>
      )}
    </section>
  );
};
export default LineControl;
