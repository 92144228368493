import FileName from '@/pages/screenplay/assets/icons/FileName.svg';
import { Black, Grey, Primary, Secondary, White } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import CheckIcon from '../assets/icons/CheckIcon.svg';

export const StyledTakeGeneratePanel = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  background-color: ${Grey[700]};
  .take-generate-header {
    display: flex;
    padding: 1rem;
    font-weight: ${FontWeight['SemiBold']};
    font-size: 1rem;
    border-bottom: 1px solid ${Grey[500]};
    justify-content: space-between;
  }
  .take-generate-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    &.disabled-tts {
      .generate-count-selector {
        display: none;
      }
      .area-btn-generate {
        .btn-generate {
          width: 100%;
        }
      }
    }
  }
  .take-generate-content-section {
    border-top: 1px solid ${Grey[600]};
    :first-of-type {
      border-top: none;
    }
    &.take-voice-setting {
      display: flex;
      padding: 1.5rem 2rem;
      flex-direction: column;
      gap: 1.25rem;
      .take-voice-setting-item {
        .setting-title {
          font-size: 1rem;
          width: 35%;
          font-weight: 500;
        }
        .dropdown-area {
          width: 65%;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        .take-voice-setting-dropdown {
          cursor: pointer;
          background-color: ${Black};
          padding: 0 1.38rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 2.5rem;
          .drop-down-text {
            color: ${White};
            font-size: 1rem;
          }
          .toggle-icon {
            width: 1.88rem;
            height: 1.88rem;
            margin-right: -1rem;
          }
        }
      }
    }
  }
  .take-speech-control {
    padding: 0.75rem 0.5rem;
    .slider-control {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      border-radius: 0.625rem;
      padding: 1.5rem;
      &.speed {
        .slider-control-header {
          .slider-value {
            &::before {
              content: 'x ';
            }
          }
        }
        .slider-control-content {
          .slider-track {
            display: none !important;
          }
        }
      }
      &.disabled {
        filter: grayscale(1);
        .slider-control-content {
          .control-min,
          .control-max {
            color: ${Grey[450]};
          }
          .slider-track,
          .slider-thumb {
            background-color: ${Grey[500]} !important;
          }
          .slider-range {
            background-color: ${Grey[500]} !important;
          }
        }
      }
      .slider-control-header {
        display: flex;
        justify-content: space-between;
        .sup-stepper-button {
          display: none;
        }
      }
      .slider-control-title {
        color: ${White};
      }
      .slider-value {
        width: 3.5rem;
        height: 1.25rem;
        flex-shrink: 0;
        text-align: center;
        border-radius: 0.0625rem;
        background: ${Grey[600]};
        color: ${Grey[400]};
        font-weight: ${FontWeight['SemiBold']};
        font-size: 0.875rem;
        padding-top: 0.25rem;
      }
      .slider-control-content {
        display: flex;
        gap: 0.25rem;
        .control-min,
        .control-max {
          width: 2rem;
          font-size: ${FontSize['Md']};
          text-align: center;
        }
        .slider-thumb {
          box-shadow: 0px 0px 5px 0px #080b11;
        }
        .slider-thumb,
        .slider-track {
          background: ${White} !important;
        }
        .slider-track {
          height: 2px !important;
        }
        .slider-range::-webkit-slider-thumb:hover {
          box-shadow: none !important;
          background: none !important;
        }
      }
    }
  }
  .take-cvc-control-buttons {
    width: 100%;
    margin-bottom: 0.25rem;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    .btn-cvc-layer-open {
      border-radius: 0;
      height: 2.75rem;
      border: 0;
      color: ${Grey[400]};
      font-size: 0.9rem;
      font-weight: ${FontWeight['Regular']};
      &:hover {
        background-color: ${Grey[600]};
      }
    }
  }
  .area-btn-generate {
    width: 100%;
    .btn-generate {
      width: 100%;
      height: 3rem;
      font-size: 1rem;
      border-radius: 0;
    }
  }
  .cvc-modal-wrapper {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    .modal-overlay {
      background-color: rgba(18, 18, 18, 0.6);
    }
  }
`;

export const StyledCVCModal = styled.section`
  &.modal-cvc-layer {
    width: 43.8rem;
    border-radius: 0.75rem;
    border: 1px solid ${Grey[200]};
    background: rgba(22, 22, 22, 0.8);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.48);
    backdrop-filter: blur(2px);
  }
  .cvc-layer-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.88rem 1.5rem 0;
    .cvc-layer-title-left {
      display: flex;
      align-items: center;
      .voice-icon {
        width: 2.625rem;
        height: 2.625rem;
      }
      .title-text {
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        margin-left: 0.5rem;
      }
    }
    .cvc-layer-title-right {
      .btn-close {
        width: 2.33038rem;
        height: 2.33088rem;
      }
    }
  }
  .modal-cvc-content {
    display: flex;
    min-height: 17.56rem;
    .content-wrapper {
      width: 100%;
      .item-uploader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem 2rem;
        .uploader-item {
          overflow: hidden;
          width: 11.875rem;
          height: 10.3rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.37rem;
          flex: 1;
          .uploader-item-title {
            font-size: 1rem;
            white-space: nowrap;
          }
          .btn-reset {
            cursor: pointer;
            background-color: transparent;
            border: 0;
            outline: 0;
            padding: 0;
          }
          &.record {
            color: ${Secondary[300]};
            &:hover {
              .btn-record {
                border: 1px solid ${Secondary[200]};
                color: ${Secondary[200]};
              }
            }
            .btn-record {
              display: flex;
              width: 11.875rem;
              padding: 0.375rem 0.8125rem 0.375rem 0.75rem;
              justify-content: center;
              align-items: center;
              gap: 0.9375rem;
              border-radius: 0.125rem;
              border: 1px solid ${Secondary[300]};
              color: ${Secondary[300]};
              font-size: 1rem;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
            }
            .record-status {
              width: 100%;
              color: ${Grey[300]};
            }
          }
          &.add-file {
            color: ${Primary[400]};
            .btn-add-file {
              &:hover {
                opacity: 0.5;
              }
              padding: 0.375rem 0 !important;
              display: inline-flex;
              width: 11.875rem;
              justify-content: center;
              align-items: center;
              gap: 0.5rem;
              border-radius: 0.125rem;
              background: ${Primary[400]};
              border: 1px solid ${Primary[400]};
              color: ${White};
              font-size: 1rem;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
            }
          }
        }
      }
    }
  }
  .record {
    .recorder {
      display: flex;
      width: 11.875rem;
      padding: 0.375rem 0.8125rem 0.375rem 0.75rem;
      justify-content: center !important;
      align-items: center !important;
      gap: 0.9375rem;
      border-radius: 0.125rem;
      border: 1px solid ${Secondary[300]} !important;
      color: ${Secondary[300]} !important;
      background-color: transparent !important;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
    .modal-cvc-footer {
      justify-content: center;
    }
  }
  .modal-cvc-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2rem 0rem;
    .sup-button {
      border-radius: 0.125rem;
      font-size: ${FontSize['Lg']};
      height: 2.5rem;
      &:has(.sup-button-icon) {
        padding: 0.375rem 0.5rem;
      }
      .sup-button-icon {
        margin-right: 0.75rem;
        width: 1.5rem;
      }
    }
    .left-area {
      display: flex;
      align-items: center;
      .btn-file-play {
        min-width: 7.25rem;
        background-color: transparent;
        svg {
          transform: scale(1.2);
        }
        .sup-loading-dots {
          border: none;
        }
      }
      &:has(.sup-loading-dots) {
        .sup-button-icon {
          display: none;
        }
      }
    }
    .right-area {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      .file-record-button {
        background-color: transparent !important;
        color: ${Secondary[300]} !important;
      }
    }
    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-cancel {
        display: flex;
        width: 10rem;
        height: 2.5rem;
        padding: 0.375rem 1.75rem;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 0.125rem;
      }
    }
  }
  .item-state {
    margin: 4.75rem 0 3.75rem;
    text-align: center;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    align-items: center;
    color: ${Grey[300]};
    .result-title {
      .file-name {
        display: inline-flex;
        gap: 0.5rem;
        &::before {
          content: ' ';
          display: block;
          width: 1rem;
          height: 1.25rem;
          background-image: url(${FileName});
          background-size: cover;
        }
      }
    }
    &.fetching {
      .fetching-title {
        position: relative;
        display: inline-flex;
        padding: 0 3rem;
      }
    }
  }
`;

export const StyledDropDownList = styled.div`
  .sup-dropdown-item {
    padding: 0.5rem 1rem;
    cursor: pointer;
    &:hover {
      background-color: ${Grey[400]};
    }
    &.selected {
      display: flex;
      justify-content: space-between;
      .sup-dropdown-item-badge-label {
        width: 0.75rem;
        height: 0.75rem;
        display: inline-block;
        margin-right: 0.25rem;
        color: ${Black};
        background-image: url(${CheckIcon});
        background-size: cover;
      }
    }
  }
`;
