import { ReactComponent as Linkout } from '@/assets/icons/Linkout.svg';
import Button from '@/components/Button/Button';
import useIsMobile from '@/hooks/useIsMobile';
import { ReactComponent as Logo } from '@/pages/screenplay/assets/images/PlayLogo.svg';
import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import { MAIN_WEB_URL } from '@/pages/screenplay/stores/data/config';
import { Grey, Primary } from '@/styles/Colors';
import UserInfo from '@/user/UserInfo';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { useTranslation } from 'react-i18next';

const CommonHeader = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useSUPAuth();
  const { t } = useTranslation();
  const redirectUri = window.location.origin + '/after-signup';
  const { track } = useLog();
  const isMobile = useIsMobile();

  return (
    <header className="layout-header">
      <section className="navbar-left">
        <div className="header-logo">
          <Logo className="header-logo-svg" />
          <div className="logo-linkout">
            <Linkout
              onClick={() => {
                window.open(MAIN_WEB_URL, '_blank')?.focus();
              }}
              className="logo-linkout-svg"
              fill={Grey[200]}
            />
            <div className="header-logo-tooltip">
              {t('header.outlinkHover')}
            </div>
          </div>
        </div>
      </section>
      <section className="navbar-right">
        {!isLoading && !isAuthenticated && (
          <>
            <Button
              size={isMobile ? 'sm' : 'xl'}
              variant="outlined"
              color={Grey['50']}
              className="btn-login"
              onClick={() => {
                track('CLICK_LOGIN');
                loginWithRedirect({
                  authorizationParams: {
                    redirect_uri: redirectUri,
                  },
                });
              }}
            >
              {t('common.login')}
            </Button>
            <Button
              size={isMobile ? 'sm' : 'xl'}
              className="btn-login sign-up"
              color={Primary['400']}
              onClick={() => {
                track('CLICK_SIGNUP');
                loginWithRedirect({
                  authorizationParams: {
                    screen_hint: 'signup',
                    redirect_uri: redirectUri,
                  },
                });
              }}
            >
              {t('common.register')}
            </Button>
          </>
        )}
        <UserInfo />
      </section>
    </header>
  );
};
export default CommonHeader;
