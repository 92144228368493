import { ReactComponent as Linkout } from '@/assets/icons/Linkout.svg';
import Button from '@/components/Button/Button';
import { StyledToastMessage } from '@/components/ToastMessage/StyledToastMessage';
import { toastMessageAtom } from '@/pages/screenplay/stores/atoms/ui';
import { SUPERTONE_API_URL } from '@/pages/screenplay/stores/data/config';
import { Grey } from '@/styles/Colors';
import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { ReactComponent as BlueCheckIcon } from '../../pages/screenplay/assets/icons/BlueCHeckIcon.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/CloseIcon.svg';

const ToastMessage = () => {
  const autoHideTIme = 3000;
  const [toastMessage, setToastMessage] = useRecoilState(toastMessageAtom);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // 이전 타이머를 클리어하여 타이머를 초기화
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // 새 타이머를 설정
    timerRef.current = setTimeout(() => {
      setToastMessage(''); // 3초 후에 컴포넌트 숨기기
    }, autoHideTIme);

    // 클린업: 컴포넌트가 언마운트되거나 리렌더링될 때 타이머 정리
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  });

  return (
    <StyledToastMessage>
      <div className="header">
        <div className="title">
          <BlueCheckIcon />
          {toastMessage}
        </div>
        <Button
          variant={'none'}
          color={Grey[300]}
          size={'md'}
          onClick={() => {
            setToastMessage('');
          }}
          endIcon={<CloseIcon />}
        />
      </div>
      <div className="description">
        <p className="text">Copied! Use them on the Supertone API.</p>
        <div className="link">
          <Button
            variant={'none'}
            fontWeight={900}
            size={'lg'}
            endIcon={<Linkout />}
            onClick={() => {
              window.open(SUPERTONE_API_URL, '_blank')?.focus();
            }}
          >
            Go to Supertone API
          </Button>
        </div>
      </div>
    </StyledToastMessage>
  );
};

export default ToastMessage;
