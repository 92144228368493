import CommonLayout from '@/pages/screenplay/components/CommonLayout';
import DesktopFooter from '@/pages/screenplay/Home/DesktopFooter';
import HomeContainer from '@/pages/screenplay/Home/HomeContainer';
import MobileFooter from '@/pages/screenplay/Home/MobileFooter';
import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import { UTM_SESSION_STORAGE_KEY } from '@/pages/screenplay/stores/data/config';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const HomePage = () => {
  const location = useLocation();
  const { track } = useLog();
  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    if (params.size > 0) {
      const queryObject = Object.fromEntries(params.entries());
      const utms = {
        utm_campaign: queryObject.utm_campaign ?? '',
        utm_content: queryObject.utm_content ?? '',
        utm_medium: queryObject.utm_medium ?? '',
        utm_source: queryObject.utm_source ?? '',
      };
      track('LANDING_HOME', utms);
      window.sessionStorage.setItem(
        UTM_SESSION_STORAGE_KEY,
        JSON.stringify(utms)
      );
    } else {
      track('LANDING_HOME');
    }
  }, [location.search, track]);
  return (
    <CommonLayout>
      <HomeContainer />
      <div className="desktop-footer">
        <DesktopFooter />
      </div>
      <div className="mobile-footer">
        <MobileFooter />
      </div>
    </CommonLayout>
  );
};

export default HomePage;
