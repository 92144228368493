import { MOBILE_BREAKPOINT } from '@/consts/breakpoints';
import { Grey } from '@/styles/Colors';
import styled from '@emotion/styled';

export const StyledVoiceList = styled.div`
  margin-top: 24px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 20px;
  }
`;

export const StyledVoiceListEmpty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  background-color: ${Grey[600]};
  border-radius: 100px;
  height: 74px;
  margin-top: 24px;

  .text {
    color: ${Grey[200]};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;
