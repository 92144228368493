import { ReactComponent as InstagramIcon } from '@/assets/icons/insta.svg';
import { ReactComponent as TictokIcon } from '@/assets/icons/tictok.svg';
import { ReactComponent as YoutubeIcon } from '@/assets/icons/youtube.svg';
import SupButton from '@/components/Button/SupButton';
import {
  SUPERTONE_INSTAGRAM_URL,
  SUPERTONE_TICTOK_URL,
  SUPERTONE_YOUTUBE_URL,
} from '@/pages/screenplay/stores/data/config';
import React from 'react';

export default function PromotionLink() {
  return (
    <div className="link-container">
      <SupButton
        color="grey"
        width="40px"
        height="40px"
        borderRadius="50%"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          window.open(SUPERTONE_INSTAGRAM_URL, '_blank')?.focus();
        }}
      >
        <InstagramIcon />
      </SupButton>

      <SupButton
        color="grey"
        width="40px"
        height="40px"
        borderRadius="50%"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          window.open(SUPERTONE_YOUTUBE_URL, '_blank')?.focus();
        }}
      >
        <YoutubeIcon />
      </SupButton>

      <SupButton
        color="grey"
        width="40px"
        height="40px"
        borderRadius="50%"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          window.open(SUPERTONE_TICTOK_URL, '_blank')?.focus();
        }}
      >
        <TictokIcon />
      </SupButton>
    </div>
  );
}
