import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { isShowLineBannerAtom } from '../stores/atoms/ui';
import { StyledSceneWriterLayout } from '../styles/StyledSceneWriterLayout';
import SceneWriterHeader from './SceneWriterHeader';

const SceneWriterLayout: React.FC<PropsWithChildren> = () => {
  const isShowLineBanner = useRecoilValue(isShowLineBannerAtom);
  return (
    <StyledSceneWriterLayout
      className={isShowLineBanner ? 'line-banner-open' : ''}
    >
      <SceneWriterHeader />
      <section className="layout-content">
        <Outlet />
      </section>
    </StyledSceneWriterLayout>
  );
};
export default SceneWriterLayout;
