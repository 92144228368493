import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { isShowVoiceLibraryAtom } from '../atoms/ui';

export const useToggleVoiceLibrary = () => {
  const setIsShowVoiceLibrary = useSetRecoilState(isShowVoiceLibraryAtom);
  const toggleVoiceLibrary = useCallback(
    () => setIsShowVoiceLibrary((prev) => !prev),
    [setIsShowVoiceLibrary]
  );

  const showVoiceLibrary = useCallback(() => {
    setIsShowVoiceLibrary(true);
  }, [setIsShowVoiceLibrary]);

  const closeVoiceLibrary = useCallback(() => {
    setIsShowVoiceLibrary(false);
  }, [setIsShowVoiceLibrary]);
  return {
    toggleVoiceLibrary,
    showVoiceLibrary,
    closeVoiceLibrary,
  };
};
