import {
  activeLineSelector,
  currentProjectAtom,
  lineListAtom,
} from '@/pages/screenplay/stores/atoms/project';
import { useRecoilValue } from 'recoil';

import { ReactComponent as LineAddIcon } from '../assets/icons/LineAddIcon.svg';
import TakeGeneratePanel from '../ControlPanel/TakeGeneratePanel';
import { useEditorContext } from '../providers/EditorContextProvider';
import StyledSceneWriterPanel from '../styles/StyledSceneWriterPanel';
import LineControl from './LineControl';
import LineItem from './LineItem';

const SceneWriterPanel = () => {
  const { addLine, selectedTakeByLine } = useEditorContext();
  const currentProject = useRecoilValue(currentProjectAtom);
  const lineList = useRecoilValue(lineListAtom);
  const activeLine = useRecoilValue(activeLineSelector);

  return (
    <>
      <StyledSceneWriterPanel className="line-active">
        <section className="sp-top-panel sp-scene-writer">
          <section className="sp-scene-title">
            <LineControl />
            {!lineList.length && (
              <section className="line-add">
                <button className="add" onClick={() => addLine()}>
                  <LineAddIcon />
                </button>
              </section>
            )}
          </section>
          <section className="sp-scene-content">
            <section className="sp-scene-content-list">
              {!!lineList.length && (
                <ul>
                  {currentProject?.lineOrder.map((lineId, index) => {
                    const targetLine = lineList.find(
                      (line) => line.id === lineId
                    );
                    return (
                      targetLine && (
                        <LineItem
                          key={targetLine.id}
                          line={targetLine}
                          num={index + 1}
                        />
                      )
                    );
                  })}
                </ul>
              )}
            </section>
          </section>
        </section>
      </StyledSceneWriterPanel>
      {activeLine &&
        (activeLine.draft?.text || selectedTakeByLine[activeLine.id]) && (
          <section className="scene-writer-right-panel">
            <TakeGeneratePanel />
          </section>
        )}
    </>
  );
};

export default SceneWriterPanel;
