export const AUDIO_FILE_ACCEPT = '.mp3,audio/wav';
export const EXPIRES_PARAM = 'X-Amz-Expires';

export const DEFAULT_LANGUAGE = 'en' as const;
export const KAKAO_SDK_KEY = '4118e8e4e350fb0289714f6a17abc1c4';
export const PUBLIC_ENDPOINTS = [
  '/casting-view/list-voice-profiles',
  '/casting-view/generate-preview-tts',
];

//home 에서 사용하는 스타일 목록
export const HOME_STYLES = [
  'Active',
  'Admiring',
  'Advertisement',
  'Amused',
  'Angry',
  'Announcements',
  'Annoyed',
  'Anxious',
  'Audio Book',
  'Bad',
  'Blank',
  'Confused',
  'Courageous',
  'Curious',
  'Disgusted',
  'Documentary',
  'Dominating',
  'Embarrassed',
  'Evil',
  'Excited',
  'Flirty',
  'Friendly',
  'Happy',
  'Jealous',
  'Live',
  'Loving',
  'Merciful',
  'Narration',
  'Normal',
  'Painful',
  'Relieved',
  'Sad',
  'Safety Education',
  'Satisfied',
  'Seductive',
  'Serene',
  'Shouting',
  'Shy',
  'Sleepy',
  'Soulless',
  'Submissive',
  'Surprised',
  'Suspicious',
  'Teasing',
  'Tired',
  'Triumphant',
  'Trusting',
  'Unfriendly',
  'Vtuber',
  'Worry',
  'Youtube',
];

export const HOME_INITIAL_STYLES = [
  'Happy',
  'Unfriendly',
  'Angry',
  'Curious',
  'Normal',
  'Suspicious',
  'Loving',
  'Sad',
  'Amused',
  'Active',
];

export const HOME_STYLES_COUNT = 10;
