import { atom, selector } from 'recoil';

import { Line, Project, Take } from '../project';

export const takeJobIdMapAtom = atom<Record<string, string>>({
  key: 'takeJobIdMap',
  default: {},
});

export const lineListAtom = atom<Line[]>({
  key: 'lineList',
  default: [],
});

export const takeListAtom = atom<Take[]>({
  key: 'takeList',
  default: [],
});

export const currentProjectAtom = atom<Project | null>({
  key: 'currentProject',
  default: null,
});

export const activeLineIdAtom = atom<string | null>({
  key: 'activeLineId',
  default: null,
});

export const activeLineSelector = selector<Line | null>({
  key: 'activeLineSelector',
  get: ({ get }) => {
    const activeLineId = get(activeLineIdAtom);
    const lineList = get(lineListAtom);
    return lineList.find((line) => line.id === activeLineId) || null;
  },
});
