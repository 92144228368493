import { Black, Grey } from '@/styles/Colors';
import styled from '@emotion/styled';
export const StyledApiCopy = styled.section`
  position: absolute;
  right: 2rem;
  top: 3.5rem;
  .api-copy {
    display: flex;
    flex-direction: column;
    background-color: ${Black};
    border-radius: 0.5rem;
    button {
      height: 3rem;
      display: flex;
      justify-content: left;
      :hover {
        background-color: ${Grey[400]};
      }
    }
  }
`;
