import { ReactComponent as IosShareIcon } from '@/assets/icons/IosShare.svg';
import { SupModal } from '@/components/Modal/SupModal';
import { SupSelect } from '@/components/Select/SupSelect';
import Sns from '@/components/Share/Sns';
import { Language } from '@/pages/screenplay/stores/data/config';
import { StyledHeader } from '@/pages/screenplay/styles/StyledHeader';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function Header() {
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const { i18n, t } = useTranslation();

  const languageOptions = [
    { value: 'ko', label: '한국어' },
    { value: 'en', label: 'English' },
    { value: 'ja', label: '日本語' },
  ];

  const handleLanguageChange = (value: Language) => {
    i18n.changeLanguage(value);
  };

  return (
    <StyledHeader>
      <div className="header-container">
        <div className="title-container">
          <div className="title1">{t('home.colorTitle')} </div>
          <div className="title2">{t('home.title')}</div>
        </div>
        <div className="button-container">
          <div className="share" onClick={() => setIsOpenShareModal(true)}>
            <IosShareIcon />
            {t('common.share')}
          </div>
          <SupSelect
            options={languageOptions}
            value={i18n.language}
            onChange={(value) => handleLanguageChange(value as Language)}
          />
        </div>
      </div>
      <SupModal
        isOpen={isOpenShareModal}
        onClose={() => setIsOpenShareModal(false)}
        title={t('common.share') || ''}
        width="295px"
        height="151px"
      >
        <Sns
          title={t('share.title')}
          xHashTags={['tts', 'supertone', 'supertoneplay']}
          description={t('share.description')}
          link={`${window.location.origin}`}
          imageUrl={`${window.location.origin}/playKakaoShare.png`}
          kaKaoImageWidth={600}
          kaKaoImageHeight={600}
          kakaoShareButtonTitle={'수퍼톤 플레이 가기'}
        />
      </SupModal>
    </StyledHeader>
  );
}
