import { StyledImageButton } from './StyledImageButton';

interface ButtonProps {
  backgroundImage: string;
  onClick?: () => void;
}

const ImageButton: React.FC<ButtonProps> = ({ backgroundImage, onClick }) => {
  return (
    <StyledImageButton onClick={onClick} backgroundImage={backgroundImage}>
      <div />
    </StyledImageButton>
  );
};

export default ImageButton;
