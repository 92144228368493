import PermissionError from '@/error/PermissionError';
import { useLocalStorage } from '@/pages/screenplay/hooks/useLocalStorage';
import { useToggleVoiceLibrary } from '@/pages/screenplay/stores/recoilHooks/useToggles';
import { WS_EVENTS, useWebSocketContext } from '@/providers/WebSocketProvider';
import { useVoiceLibraryQuery } from '@/query/useVoiceLibraryQuery';
import { fetchAudio, getAudioBuffer } from '@/util/audio';
import insertAfter from '@/util/insertAfter';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import useAxios from '../hooks/useAxios';
import { useLog } from '../hooks/useLog/useLog';
import { audioFileMapAtom } from '../stores/atoms/audio';
import {
  activeLineIdAtom,
  currentProjectAtom,
  lineListAtom,
  takeListAtom,
} from '../stores/atoms/project';
import { selectedLineIdsAtom, showPayModalAtom } from '../stores/atoms/ui';
import { DEFAULT_PARAMETER, Language } from '../stores/data/config';
import {
  AudioBufferTake,
  Draft,
  ExtendParameter,
  Line,
  Parameter,
  Project,
  Take,
} from '../stores/project';
import { useDataContext } from './DataContextProvider';

export interface TakeOption {
  type: 'cvc' | 'tts';
  text: string;
  voiceId: string;
  language: Language;
  parameter: {
    pitch_shift: number;
    pitch_variance: number;
    speed: number;
    age: number;
    gender: number;
  };
  style?: string;
  sourceId?: string;
  resourceId?: string;
}
interface EditorContextProps {
  updateProject: (project: Partial<Project>) => Promise<void>;
  addLine: (line?: Line) => Promise<Line>;
  addLinesToLineList: (lines: Partial<Line>[]) => void;
  updateDraft: (lineId: string, draft: Draft) => void;
  updateLine: (line: Partial<Line>) => Promise<void>;
  updateLineList: (line: Line) => void;
  removeSelectedLines: () => void;
  toggleSelectedLineIds: (lineId: string) => void;
  toggleAllLine: () => void;
  addTakes: (options: TakeOption, num?: number) => void;
  regenerateTakes: (take: AudioBufferTake) => void;
  updateTake: (take: Take) => void;
  removeTake: (id: string) => void;
  audioBufferTakeLists: AudioBufferTake[];
  takeMap: Record<string, Take[]>;
  takeJobIdMap: Record<string, string>;
  selectTakeId: (lineId: string, takeId: string) => void;
  selectedTakeByLine: Record<string, string>;
  usedProfileIdList: string[];
  controlState: Parameter;
  setControlState: Dispatch<SetStateAction<Parameter>>;
}

const EditorContext = createContext<EditorContextProps>(
  {} as EditorContextProps
);

export const useEditorContext = () => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('EditorContext not supported');
  }
  return context;
};

const EditorContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { on, off, sessionId } = useWebSocketContext();
  const { voiceProfileList } = useVoiceLibraryQuery();
  const [selectedTakeByLine, setSelectedTakeByLine] = useLocalStorage<{
    [key: string]: string;
  }>('selectedTakeByLine', {});
  const { takeJobIdMap, updateTakeJobIdMap } = useDataContext();
  const [audioFileMap, setAudioFileMap] = useRecoilState(audioFileMapAtom);
  const [controlState, setControlState] =
    useState<Parameter>(DEFAULT_PARAMETER);
  const setShowPayModal = useSetRecoilState(showPayModalAtom);
  const [lineList, setLineList] = useRecoilState(lineListAtom);
  const [takeList, setTakeList] = useRecoilState(takeListAtom);
  const [currentProject, setCurrentProject] =
    useRecoilState(currentProjectAtom);
  const [selectedLineIds, setSelectedLineIds] =
    useRecoilState(selectedLineIdsAtom);
  const [selectedLineId, setSelectedLineId] = useRecoilState(activeLineIdAtom);

  const { showVoiceLibrary } = useToggleVoiceLibrary();
  const { track } = useLog();
  const [initialized, setInitialized] = useState(false);

  const {
    putProject,
    putTake,
    getResourceInfo,
    createCvc,
    createTts,
    putLine,
    postLine,
    postLines,
    postTakes,
    deleteLines,
    deleteTake,
  } = useAxios();

  // profile
  const defaultProfile = useMemo(
    () => voiceProfileList?.find((profile) => !!profile?.default_voice_id),
    [voiceProfileList]
  );
  const defaultVoice = useMemo(
    () => defaultProfile?.default_voice_id,
    [defaultProfile]
  );

  const updateProject = useCallback(
    async (updatedProject: Partial<Project>) => {
      if (currentProject) {
        await putProject({
          ...updatedProject,
          id: currentProject?.id,
        });

        setCurrentProject({
          ...currentProject,
          ...updatedProject,
        });
      }
    },
    [currentProject, putProject, setCurrentProject]
  );

  const updateLine = useCallback(
    async (updatedLine: Partial<Line>): Promise<void> => {
      await putLine(updatedLine);
      setLineList((prev) => {
        return prev.map((l) => {
          if (l.id === updatedLine.id) {
            return {
              ...l,
              ...updatedLine,
            };
          }
          return l;
        }) as Line[];
      });
    },
    [putLine, setLineList]
  );

  const toggleSelectedLineIds = useCallback(
    (lineId: string) => {
      setSelectedLineIds((prev) => {
        if (prev.includes(lineId)) {
          return prev.filter((id) => id !== lineId);
        }
        return [...prev, lineId];
      });
    },
    [setSelectedLineIds]
  );

  const toggleAllLine = useCallback(() => {
    if (selectedLineIds.length === lineList.length) {
      setSelectedLineIds([]);
    } else {
      setSelectedLineIds(lineList.map((line) => line.id));
    }
  }, [selectedLineIds, lineList, setSelectedLineIds]);

  const createEmptyLine = useCallback(
    (projectId: string): Partial<Line> => {
      return {
        projectId,
        draft: {
          text: '',
          voiceId: defaultVoice,
          language: defaultProfile?.language,
          parameter: DEFAULT_PARAMETER,
        } as Draft,
      };
    },
    [defaultVoice, defaultProfile]
  );

  const createLine = useCallback(
    async (line?: Line) => {
      // 기본값으로 비어있는 새라인 생성
      let newLine = createEmptyLine(currentProject?.id as string);
      if (line) {
        // 라인이 있다면 그 라인 정보를 바탕으로 기본 값을 바꿔준다.
        const lineInfo = line.draft;
        newLine = {
          ...newLine,
          draft: {
            ...newLine.draft,
            ...{
              language: lineInfo?.language || defaultProfile?.language,
              voiceId: lineInfo?.voiceId || defaultVoice,
              parameter: lineInfo?.parameter || DEFAULT_PARAMETER,
            },
          },
        };
      }
      // indexed db에 라인을 넣어준다. (scene에 함꼐 추가된다)
      const addedLine: Line = await postLine(newLine);
      return addedLine;
    },
    [
      createEmptyLine,
      currentProject?.id,
      postLine,
      defaultProfile?.language,
      defaultVoice,
    ]
  );

  const addLine = useCallback(
    async (lineRef?: Line): Promise<Line> => {
      const newLine = await createLine(lineRef);
      track('ADD_NEW_LINE', {
        projectId: currentProject?.id,
        lineId: newLine.id,
      });
      setSelectedLineId(newLine.id);
      setLineList([...lineList, newLine]);
      const lineOrder = lineRef
        ? [
            ...insertAfter<string>(
              [...(currentProject?.lineOrder ?? [])],
              lineRef?.id,
              newLine.id
            ),
          ]
        : [...(currentProject?.lineOrder ?? []), newLine.id];

      if (currentProject) {
        await updateProject({
          id: currentProject.id,
          lineOrder,
        });
        setCurrentProject({
          ...currentProject,
          lineOrder,
        });
      }

      return newLine;
    },
    [
      createLine,
      track,
      currentProject,
      setSelectedLineId,
      setLineList,
      lineList,
      updateProject,
      setCurrentProject,
    ]
  );

  const addLinesToLineList = useCallback(
    async (lines: Partial<Line>[]) => {
      const addedLines = await postLines(
        lines.map((line) => {
          return {
            ...line,
          };
        })
      );
      setLineList([...lineList, ...addedLines]);

      if (currentProject) {
        await updateProject({
          id: currentProject.id,
          lineOrder: [
            ...currentProject.lineOrder,
            ...addedLines.map((line) => line.id),
          ],
        });
        setCurrentProject({
          ...currentProject,
          lineOrder: [
            ...currentProject.lineOrder,
            ...addedLines.map((line) => line.id),
          ],
        });
      }
    },
    [
      currentProject,
      lineList,
      postLines,
      setCurrentProject,
      setLineList,
      updateProject,
    ]
  );

  const updateLineList = useCallback(
    (updatedLine: Line) => {
      setLineList((prev) => {
        return prev.map((line) => {
          if (line.id === updatedLine.id) {
            return updatedLine;
          }
          return line;
        });
      });
    },
    [setLineList]
  );

  const selectTakeId = useCallback(
    (lineId: string, takeId: string) => {
      if (selectedTakeByLine[lineId] !== takeId) {
        setSelectedTakeByLine({
          ...selectedTakeByLine,
          [lineId]: takeId,
        });
      }

      // 선택된 take
      const take = takeList.find((take) => take.id === takeId);
      if (take) {
        const draft = {
          text: take.type === 'tts' ? take.text : undefined,
          language: take.language,
          voiceId: take.voiceId,
          parameter: take.parameter,
          sourceId: take.type === 'cvc' ? take.sourceId : undefined,
        } as Draft;
        // 라인의 선택정보를 업데이트 한다.
        setLineList((prev) => {
          return prev.map((l) => {
            if (l.id === lineId) {
              return {
                ...l,
                draft: {
                  ...draft,
                },
              };
            }
            return l;
          }) as Line[];
        });
      }
    },
    [selectedTakeByLine, setLineList, setSelectedTakeByLine, takeList]
  );

  const deSelectTakeId = useCallback(
    (takeId: string) => {
      const newObj = { ...selectedTakeByLine };
      for (const key in newObj) {
        if (newObj[key] === takeId) {
          delete newObj[key];
        }
      }
      setSelectedTakeByLine(newObj);
    },
    [selectedTakeByLine, setSelectedTakeByLine]
  );

  const addTakeIdMap = useCallback(
    (lineId: string, takeId: string) => {
      setSelectedTakeByLine({
        ...selectedTakeByLine,
        ...{
          [lineId]: takeId,
        },
      });
    },
    [selectedTakeByLine, setSelectedTakeByLine]
  );

  const takeMap = useMemo(() => {
    const map: Record<string, Take[]> = {};
    takeList.forEach((take) => {
      if (map[take.lineId]) {
        map[take.lineId].push(take);
      } else {
        map[take.lineId] = [take];
      }
    });
    return map;
  }, [takeList]);

  const updateDraft = useCallback(
    async (lineId: string, draft: Draft) => {
      const line = lineList.find((line) => line.id === lineId);
      const d = {
        ...(line?.draft ?? {}),
        ...draft,
      };
      await updateLine({ id: lineId, draft: d });
    },
    [lineList, updateLine]
  );

  const addTakes = useCallback(
    async (options: TakeOption, num?: number) => {
      if (!currentProject?.id) {
        return;
      }
      // add takes by num
      const takes: Partial<Take>[] = [];
      for (let i = 0; i < (num ?? 1); i++) {
        takes.push({
          lineId: selectedLineId as string,
          text: options.text as string,
          voiceId: options.voiceId as string,
          language: options.language,
          // projectId: project.id,
          type: options.type,
          parameter: options.parameter,
          sourceId: options.resourceId ?? '',
          resourceId: options.resourceId ?? '',
        });
      }
      // 나중에 Generate된 파일이 위로 올라간다.
      // fetching 을 시작하면서, takeList에 추가한다.
      let jobId = '';
      try {
        const res =
          options.type === 'tts'
            ? await createTts(
                {
                  text: options.text,
                  language: options.language,
                  voice_id: options.voiceId,
                  parameters: options.parameter,
                  take_count: num || 1,
                },
                sessionId
              )
            : await createCvc(
                {
                  voice_id: options.voiceId,
                  parameters: options.parameter,
                  source_resource_id: options.resourceId as string,
                },
                sessionId
              );
        const {
          data: {
            data: { job_id },
          },
        } = res as any;
        jobId = job_id;
      } catch (error) {
        if (error instanceof PermissionError) {
          setShowPayModal(true);
          return;
        }
      }
      // 추가된 테이크 값 설정

      // 테이크 추가
      const addedTakes = (await postTakes(takes)) as Take[];
      setTakeList((prev) => [...prev, ...addedTakes]);
      // 테이크 추가 상태 제어 map 업데이트
      updateTakeJobIdMap(
        addedTakes.reduce((acc, take) => ({ ...acc, [take.id]: jobId }), {})
      );
      selectTakeId(selectedLineId as string, addedTakes[0].id);
      // 라인 아이디와 테이크 아이디 매핑 추가
      if (selectedLineId && takes[0].id) {
        addTakeIdMap(selectedLineId, takes[0].id);
      }
    },
    [
      currentProject?.id,
      postTakes,
      setTakeList,
      updateTakeJobIdMap,
      selectTakeId,
      selectedLineId,
      createTts,
      sessionId,
      createCvc,
      setShowPayModal,
      addTakeIdMap,
    ]
  );

  const regenerateTakes = useCallback(
    async (take: AudioBufferTake) => {
      const res =
        take.type === 'cvc'
          ? await createCvc(
              {
                voice_id: take.voiceId,
                parameters: take.parameter as ExtendParameter,
                source_resource_id: take.resourceId as string,
              },
              sessionId
            )
          : await createTts(
              {
                text: take.text,
                language: take.language,
                voice_id: take.voiceId,
                parameters: take.parameter as Parameter,
                take_count: 1,
              },
              sessionId
            );
      const {
        data: {
          data: { job_id },
        },
      } = res as any;
      updateTakeJobIdMap({ [take.id]: job_id });
    },
    [sessionId, updateTakeJobIdMap, createCvc, createTts]
  );

  const audioBufferTakeLists = useMemo(() => {
    return takeList.map((take) => {
      return {
        ...take,
        audioBuffer: audioFileMap[take.id]?.audioBuffer,
      };
    });
  }, [audioFileMap, takeList]);

  const removeSelectedLines = useCallback(async () => {
    if (selectedLineIds.includes(selectedLineId as string)) {
      setSelectedLineId(null);
    }
    setSelectedLineIds([]);
    await deleteLines(selectedLineIds);
    setLineList(lineList.filter((line) => !selectedLineIds.includes(line.id)));
    await updateProject({
      lineOrder:
        currentProject?.lineOrder.filter(
          (lineId) => !selectedLineIds.includes(lineId)
        ) ?? [],
      id: currentProject?.id,
    });
    track('DELETE_LINES', {
      projectId: currentProject?.id,
      lineIds: selectedLineIds,
    });
  }, [
    selectedLineIds,
    selectedLineId,
    setSelectedLineIds,
    deleteLines,
    setLineList,
    lineList,
    updateProject,
    currentProject?.lineOrder,
    currentProject?.id,
    track,
    setSelectedLineId,
  ]);

  const removeTake = useCallback(
    async (takeId: string) => {
      // take 필터링으로 테이크 제거, audio도 삭제해야함
      await deleteTake(takeId);
      setTakeList((prev) => prev.filter((take) => take.id !== takeId));
      deSelectTakeId(takeId);
    },
    [deleteTake, setTakeList, deSelectTakeId]
  );

  const updateTake = useCallback(
    async (take: Partial<Take>) => {
      await putTake(take);
      setTakeList((prev) => {
        return prev.map((t) => {
          if (t.id === take.id) {
            return {
              ...t,
              ...take,
            };
          }
          return t;
        });
      });
    },
    [putTake, setTakeList]
  );

  const usedProfileIdList = useMemo(() => {
    if (!lineList?.length) return [];
    const takeIdToVoiceIdMap = new Map(
      takeList.map((take) => [take.id, take.voiceId])
    );
    const voiceIdToProfileIdMap = new Map();
    voiceProfileList?.forEach((profile) => {
      profile.voices.forEach((voice) => {
        voiceIdToProfileIdMap.set(voice.id, profile.id);
      });
    });
    const voiceIds = new Set<string>();
    lineList.forEach((line) => {
      if (line.draft?.voiceId) {
        voiceIds.add(line.draft.voiceId);
      }
    });
    Object.values(selectedTakeByLine).forEach((selectedTakeId) => {
      const voiceId = takeIdToVoiceIdMap.get(selectedTakeId);
      if (voiceId) {
        voiceIds.add(voiceId);
      }
    });
    return Array.from(voiceIds).reduce<string[]>((profileIds, voiceId) => {
      const profileId = voiceIdToProfileIdMap.get(voiceId);
      if (profileId) {
        profileIds.push(profileId);
      }
      return profileIds;
    }, []);
  }, [lineList, selectedTakeByLine, takeList, voiceProfileList]);

  useEffect(() => {
    const complete = ({ job_id, resource_id, resource_ids }: any) => {
      // job_id로 들어온 값을 찾아서 해당 take를 업데이트한다.
      const ids = resource_ids ? resource_ids : [resource_id];
      // job_id에 속한 take들을 필터링 한다.
      const takes = takeList.filter((take) => takeJobIdMap[take.id] === job_id);
      if (takes.length) {
        // resource_id로 들어온 값을 찾아서 해당 resource를 가져와 audioBuffer를 만든다.
        let audio_length = 0;
        takes.forEach(async (take, index) => {
          getResourceInfo(ids[index]).then(async (res) => {
            const {
              data: {
                data: { transcoded },
              },
            } = res as any;
            const { arrayBuffer } = await fetchAudio(transcoded[0].url);
            const audioBuffer = await getAudioBuffer(arrayBuffer);
            if (!audioBuffer) return console.error('Failed to load resource');
            audio_length += audioBuffer.duration;
            setAudioFileMap((prev) => {
              return {
                ...prev,
                [take.id]: { audioBuffer, fileName: take.text },
              };
            });
            await updateTake({
              id: take.id,
              resourceId: ids[index],
            });
            updateTakeJobIdMap({ [take.id]: '' });
            // 새로운 오디오 audioBuffer가 들어오면, updateTakePosition 에선 이전 buffer들을 들고 있어서 position을 업데이트하기 위해 새 buffer를 넣어준다.
            // await updateLinePositions(take.id, audioBuffer);
            if (index === takes.length - 1) {
              const profile = voiceProfileList?.find((profile) =>
                profile.voices.find((voice) => voice.id === take.voiceId)
              );
              track('ADD_NEW_TAKE', {
                lineId: take.lineId,
                projectId: take.projectId,
                text: take.text,
                voiceId: take.voiceId,
                language: take.language,
                type: take.type,
                voice_name: profile?.name,
                pitch_shift: take.parameter?.pitch_shift,
                pitch_variance: take.parameter?.pitch_variance,
                speed: take.parameter?.speed,
                audio_length,
                takeCount: takes.length,
                style: profile?.voices.find(
                  (voice) => voice.id === take.voiceId
                )?.style,
              });
            }
          });
        });
      }
    };
    on(WS_EVENTS.JOB_DONE, complete);
    return () => {
      off(WS_EVENTS.JOB_DONE, complete);
    };
  }, [
    takeList,
    takeJobIdMap,
    updateTake,
    updateTakeJobIdMap,
    off,
    on,
    setAudioFileMap,
    getResourceInfo,
    voiceProfileList,
    track,
  ]);

  useEffect(() => {
    // 라인이 없으면 추가
    if (!initialized) {
      setInitialized(true);
      if (lineList.length === 0) {
        addLine().then(() => {
          //프로젝트 생성시  Voice library를 open 합니다.
          showVoiceLibrary();
        });
      }
    }
  }, [addLine, initialized, lineList.length, showVoiceLibrary]);

  if (!currentProject?.id || voiceProfileList?.length === 0) {
    return null;
  }
  return (
    <EditorContext.Provider
      value={{
        updateProject,
        addLine,
        addLinesToLineList,
        updateDraft,
        updateLine,
        updateLineList,
        removeSelectedLines,
        toggleSelectedLineIds,
        toggleAllLine,
        addTakes,
        regenerateTakes,
        updateTake,
        removeTake,
        audioBufferTakeLists,
        takeMap,
        takeJobIdMap,
        selectTakeId,
        selectedTakeByLine,
        usedProfileIdList,
        controlState,
        setControlState,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};
export default EditorContextProvider;
