import { MOBILE_BREAKPOINT } from '@/consts/breakpoints';
import styled from '@emotion/styled';

export const StyledEmotionList = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  width: 100%;
  overflow: hidden;

  margin-top: 52px;

  .swiper-container {
    position: relative;
    min-width: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .swiper {
    margin: 0;
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    cursor: pointer;
    background-color: black;
    opacity: 1;
    transition: opacity 0.3s ease;

    &::after {
      display: none;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 32px;
  }
`;
