import StyledVoicePanel from '../styles/StyledVoicePanel';
import VoicesTab from './VoicesTab';

const VoicePanel = () => {
  return (
    <StyledVoicePanel className="sp-top-panel sp-project">
      <section className="tab-content">
        <VoicesTab />
      </section>
    </StyledVoicePanel>
  );
};

export default VoicePanel;
