import { sleep } from '@/util/sleep';

export default async function executeApiCallFunctions<T>(
  callFunctions: Array<() => Promise<T>>,
  batchSize: number,
  delay: number,
  updateStatus: (count: number) => void
): Promise<T[]> {
  const resultList = [];
  for (let i = 0; i < callFunctions.length; i++) {
    const result = await callFunctions[i]();
    resultList.push(result);

    if ((i + 1) % batchSize === 0 && i + 1 < callFunctions.length) {
      console.log(`Waiting for ${delay / 1000} seconds...`);
      updateStatus(i);
      await sleep(delay);
    }
  }
  return resultList;
}
