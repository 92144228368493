import { atom } from 'recoil';

import { QuickMenuType } from '../data/config';

export const HEADER_HEIGHT = 4.375;
export const HEADER_HEIGHT_MOBILE = 4.0625;

export const isModalOpenAtom = atom<boolean>({
  key: 'isModalOpen',
  default: false,
});

export const isShowVoiceLibraryAtom = atom<boolean>({
  key: 'isShowVoiceLibrary',
  default: false,
});

export const isTextEditingAtom = atom<boolean>({
  key: 'isTextEditing',
  default: false,
});

export const isLoadingProjectAtom = atom<boolean>({
  key: 'isLoadingProject',
  default: false,
});

export const selectedQuickMenuAtom = atom<QuickMenuType>({
  key: 'selectedQuickMenu',
  default: 'Home',
});

export const isShowLineBannerAtom = atom<boolean>({
  key: 'isShowLineBanner',
  default: true,
});

export const selectedLineIdsAtom = atom<string[]>({
  key: 'selectedLineIds',
  default: [],
});

export const showPayModalAtom = atom<boolean>({
  key: 'showPayModal',
  default: false,
});

export const showScriptModalAtom = atom<boolean>({
  key: 'showScriptModal',
  default: false,
});

export const showPaymentOverrayAtom = atom<boolean>({
  key: 'showPaymentOverray',
  default: false,
});

export const toastMessageAtom = atom<string>({
  key: 'toastMessage',
  default: '',
});
