import IconButton from '@/components/Button/IconButton';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as DeleteIcon } from '../assets/icons/DeleteIcon.svg';
import { isShowLineBannerAtom } from '../stores/atoms/ui';

const LineBanner = () => {
  const setisShowLineBanner = useSetRecoilState(isShowLineBannerAtom);
  return (
    <section className="line-banner">
      <section className="line-banner-text">
        🎁 설날 특별 할인 이벤트! 3주 무제한 이용 $24 {'->'} $5 – 2월 17일까지
      </section>
      <IconButton
        className="btn-exit"
        onClick={() => setisShowLineBanner(false)}
      >
        <DeleteIcon />
      </IconButton>
    </section>
  );
};
export default LineBanner;
