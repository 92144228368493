import { WebSocketContext } from '@/providers/WebSocketProvider';
import { fetchAudio, getAudioBuffer } from '@/util/audio';
import { useContext } from 'react';

const useVoiceProfile = () => {
  const { sessionId } = useContext(WebSocketContext);

  const loadVoiceFile = async (url: string) => {
    const audio = await fetchAudio(url);
    const audioBuffer = await getAudioBuffer(audio.arrayBuffer);

    return { audioBuffer: audioBuffer as AudioBuffer };
  };

  return {
    sessionId,
    loadVoiceFile,
  };
};

export default useVoiceProfile;
