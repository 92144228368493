import { Black, Grey } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import { HEADER_HEIGHT } from '../stores/atoms/ui';

const StyledProjects = styled.section`
  display: flex;
  justify-content: center;
  .project-wrap {
    padding-bottom: 3rem;
    min-height: calc(100vh - ${HEADER_HEIGHT}rem);
    width: calc(28.9rem * 3 + 4rem * 2);
    flex-direction: column;
  }
  .project-title {
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    .btn-project-more {
      width: 2rem;
      height: 2rem;
      flex-shrink: 0;
    }
  }
  .project-contents {
    display: flex;
    width: 100%;
  }
  .project-list {
    width: 100%;
    display: grid;
    grid-column-gap: 4rem;
    grid-row-gap: 4rem;
    grid-template-columns: repeat(auto-fill, 28.9rem);
    .project-list-item {
      padding: 1.5rem;
      cursor: pointer;
      &:hover {
        background-color: ${Grey[500]};
        .project-list-item-title {
          .project-control {
            display: flex;
          }
        }
      }
      border-radius: 0.5rem;
      background: ${Grey[700]};
      width: 28.9rem;
      height: 13.3rem;
      /* {project item} */
      &.selected {
        .project-list-item-title {
          .project-control {
            display: flex;
          }
        }
      }
      .project-list-item-title {
        height: 3rem;
        margin-bottom: 1.69rem;
        font-size: 1.25rem;
        font-weight: ${FontWeight['SemiBold']};
        color: ${Grey[50]};
        letter-spacing: 0.0125rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .project-name-text {
          width: 20.375rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .project-name-edit {
          width: 20.375rem;
          background-color: transparent;
          outline: 0;
          border: 0;
          font-size: 1.25rem;
          font-weight: ${FontWeight['SemiBold']};
          color: ${Grey[50]};
          letter-spacing: 0.0125rem;
        }
        .project-control {
          display: none;
          gap: 0.5rem;
          align-items: center;
          .button-reset {
            background-color: transparent;
            border: 0;
            cursor: pointer;
            width: 2rem;
            height: 2rem;
            svg {
              width: 2rem;
              height: 2rem;
            }
          }
          .btn-export-project {
            color: ${Grey[300]};
          }
          .btn-project-more {
            width: 2rem;
            height: 2rem;
            margin-right: -0.5rem;
          }
        }
      }
      .project-list-item-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .project-preview {
          width: 7.88rem;
          height: 5.25rem;
          text-overflow: ellipsis;
          background-color: ${Black};
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          padding: 0.8rem 0.45rem;
          line-height: 150%;
          font-size: 0.75rem;
          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        .project-info {
          width: 16.3rem;
          height: 5.25rem;
          .project-item {
            display: flex;
            justify-content: space-between;
            font-size: 0.875rem;
            line-height: 150%;
            .label {
              color: ${Grey[300]};
            }
            .value {
              .time {
                font-style: normal;
                color: ${Grey[400]};
                margin-left: 0.25rem;
              }
            }
          }
        }
      }
      &.create-new {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .btn-create-new {
          font-size: 1.5rem;
          font-weight: ${FontWeight['SemiBold']};
        }
        .icon-create-new {
          width: 2.5rem;
          height: 2.5rem;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
`;

export const StyledProjectImportDropDown = styled.section`
  width: 12.8125rem;
  margin: 0.5rem 0;
  .project-control-item {
    padding: 0.5rem 0.5rem;
    &:hover {
      background-color: ${Grey[400]};
    }
    justify-content: start;
  }
  .btn-import-file {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    font-size: 0.875rem;
    font-weight: ${FontWeight['SemiBold']};
    cursor: pointer;
    input {
      display: none;
    }
  }
  .btn-control {
    width: 100%;
    gap: 0.5rem;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
  }
`;
export default StyledProjects;
