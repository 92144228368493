import { Grey } from '@/styles/Colors';
import styled from '@emotion/styled';

export const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000080;
`;

export const ModalContainer = styled.div<{ width?: string; height?: string }>`
  position: relative;
  padding: 16px;
  background-color: ${Grey[500]};
  border-radius: 12px;
  color: white;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  outline: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
`;

export const ModalTitle = styled.h2`
  margin-bottom: 1rem;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
`;
