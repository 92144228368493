import { Black, Primary, White } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

export const StyledToastMessage = styled.div`
  position: absolute;
  bottom: 6rem;
  right: 1.2rem;
  width: 23.2rem;
  height: 9.6rem;
  background-color: ${White};
  z-index: 10;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  color: ${Black};
  .header {
    display: flex;
    justify-content: space-between;
    .title {
      svg {
        margin-right: 0.4rem;
      }
      display: flex;
      font-size: ${FontSize['2Xl']};
      height: 3rem;
      border-bottom: 1px solid ${Primary['100']};
      font-weight: ${FontWeight['Bold']};
      align-items: center;
    }
  }
  .description {
    .text {
      line-height: 3.2rem;
      font-size: ${FontSize['2Xl']};
      height: 4rem;
    }
    .link {
      display: flex;
      justify-content: end;
      height: 1rem;
      button {
        font-size: ${FontSize['Xl']};
      }
    }
  }
`;
