import Button from '@/components/Button/Button';
import ObtIcon from '@/pages/screenplay/assets/images/Obt.png';
import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import { showPaymentOverrayAtom } from '@/pages/screenplay/stores/atoms/ui';
import { WHY_NOT_PAY_FORM_URL } from '@/pages/screenplay/stores/data/config';
import { Primary } from '@/styles/Colors';
import React from 'react';
import { useSetRecoilState } from 'recoil';

import Modal, { ModalProps } from '../Modal';
import ModalBody from '../ModalBody';
import ModalFooter from '../ModalFooter';
import StyledPayModal from './StyledPayModal';

interface PayModalProps
  extends Pick<
    ModalProps,
    'isOpen' | 'isPortal' | 'hideBackdrop' | 'backdropColor' | 'onClose'
  > {
  onClose: () => void;
}

const PayModal = ({ onClose, hideBackdrop }: PayModalProps) => {
  const setShowPaymentOverrayOpen = useSetRecoilState(showPaymentOverrayAtom);
  const { track } = useLog();

  return (
    <Modal isOpen={true} hideBackdrop={hideBackdrop} onClose={onClose}>
      <StyledPayModal>
        <ModalBody>
          <div className="pay-modal-header">
            <div className="title">Supertone Play</div>
            <img src={ObtIcon} alt="trial" width="50" height="28" />
            <div className="period">
              Open Beta Period: Oct 31, 2024 – Feb 17, 2025
            </div>
          </div>
          <div className="pay-modal-body">
            <div className="left-side">
              <div>
                We offer a special discount for OBT users. We simply provide:
              </div>
              <div className="offer-desc">
                <strong>
                  Unlimited usage and full access to over 150 Premium voices
                </strong>{' '}
                during the 3-month open beta.
              </div>
            </div>
            <div className="right-side">
              <div className="original-price">
                <s>$24</s>
              </div>
              <div className="current-price">$5</div>
              <div className="price-description">
                Seollal (Lunar New Year) Sale Until Feb 17, 2025
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="pay-modal-footer">
          <div className="left-side">
            <strong>
              Decided not to pay?{' '}
              <a
                href={WHY_NOT_PAY_FORM_URL}
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                Tell us why
              </a>
            </strong>
          </div>
          <div className="right-side">
            <Button
              className="pay-button"
              color={Primary[400]}
              size="lg"
              onClick={() => {
                track('BUY_CONTINUE', {});
                setShowPaymentOverrayOpen(true);
              }}
            >
              Continue to Payment
            </Button>
          </div>
        </ModalFooter>
      </StyledPayModal>
    </Modal>
  );
};

export default PayModal;
