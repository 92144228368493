import {
  initIndexedDB,
  updateFinishMigration,
} from '@/pages/screenplay/api/project';
import useAxios from '@/pages/screenplay/hooks/useAxios';
import { Line, Project, Scene, Take } from '@/pages/screenplay/stores/project';
import { ProfileEditInfo } from '@/pages/screenplay/stores/voice';
import executeApiCallFunctions from '@/util/executeApiCallFunctions';
import findKeysByValue from '@/util/findKeysByValue';
import splitArrayIntoChunks from '@/util/splitArrayIntoChunks';
import { SUPUser } from '@supertone-inc/auth-sdk-react';
import { useCallback, useEffect, useState } from 'react';

const useMigration = (user: SUPUser | null) => {
  const [migrationStatus, setMigrationStatus] = useState<string>('');
  const [migrationData, setMigrationData] = useState<{
    projectList: Project[];
    sceneList: Scene[];
    lineList: Line[];
    takeList: Take[];
    voiceList: ProfileEditInfo[];
  } | null>(null);
  const { postProject, postLines, postTakes, putProject } = useAxios();

  const migration = useCallback(async () => {
    // 진행률을 적절하게 보여주기 위한 매직 넘버, 실제 데이터를 보여주려면 분산 요청하는 개수등을 계산해야 하는데, 1회성 숫자에 굳이 그럴 필요까지는 없다고 판단.
    const migrationMagicNumber = 1.5;
    const apiCallCount = Math.ceil(
      [
        migrationData?.projectList.length ?? 0,
        migrationData?.lineList.length ?? 0,
        migrationData?.takeList.length ?? 0,
        migrationData?.projectList.length ?? 0, //project는 update를 한번 더 하기 때문에 한번더 계산에 추가
      ].reduce((accumulator, currentValue) => accumulator + currentValue, 0) /
        migrationMagicNumber
    );
    const projectPromises: (() => Promise<Project>)[] = [];
    let migrationDoneCount = 0;

    //프로젝트 post propmise생성
    migrationData?.projectList.forEach((project: Project) => {
      projectPromises.push(async () =>
        postProject({
          name: project.name,
          language: project.language,
          voiceIds: migrationData.voiceList
            ?.filter(({ projectId }) => projectId === project.id)
            .map(({ profileId }) => profileId),
        })
      );
    });
    //프로젝트 분산 요청
    const createdProjectList = await executeApiCallFunctions<Project>(
      projectPromises,
      5,
      1000,
      (callCount) => {
        migrationDoneCount += callCount;
        console.log(migrationDoneCount, callCount);
        setMigrationStatus(
          `${(
            (migrationDoneCount / apiCallCount) *
            100
          ).toFixed()}% progressing..`
        );
      }
    );
    //프로젝트 indexedDB id값과 server에서 생성한 id맵 생성
    const projectOldNewIdMap: Record<string, string> = {};
    migrationData?.projectList.forEach((project, index) => {
      projectOldNewIdMap[project.id] = createdProjectList[index].id;
    });
    console.log(JSON.stringify(projectOldNewIdMap));

    //라인 post propmise생성
    const linePromises: (() => Promise<Line[]>)[] = [];
    splitArrayIntoChunks<Line>(migrationData?.lineList ?? [], 20).forEach(
      (lines: Line[]) => {
        linePromises.push(
          async () =>
            await postLines(
              lines
                .map((line) => {
                  return {
                    projectId: projectOldNewIdMap[line.projectId],
                    draft: {
                      ...line.draft,
                    },
                  };
                })
                .filter((line) => !!line.projectId)
            )
        );
      }
    );

    //라인 분산 요청
    const createdLineList: Line[][] = await executeApiCallFunctions<Line[]>(
      linePromises,
      3,
      1000,
      (callCount) => {
        migrationDoneCount += callCount;
        console.log(migrationDoneCount, callCount);
        setMigrationStatus(
          `${(
            (migrationDoneCount / apiCallCount) *
            100
          ).toFixed()}% progressing..`
        );
      }
    );

    //라인 indexedDB id값과 server에서 생성한 id맵 생성
    const lineOldNewIdMap: Record<string, string> = {};
    migrationData?.lineList.forEach((line, index) => {
      if (createdLineList.flat()[index]?.id) {
        lineOldNewIdMap[line.id] = createdLineList.flat()[index].id;
      }
    });

    const takePromises: (() => Promise<Take[]>)[] = [];
    splitArrayIntoChunks<Take>(migrationData?.takeList ?? [], 20).forEach(
      (takes: Take[]) => {
        takePromises.push(
          async () =>
            await postTakes(
              takes
                .map((take) => {
                  return {
                    type: take.type,
                    lineId: lineOldNewIdMap[take.lineId],
                    text: take.text,
                    voiceId: take.voiceId,
                    language: take.language,
                    resourceId: take.resourceId ?? take.resource_id,
                    sourceId: take.sourceId ?? take.resource_id,
                    parameter: take.parameter,
                  };
                })
                .filter((take) => !!take.lineId)
            )
        );
      }
    );
    await executeApiCallFunctions<Take[]>(
      takePromises,
      3,
      1000,
      (callCount) => {
        migrationDoneCount += callCount;
        console.log(migrationDoneCount, callCount);
        setMigrationStatus(
          `${(
            (migrationDoneCount / apiCallCount) *
            100
          ).toFixed()}% progressing..`
        );
      }
    );

    // 프로젝트 lineOrder업데이트 추가
    const updateProjects: (() => Promise<boolean>)[] = [];
    createdProjectList.forEach((project: Project) => {
      updateProjects.push(async () => {
        const oldProjectId = findKeysByValue(projectOldNewIdMap, project.id);
        const sceneByProject = migrationData?.sceneList.find(
          (scene) => scene.projectId === oldProjectId
        );
        return putProject({
          id: project.id,
          lineOrder:
            sceneByProject?.lineIds
              .map((lineId) => lineOldNewIdMap[lineId])
              .filter((lineId) => !!lineId) ?? [],
        });
      });
    });
    //프로젝트 분산 업데이트 요청
    await executeApiCallFunctions<boolean>(
      updateProjects,
      5,
      1000,
      (callCount) => {
        migrationDoneCount += callCount;
        console.log(migrationDoneCount, callCount);
        setMigrationStatus(
          `${(
            (migrationDoneCount / apiCallCount) *
            100
          ).toFixed()}% progressing..`
        );
      }
    );
    setMigrationStatus('Almost done..');

    return true;
  }, [
    migrationData?.lineList,
    migrationData?.projectList,
    migrationData?.sceneList,
    migrationData?.takeList,
    migrationData?.voiceList,
    postLines,
    postProject,
    postTakes,
    putProject,
  ]);

  useEffect(() => {
    if (migrationStatus === 'start') {
      setMigrationStatus('Supertone Play migration has started.');
      migration().then(() => {
        updateFinishMigration().then(() => {
          console.log('migration done');
          setMigrationStatus('done');
        });
      });
    }
  }, [
    migrationStatus,
    migrationData,
    postProject,
    postLines,
    postTakes,
    migration,
  ]);

  useEffect(() => {
    if (user?.email) {
      initIndexedDB(user.email).then(({ migrationData }) => {
        if (migrationData.projectList.length !== 0) {
          setMigrationData(migrationData);
          setMigrationStatus('start');
        } else {
          setMigrationStatus('done');
        }
      });
    }
  }, [user?.email]);

  return {
    migrationStatus,
  };
};

export default useMigration;
