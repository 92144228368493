/* tslint:disable max-line-length */

/**
 * ![White](https://via.placeholder.com/15/ffffff/000000?text=+)
 */
export const White = '#f6f8fa';
export const WhiteGray = '#d9d9d9';
/**
 * ![Black](https://via.placeholder.com/15/121212/000000?text=+)
 */
export const Black = '#121212';
/**
 * ![PureBlack](https://via.placeholder.com/15/000000/000000?text=+)
 */
export const PureBlack = '#000000';
/**
 * ![Grey50](https://via.placeholder.com/15/f4f4f4/000000?text=+) ![Grey100](https://via.placeholder.com/15/dbdbdb/000000?text=+) ![Grey150](https://via.placeholder.com/15/c2c2c2/000000?text=+) ![Grey200](https://via.placeholder.com/15/a9a9a9/000000?text=+) ![Grey300](https://via.placeholder.com/15/909090/000000?text=+) ![Grey400](https://via.placeholder.com/15/767676/000000?text=+) ![Grey450](https://via.placeholder.com/15/5d5d5d/000000?text=+) ![Grey500](https://via.placeholder.com/15/444444/000000?text=+) ![Grey600](https://via.placeholder.com/15/2b2b2b/000000?text=+) ![Grey800](https://via.placeholder.com/15/161616/000000?text=+)
 */
export const Grey = {
  /**
   * ![Grey50](https://via.placeholder.com/15/f4f4f4/000000?text=+)
   */
  50: '#f4f4f4',
  /**
   * ![Grey100](https://via.placeholder.com/15/dbdbdb/000000?text=+)
   */
  100: '#dbdbdb',
  /**
   * ![Grey150](https://via.placeholder.com/15/c2c2c2/000000?text=+)
   */
  150: '#c2c2c2',
  /**
   * ![Grey200](https://via.placeholder.com/15/a9a9a9/000000?text=+)
   */
  200: '#a9a9a9',
  /**
   * ![Grey300](https://via.placeholder.com/15/909090/000000?text=+)
   */
  300: '#909090',
  /**
   * ![Grey400](https://via.placeholder.com/15/767676/000000?text=+)
   */
  400: '#767676',
  /**
   * ![Grey450](https://via.placeholder.com/15/5d5d5d/000000?text=+)
   */
  450: '#5d5d5d',
  /**
   * ![Grey500](https://via.placeholder.com/15/444444/000000?text=+)
   */
  475: '#555555',
  500: '#444444',
  /**
   * ![Grey600](https://via.placeholder.com/15/2b2b2b/000000?text=+)
   */
  600: '#2b2b2b',
  /**
   * ![Grey700](https://via.placeholder.com/15/202020/000000?text=+)
   */
  700: '#202020',
  /**
   * ![Grey800](https://via.placeholder.com/15/161616/000000?text=+)
   */
  800: '#161616',
} as const;

/**
 * ![Primary50](https://via.placeholder.com/15/f3f3ff/000000?text=+) ![Primary100](https://via.placeholder.com/15/c4d5ff/000000?text=+) ![Primary150](https://via.placeholder.com/15/96b6ff/000000?text=+) ![Primary200](https://via.placeholder.com/15/6798ff/000000?text=+) ![Primary300](https://via.placeholder.com/15/3979ff/000000?text=+) ![Primary400](https://via.placeholder.com/15/0a5bff/000000?text=+) ![Primary450](https://via.placeholder.com/15/0850db/000000?text=+) ![Primary500](https://via.placeholder.com/15/0745b8/000000?text=+) ![Primary600](https://via.placeholder.com/15/053a94/000000?text=+) ![Primary700](https://via.placeholder.com/15/032f70/000000?text=+) ![Primary800](https://via.placeholder.com/15/02254c/000000?text=+) ![Primary900](https://via.placeholder.com/15/011a28/000000?text=+)
 */
export const Primary = {
  /**
   * ![Primary50](https://via.placeholder.com/15/f3f3ff/000000?text=+)
   */
  50: '#f3f3ff',
  /**
   * ![Primary100](https://via.placeholder.com/15/c4d5ff/000000?text=+)
   */
  100: '#c4d5ff',
  /**
   * ![Primary150](https://via.placeholder.com/15/96b6ff/000000?text=+)
   */
  150: '#96b6ff',
  /**
   * ![Primary200](https://via.placeholder.com/15/6798ff/000000?text=+)
   */
  200: '#6798ff',
  /**
   * ![Primary300](https://via.placeholder.com/15/3979ff/000000?text=+)
   */
  300: '#3979ff',
  /**
   * ![Primary400](https://via.placeholder.com/15/0a5bff/000000?text=+)
   */
  400: '#0a5bff',
  /**
   * ![Primary450](https://via.placeholder.com/15/0850db/000000?text=+)
   */
  450: '#0850db',
  /**
   * ![Primary500](https://via.placeholder.com/15/0745b8/000000?text=+)
   */
  500: '#0745b8',
  /**
   * ![Primary600](https://via.placeholder.com/15/053a94/000000?text=+)
   */
  600: '#053a94',
  /**
   * ![Primary700](https://via.placeholder.com/15/032f70/000000?text=+)
   */
  700: '#032f70',
  /**
   * ![Primary800](https://via.placeholder.com/15/02244d/000000?text=+)
   */
  800: '#02244d',
  /**
   * ![Primary900](https://via.placeholder.com/15/001929/000000?text=+)
   */
  900: '#001929',
} as const;

/**
 * ![Secondary50](https://via.placeholder.com/15/ffbc78/000000?text=+) ![Secondary100](https://via.placeholder.com/15/ff8b3c/000000?text=+) ![Secondary200](https://via.placeholder.com/15/ff5a00/000000?text=+) ![Secondary300](https://via.placeholder.com/15/bb3e00/000000?text=+)
 */
export const Secondary = {
  /**
   * ![Secondary50](https://via.placeholder.com/15/ffbc78/000000?text=+)
   */
  50: '#ffbc78',
  /**
   * ![Secondary100](https://via.placeholder.com/15/ff8b3c/000000?text=+)
   */
  100: '#ff8b3c',
  /**
   * ![Secondary200](https://via.placeholder.com/15/ff5a00/000000?text=+)
   */
  200: '#ff5a00',
  /**
   * ![Secondary300](https://via.placeholder.com/15/bb3e00/000000?text=+)
   */
  300: '#bb3e00',
} as const;

/**
 * ![Green](https://via.placeholder.com/15/31b743/000000?text=+)
 */
export const Green = '#31b743';
/**
 * ![Red](https://via.placeholder.com/15/ff0000/000000?text=+)
 */
export const Red = '#ff0000';
/**
 * ![PastelBlue](https://via.placeholder.com/15/3d8dd7/000000?text=+)
 */
export const PastelBlue = '#3d8dd7';

export const PurchaseBtn = '#3898FF';
export const PurchaseBtnHover = '#5CABFF';

/**
 * ![Dark700](https://via.placeholder.com/15/272a30/000000?text=+) ![Dark800](https://via.placeholder.com/15/1c1f24/000000?text=+)
 */
export const Dark = {
  /**
   * ![Dark700](https://via.placeholder.com/15/272a30/000000?text=+)
   */
  700: '#272a30',
  /**
   * ![Dark800](https://via.placeholder.com/15/1c1f24/000000?text=+)
   */
  800: '#1c1f24',
} as const;

export const BannerBlue = '#637FB8';
