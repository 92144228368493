function insertAfter<T>(arr: T[], target: T, value: T): T[] {
  const index = arr.indexOf(target); // target의 인덱스 찾기
  if (index === -1) {
    console.error('Target not found in array');
    return arr; // target이 없으면 원본 배열 반환
  }
  arr.splice(index + 1, 0, value); // target 뒤에 value 삽입
  return arr;
}
export default insertAfter;
