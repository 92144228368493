import Button from '@/components/Button/Button';
import { useEditorContext } from '@/pages/screenplay/providers/EditorContextProvider';
import {
  currentProjectAtom,
  lineListAtom,
  takeListAtom,
} from '@/pages/screenplay/stores/atoms/project';
import { showScriptModalAtom } from '@/pages/screenplay/stores/atoms/ui';
import { DEFAULT_PARAMETER } from '@/pages/screenplay/stores/data/config';
import { Draft } from '@/pages/screenplay/stores/project';
import { useVoiceLibraryQuery } from '@/query/useVoiceLibraryQuery';
import { Grey } from '@/styles/Colors';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Modal, { ModalProps } from '../Modal';
import ModalBody from '../ModalBody';
import StyledScriptModal from './StyledScriptModal';

interface PayModalProps
  extends Pick<
    ModalProps,
    'isOpen' | 'isPortal' | 'hideBackdrop' | 'backdropColor' | 'onClose'
  > {
  onClose: () => void;
}

const ScriptModal = ({ onClose, hideBackdrop, isOpen }: PayModalProps) => {
  const { t } = useTranslation();
  const { addLinesToLineList, selectedTakeByLine } = useEditorContext();
  const currentProject = useRecoilValue(currentProjectAtom);
  const takeList = useRecoilValue(takeListAtom);
  const lineList = useRecoilValue(lineListAtom);
  const setScriptModalOpen = useSetRecoilState(showScriptModalAtom);
  const { voiceProfileList } = useVoiceLibraryQuery();

  // 라인이 선택한 텍스트들만 조합해서 txt파일로 저장한다.
  const exportScene = useCallback(async () => {
    const lineListOrderBy = currentProject?.lineOrder.map((lineId, index) => {
      return lineList.find((line) => line.id === lineId);
    });

    const lineScriptList = lineListOrderBy?.reduce((acc, line) => {
      const take =
        takeList?.find((take) =>
          Object.keys(selectedTakeByLine).includes(take.id)
        ) || line?.draft;
      take?.text && acc.push(take.text);
      return acc;
    }, [] as string[]);
    const string = lineScriptList?.join('\n') || '';
    const blob = new Blob([string], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${currentProject?.name}.txt`;
    a.click();
    URL.revokeObjectURL(url);
    setScriptModalOpen(false);
  }, [
    currentProject?.lineOrder,
    currentProject?.name,
    lineList,
    selectedTakeByLine,
    setScriptModalOpen,
    takeList,
  ]);

  const addLines = useCallback(
    async (content: string[]) => {
      const profileId = currentProject?.voiceIds[0];
      const profile = voiceProfileList?.find(
        (voiceProfile) => voiceProfile.id === profileId
      );
      if (profile) {
        const newLines = [];
        for (const text of content) {
          newLines.push({
            projectId: currentProject?.id,
            draft: {
              text,
              voiceId: profile.default_voice_id,
              parameter: DEFAULT_PARAMETER,
            } as Draft,
          });
        }
        addLinesToLineList(newLines);
      }
    },
    [
      currentProject?.voiceIds,
      currentProject?.id,
      voiceProfileList,
      addLinesToLineList,
    ]
  );

  const importFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let reader = new FileReader();
      reader.onloadend = function (evt: ProgressEvent<FileReader>) {
        if (evt.target?.readyState === FileReader.DONE) {
          if (typeof evt.target.result !== 'string') {
            return;
          } else {
            const lineScriptArray = evt.target.result.split('\n');
            if (lineScriptArray.length > 0) {
              addLines(lineScriptArray).then(() => {
                e.target.value = '';
              });
            }
          }
        }
        setScriptModalOpen(false);
      };
      if (e.target.files) {
        const file = e.target.files[0];
        file && reader.readAsText(file);
      }
    },
    [addLines, setScriptModalOpen]
  );

  return (
    <Modal isOpen={true} hideBackdrop={hideBackdrop} onClose={onClose}>
      <StyledScriptModal>
        <ModalBody>
          <div className="script-modal-header">
            <div className="title">Import or Export Script</div>
            <div className="description">
              You can import or export scripts for use
            </div>
          </div>
          <div className="script-modal-body">
            <div className="left-side">
              <div className="action-desc">
                <div>Import File Format</div>
                <div className="file-format">TXT</div>
              </div>
              <div className="button-layout-container">
                <label htmlFor="btn-import-file" className="btn-import-file">
                  {t('Import')}
                  <input
                    type="file"
                    color="transparent"
                    id="btn-import-file"
                    accept="text/plain"
                    multiple={false}
                    onChange={importFile}
                  />
                </label>
              </div>
            </div>
            <div className="right-side">
              <div className="action-desc">
                <div>Export File Format</div>
                <div className="file-format">TXT</div>
              </div>
              <div className="button-layout-container">
                <Button
                  variant={'outlined'}
                  color={Grey[400]}
                  size={'xl'}
                  onClick={() => exportScene()}
                >
                  Export
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </StyledScriptModal>
    </Modal>
  );
};

export default ScriptModal;
