import DesktopView from '@/pages/screenplay/Home/DesktopView';
import MobileView from '@/pages/screenplay/Home/MobileView';
import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import { StyledVoiceItem } from '@/pages/screenplay/styles/StyledVoiceItem';
import { Language } from '@/pages/screenplay/VoiceLibrary/types';
import { usePreviewTTS } from '@/query/usePreviewTTS';
import { LottieRefCurrentProps } from 'lottie-react';
import { useCallback, useEffect, useRef, useState } from 'react';

interface VoiceListItemProps {
  voiceId: string;
  style: string;
  script: string;
  sample?: string;
  isPlaying: boolean;
  thumbnailPath: string;
  onPlayPause: (id: string, audioUrl: string | undefined) => void;
  language: Language;
  resetAudio: () => void;
  currentAudioUrl: string | null;
  setCurrentAudioUrl: (audioUrl: string | null) => void;
  isAudioEnded: boolean;
}

export function VoiceListItem({
  voiceId,
  style,
  script,
  sample,
  isPlaying,
  onPlayPause,
  thumbnailPath,
  language,
  resetAudio,
  currentAudioUrl,
  setCurrentAudioUrl,
  isAudioEnded,
}: VoiceListItemProps) {
  const { track } = useLog();
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isEditable = !(isLoading || isPlaying);
  const [editedText, setEditedText] = useState('');
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const circularProgressLottieRef = useRef<LottieRefCurrentProps>(null);

  const { mutate: generatePreviewTTS, cancel } = usePreviewTTS();

  const handleClose = () => {
    cancel();
    setIsLoading(false);
    setIsEditing(false);
    setEditedText('');
  };

  const handleSubmit = () => {
    setIsLoading(true);
    track('CLICK_EDIT', {
      voiceId,
      style,
      language,
    });
    generatePreviewTTS(
      {
        voiceId,
        text: editedText,
        language,
      },
      {
        onSuccess: (response: ArrayBuffer) => {
          const blob = new Blob([new Uint8Array(response)], {
            type: 'audio/mpeg',
          });
          const audioUrl = URL.createObjectURL(blob);
          setCurrentAudioUrl(audioUrl);
          onPlayPause(voiceId, audioUrl);
          setIsLoading(false);
        },
        onError: () => {
          setIsLoading(false);
        },
      }
    );
  };

  const onClickPlay = useCallback(() => {
    track('CLICK_PLAY', {
      voiceId,
      style,
      language,
    });
  }, [language, style, track, voiceId]);

  useEffect(() => {
    if (isPlaying && lottieRef.current) {
      lottieRef.current.play();
    } else if (lottieRef.current) {
      lottieRef.current.stop();
    }
  }, [isPlaying]);

  return (
    <StyledVoiceItem
      onClick={() => !isEditing && onPlayPause(voiceId, sample)}
      isEditing={isEditing}
    >
      <DesktopView
        isEditable={isEditable}
        isPlaying={isPlaying}
        lottieRef={lottieRef}
        script={script}
        isEditing={isEditing}
        editedText={editedText}
        setEditedText={setEditedText}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onClickPlay={onClickPlay}
        setIsEditing={setIsEditing}
        thumbnailPath={thumbnailPath}
        isLoading={isLoading}
        circularProgressLottieRef={circularProgressLottieRef}
        onPlayPause={() => onPlayPause(voiceId, currentAudioUrl || undefined)}
        resetAudio={resetAudio}
        isAudioEnded={isAudioEnded}
      />
      <MobileView
        isEditable={isEditable}
        isPlaying={isPlaying}
        lottieRef={lottieRef}
        script={script}
        isEditing={isEditing}
        editedText={editedText}
        setEditedText={setEditedText}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        onClickPlay={onClickPlay}
        setIsEditing={setIsEditing}
        thumbnailPath={thumbnailPath}
        isLoading={isLoading}
        circularProgressLottieRef={circularProgressLottieRef}
        onPlayPause={() => onPlayPause(voiceId, currentAudioUrl || undefined)}
        resetAudio={resetAudio}
        isAudioEnded={isAudioEnded}
      />
    </StyledVoiceItem>
  );
}
