import { Grey, White } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import AddIconHover from '../assets/images/AddIcon_hover.svg';
import AddIcon from '../assets/images/AddIcon.svg';
import ProjectIcon from '../assets/images/ProjectIcon.svg';

const StyledVoicePanel = styled.section`
  width: 20rem;
  .create-new {
    height: 6rem;
    align-items: center;
    display: flex;
    border-bottom: 1px solid ${Grey[500]};
    .btn-create-new-item {
      color: ${Grey[300]};
      font-size: 1rem;
      ::before {
        content: '';
        vertical-align: middle;
        display: inline-block;
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.85rem;
        background-image: url(${AddIcon});
        background-size: cover;
      }
      &:hover {
        color: ${Grey[50]};
        ::before {
          background-image: url(${AddIconHover});
        }
      }
    }
    &.add-voice {
      border-bottom: 1px solid ${Grey[500]};
    }
  }
  .list-item {
    display: flex;
    padding: 1rem 1.25rem;
    height: 4rem;
    justify-content: space-between;
    align-items: center;
    &:hover {
      cursor: pointer;
      background-color: ${Grey[500]};
      .btn-open-project {
        display: inline-flex !important;
      }
    }
    &.active {
      background-color: ${Grey[800]};
      .list-item-title {
        &::before {
          width: 1.5625rem;
          height: 1.5625rem;
        }
      }
      .btn-scene-toggle {
        color: ${Grey[50]};
      }
    }
    .btn-open-project {
      display: none !important;
      border: 1px solid ${White};
      border-radius: 12.5rem;
      color: ${White};
      width: 3.75rem;
      display: inline-flex;
      padding: 0.56rem 0;
      line-height: 100%;
      text-decoration: none;
      font-size: 0.875rem;
      justify-content: center;
      vertical-align: middle;
      &:hover {
        background-color: ${Grey[300]};
      }
    }
    .btn-item-toggle {
      vertical-align: middle;
    }
    .list-buttons {
      display: inline-flex !important;
      align-items: center;
      gap: 0.69rem;
    }
    .btn-voice-icon {
      background-color: transparent;
      border: none;
    }
    .list-item-title {
      user-select: none;
      cursor: text;
      font-weight: ${FontWeight['SemiBold']};
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      margin-right: 1rem;
      &::before {
        content: '';
        vertical-align: middle;
        display: inline-block;
        width: 1.3125rem;
        height: 1.3125rem;
        margin-right: 0.44rem;
        background-image: url(${ProjectIcon});
        background-size: cover;
      }
      &.editing {
        white-space: nowrap;
        display: flex;
      }
    }
    .list-item-title-edit {
      background-color: transparent;
      border: 0;
      height: calc(1.3125rem - 4px);
      line-height: 100%;
      color: ${Grey[50]};
      white-space: nowrap;
      overflow-y: hidden;
      display: -webkit-box;
      width: calc(100% - 1rem);
      border: none;
      outline: none;
    }
    &.voice {
      height: 4rem;
      .btn-voice-icon {
        width: 2rem;
        height: 2rem;
        display: none;
      }
      &:hover {
        .btn-voice-icon {
          display: inline-flex;
        }
      }
      .voice-profile {
        display: inline-flex;
        align-items: center;
        gap: 0.75rem;
      }
      &.selected-line-voice {
        background-color: ${Grey[800]};
      }
      &.disabled {
        cursor: not-allowed;
        .voice-profile {
          pointer-events: none;
          opacity: 0.33;
        }
        .btn-voice-delete {
          pointer-events: auto;
        }
      }
    }
  }
`;

export default StyledVoicePanel;
