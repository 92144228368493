import debounce from '@/util/debounce';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { useEditorContext } from '../providers/EditorContextProvider';
import { isTextEditingAtom } from '../stores/atoms/ui';
import { MAX_LINE_TEXT_COUNT } from '../stores/data/config';
import { Line } from '../stores/project';

interface LineEditorProps {
  text: string;
  line: Line;
  onUpdate?: (text: string) => void;
  isOpen: boolean;
}
const LineEditor: React.FC<LineEditorProps> = ({
  text: textProp,
  line,
  isOpen,
}) => {
  const { t } = useTranslation();
  const setIsTextEditing = useSetRecoilState(isTextEditingAtom);
  const { updateDraft } = useEditorContext();
  const [text, setText] = useState(textProp);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const onFocus = useCallback(() => {
    setIsTextEditing(true);
  }, [setIsTextEditing]);

  const onBlur = useCallback(() => {
    setIsTextEditing(false);
  }, [setIsTextEditing]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    switch (e.code) {
      case 'Escape':
        setTimeout(() => inputRef.current?.blur());
        return;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateDraft = useCallback(debounce(updateDraft, 500), [
    updateDraft,
  ]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      // 줄바꿈 문자를 공백으로 치환한다.
      const text = e.target.value
        .replace(/\r?\n|\r/g, '')
        .slice(0, MAX_LINE_TEXT_COUNT);

      setText(text);
      debouncedUpdateDraft(line.id, {
        text,
        sourceId: undefined,
      });
    },
    [debouncedUpdateDraft, line.id]
  );

  // 라인 오픈시 에디터 영역 포커스
  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setText(textProp);
  }, [textProp]);

  return (
    <section className={classNames('line-editor', isOpen && 'line-open')}>
      <section className="line-editor-left">
        <section className="shadow-text">{text}</section>
        <textarea
          ref={inputRef}
          className="content-editor line-editor-text"
          maxLength={MAX_LINE_TEXT_COUNT}
          placeholder={t('Write your story here') as string}
          data-hj-allow
          value={text}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
      </section>
      {isOpen && (
        <section className="line-text-count">
          ({text.length}/{MAX_LINE_TEXT_COUNT})
        </section>
      )}
    </section>
  );
};
export default LineEditor;
