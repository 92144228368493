import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import { HOME_BANNER_LINK } from '@/pages/screenplay/stores/data/config';
import { StyledBanner } from '@/pages/screenplay/styles/StyledBanner';

export default function Banner() {
  const { track } = useLog();
  const handleClick = () => {
    track('CLICK_BANNER');
    window.open(HOME_BANNER_LINK, '_blank')?.focus();
  };

  return (
    <StyledBanner>
      <div className="banner" onClick={handleClick}>
        <h1>Join Our University Content Partners</h1>
        <p>
          We’re inviting university students to join our content partner
          program.
        </p>
      </div>
    </StyledBanner>
  );
}
