import CopyIcon from '@/assets/icons/ClipIcon.svg';
import kakaoImage from '@/assets/images/kakao.png';
import xImage from '@/assets/images/x.png';
import ImageButton from '@/components/Share/ImageButton';
import { ButtonContainer, SnsContainer } from '@/components/Share/StyledSns';
import useKakaoShare from '@/hooks/useKakaoShare';
import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import clipboardCopy from '@/util/clipboardCopy';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface SnsProps {
  link: string;
  title: string;
  description: string;
  imageUrl: string;
  kakaoShareButtonTitle: string;
  xHashTags: string[];
  kaKaoImageWidth: number;
  kaKaoImageHeight: number;
}
export default function Sns({
  link,
  title,
  description,
  imageUrl,
  kaKaoImageWidth,
  kaKaoImageHeight,
  kakaoShareButtonTitle,
  xHashTags,
}: SnsProps) {
  const { t } = useTranslation();
  const { track } = useLog();
  const { handleClickKaKaoShare } = useKakaoShare({
    shareTitle: title,
    shareDescription: description,
    imageUrl: imageUrl,
    linkUrl: link,
    buttonTitle: kakaoShareButtonTitle,
    imageWidth: kaKaoImageWidth,
    imageHeight: kaKaoImageHeight,
  });

  const handleKakaoClick = () => {
    track('CLICK_SHARE', { channel: 'kakao' });
    handleClickKaKaoShare();
  };

  const handleCopy = () => {
    track('CLICK_SHARE', { channel: 'link' });
    clipboardCopy(link)
      .then(() => {
        alert(t('URL has been copied to clipboard!'));
      })
      .catch((err) => {
        console.error('복사 실패:', err);
        alert(t('URL copy failed!'));
      });
  };

  const shareToX = useCallback(() => {
    track('CLICK_SHARE', { channel: 'x' });
    // 공유할 내용 설정
    const text = encodeURIComponent(title);
    const url = encodeURIComponent(link);
    const hashtags = encodeURIComponent(xHashTags.join(','));

    // X 공유 URL 생성
    const shareUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=${hashtags}`;

    // 새 창으로 열기
    window.open(shareUrl, '_blank', 'width=550,height=420');
  }, [link, title, track, xHashTags]);

  return (
    <SnsContainer>
      <ButtonContainer>
        <ImageButton backgroundImage={kakaoImage} onClick={handleKakaoClick} />
        <ImageButton backgroundImage={xImage} onClick={shareToX} />
        <ImageButton backgroundImage={CopyIcon} onClick={handleCopy} />
      </ButtonContainer>
    </SnsContainer>
  );
}
