import { HOME_INITIAL_STYLES, HOME_STYLES_COUNT } from '@/consts';
import Banner from '@/pages/screenplay/Home/Banner';
import EmotionList from '@/pages/screenplay/Home/EmotionList';
import Header from '@/pages/screenplay/Home/Header';
import VoiceList from '@/pages/screenplay/Home/VoiceList';
import { StyledHomeContainer } from '@/pages/screenplay/styles/StyledHomeContainer';
import { useVoiceProfilesQuery } from '@/query/useVoiceProfilesQuery';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function HomeContainer() {
  const { flattenedVoiceProfiles, isLoading, uniqueStyles } =
    useVoiceProfilesQuery();
  const [styles, setStyles] = useState<string[]>(HOME_INITIAL_STYLES);
  const [selectedStyle, setSelectedStyle] = useState<string>(
    HOME_INITIAL_STYLES[0]
  );
  const { i18n } = useTranslation();

  const handleStyleChange = useCallback(() => {
    if (!uniqueStyles) return;
    // 랜덤하게 10개의 스타일을 선택
    const shuffled = [...uniqueStyles].sort(() => 0.5 - Math.random());
    const randomStyles = shuffled.slice(0, HOME_STYLES_COUNT);
    setStyles(randomStyles);
    setSelectedStyle(randomStyles[0]);
  }, [uniqueStyles]);

  useEffect(() => {
    document.body.style.minWidth = '0';
  }, []);

  return (
    <>
      <Banner />
      <StyledHomeContainer>
        <Header />
        {isLoading ? null : (
          <>
            <EmotionList
              selectedStyle={selectedStyle}
              onStyleSelect={setSelectedStyle}
              styles={styles}
              onStyleChange={handleStyleChange}
            />
            <VoiceList
              voices={flattenedVoiceProfiles?.filter(
                (voice) =>
                  voice.style === selectedStyle &&
                  voice.language === i18n.language
              )}
            />
          </>
        )}
      </StyledHomeContainer>
    </>
  );
}
