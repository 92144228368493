import { MOBILE_BREAKPOINT } from '@/consts/breakpoints';
import { Black, Grey, Primary } from '@/styles/Colors';
import styled from '@emotion/styled';

export const StyledVoiceItem = styled.div<{ isEditing: boolean }>`
  .item-container {
    margin-bottom: 8px;
  }

  .script-container {
    background-color: ${Grey[600]};
    border-radius: 100px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: ${({ isEditing }) => (isEditing ? 'cursor' : 'pointer')};

    &:hover {
      background-color: ${Grey[500]};
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      border-radius: 16px;
      padding: 16px;
      display: block;
    }
  }

  .script-container-left {
    display: flex;
    align-items: center;
  }

  .relative {
    position: relative;
  }

  .playing-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #000000e6;
    box-shadow: 0 0 3px 0 #000000e6;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      width: 58px;
      height: 58px;
    }
  }

  .playing {
    width: 80%;
    height: 80%;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      width: 70%;
      height: 70%;
    }
  }

  .profile-image {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
  }

  .script {
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
    color: ${Grey[100]};
    margin-left: 16px;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      margin-left: 0;

      height: 72px;
      overflow-y: auto;
    }
  }

  .primary-script {
    color: ${Primary[200]};
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    line-height: 140%;

    &::placeholder {
      color: ${Grey[400]};
      opacity: 1; /* Firefox에서 opacity 설정이 필요할 수 있음 */
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .hover-button {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    position: relative;
    .hover-button-tooltip {
      width: 290px;
      position: absolute;
      color: ${Grey[150]};
      border-radius: 6px;
      background-color: ${Black};
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      top: -46px;
      left: -150px;
      flex: 1 1 auto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      display: none;
    }
    :hover {
      .hover-button-tooltip {
        display: flex;
      }
    }
  }

  .script-container:hover .hover-button {
    opacity: 1;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    .desktop {
      display: none;
    }
  }

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    .mobile {
      display: none;
    }
  }

  .mobile-bottom-container {
    margin-top: 10px;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .mobile-buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .mobile-input-textarea {
    height: 65px;
  }

  .circular-progress-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circular-progress {
    width: 30px;
    height: 30px;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
