import { TTSPreviewParams } from '@/pages/screenplay/api';
import useAxios from '@/pages/screenplay/hooks/useAxios';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useRef } from 'react';

export const usePreviewTTS = () => {
  const { generatePreviewTTS } = useAxios();
  const abortControllerRef = useRef<AbortController>();

  const mutation = useMutation({
    mutationFn: async (params: TTSPreviewParams) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      return generatePreviewTTS({
        ...params,
        signal: abortControllerRef.current.signal,
      });
    },
  });

  const cancel = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }, []);

  return { ...mutation, cancel };
};
