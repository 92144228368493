import styled from '@emotion/styled';

interface StyledImageButtonProps {
  size?: number;
  backgroundImage: string;
}

export const StyledImageButton = styled.button<StyledImageButtonProps>`
  /* reset button */
  margin: 0;
  background: none;
  border: none;
  outline: none;
  /* button style */
  position: relative;
  width: 52px;
  height: 52px;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;

  div {
    position: absolute;
    inset: 0;
    background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
    background-size: cover;
    background-position: center;
  }
`;
