import { MOBILE_BREAKPOINT } from '@/consts/breakpoints';
import styled from '@emotion/styled';

export const StyledHomeContainer = styled.div`
  max-width: 1396px;
  width: calc(100% - 7.5rem);
  min-width: 0;
  margin: 0 auto 200px auto;
  padding: 0 46px;

  font-family: 'Pretendard Variable';

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    /* TODO: 원인을 모르겠는데 -2.5rem 해줘야 가로 스크롤 안생김 */
    width: calc(100% - 2.5rem);
    padding: 0 16px;
  }
`;
