import { ReactComponent as InfoIcon } from '@/assets/icons/InfoIcon.svg';
import {
  StyledVoiceList,
  StyledVoiceListEmpty,
} from '@/pages/screenplay/Home/StyledVoiceList';
import { VoiceListItem } from '@/pages/screenplay/Home/VoiceListItem';
import { Language } from '@/pages/screenplay/VoiceLibrary/types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function VoiceList({
  voices,
}: {
  voices: {
    id: string;
    language: Language;
    thumbnailPath: string;
    script: string;
    sample: string;
    style: string;
  }[];
}) {
  const [playingId, setPlayingId] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [currentAudioUrl, setCurrentAudioUrl] = useState<string | null>(null);
  const { t } = useTranslation();
  const [isAudioEnded, setIsAudioEnded] = useState(true);

  const handlePlayPause = (id: string, audioUrl: string | undefined) => {
    if (!audioUrl) return;

    if (playingId === id) {
      audioRef.current?.pause();
      setPlayingId(null);
    } else {
      if (audioUrl !== currentAudioUrl) {
        if (audioRef.current) {
          audioRef.current.pause();
        }
        const audio = new Audio(audioUrl);
        audioRef.current = audio;
        setCurrentAudioUrl(audioUrl);
      }

      audioRef.current?.play();
      setPlayingId(id);
      setIsAudioEnded(false);

      if (audioRef.current) {
        audioRef.current.onended = () => {
          setPlayingId(null);
          setIsAudioEnded(true);
        };
      }
    }
  };

  const resetAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsAudioEnded(true);
      setPlayingId(null);
    }
  };

  if (voices.length === 0) {
    return (
      <StyledVoiceListEmpty>
        <InfoIcon />
        <div className="text">{t('home.noVoice')}</div>
      </StyledVoiceListEmpty>
    );
  }

  return (
    <StyledVoiceList>
      {voices.map((voice) => (
        <VoiceListItem
          key={voice.id}
          voiceId={voice.id}
          style={voice.style}
          script={voice.script}
          sample={voice.sample}
          isPlaying={playingId === voice.id}
          onPlayPause={handlePlayPause}
          thumbnailPath={voice.thumbnailPath}
          language={voice.language}
          resetAudio={resetAudio}
          setCurrentAudioUrl={setCurrentAudioUrl}
          currentAudioUrl={currentAudioUrl}
          isAudioEnded={isAudioEnded}
        />
      ))}
    </StyledVoiceList>
  );
}
