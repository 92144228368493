import { Language } from '../stores/data/config';
import { Parameter } from '../stores/project';
export const languageMap = {
  ko: 'ko-nn',
  en: 'en-us',
  ja: 'ja',
};
export interface ZeroShotParameter extends Parameter {
  similarity: number;
}
export type ZeroShotParameterKey = keyof ZeroShotParameter;

export interface TTSParams {
  text: string;
  language: Language;
  voice_id: string;
  take_count: number;
  parameters: {
    pitch_shift: number;
    pitch_variance: number;
    speed: number;
  };
}
export interface CVCParams {
  voice_id: string;
  parameters: {
    gender: number;
    age: number;
    pitch_shift: number;
    pitch_variance: number;
    speed: number;
  };
  source_resource_id: string;
}

export interface TTSPreviewParams {
  text: string;
  voiceId: string;
  language: Language;
}
