import { KAKAO_SDK_KEY } from '@/consts';
import { useEffect, useState } from 'react';

const KakaoScriptBasePath =
  'https://t1.kakaocdn.net/kakao_js_sdk/2.7.4/kakao.min.js';

export default function useLoadKakao() {
  const [isLoaded, setIsLoaded] = useState(false);

  const kakaoInitialized = () => {
    const kakao = window.Kakao;

    if (kakao && !kakao.isInitialized()) {
      kakao.init(KAKAO_SDK_KEY);
    }
  };

  useEffect(() => {
    if (isLoaded) {
      return;
    }

    const scriptElement = document.createElement('script');
    scriptElement.src = KakaoScriptBasePath;
    scriptElement.async = true;
    scriptElement.integrity =
      'sha384-DKYJZ8NLiK8MN4/C5P2dtSmLQ4KwPaoqAfyA/DfmEc1VDxu4yyC7wy6K1Hs90nka';
    scriptElement.crossOrigin = 'anonymous';
    scriptElement.onload = () => {
      kakaoInitialized();
      setIsLoaded(true);
    };
    document.head.appendChild(scriptElement);
  }, [isLoaded]);
}
