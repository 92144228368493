import Loading from '@/components/Loading/Loading';
import StyledAfterSignUp from '@/layout/Auth/StyledAfterSignUp';
import useAxios from '@/pages/screenplay/hooks/useAxios';
import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import {
  GA_CONVERSION_AD_ID,
  UTM_SESSION_STORAGE_KEY,
} from '@/pages/screenplay/stores/data/config';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const AfterSignUp = () => {
  const { checkTrial } = useAxios();
  const { track } = useLog();
  const { isLoading, isAuthenticated } = useSUPAuth();
  const [isRedirect, setIsRedirect] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const queryParamsObject = Object.fromEntries(queryParams.entries());
  const redirectURI = queryParamsObject['from'];

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      checkTrial()
        .then((result) => {
          if (result.data.isTrialStarted) {
            const utms = window.sessionStorage.getItem(UTM_SESSION_STORAGE_KEY);
            if (utms) {
              const utmObjects = JSON.parse(utms);
              track('REGISTER_USER', {
                utm_campaign: utmObjects.utm_campaign,
                utm_content: utmObjects.utm_content,
                utm_medium: utmObjects.utm_medium,
                utm_source: utmObjects.utm_source,
              });
              window.sessionStorage.removeItem(UTM_SESSION_STORAGE_KEY);
            } else {
              track('REGISTER_USER', {});
            }

            if (process.env.REACT_APP_ENV === 'prd') {
              window.gtag('event', 'conversion', {
                send_to: GA_CONVERSION_AD_ID,
              });
            }
          }
          setIsRedirect(true);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);
  return isRedirect ? (
    <Navigate to={redirectURI ?? '/'} />
  ) : (
    <StyledAfterSignUp>
      <div className="layout">
        <Loading />
      </div>
    </StyledAfterSignUp>
  );
};

export default AfterSignUp;
