import { Grey, Primary } from '@/styles/Colors';
import styled from '@emotion/styled';

interface StyledChipProps {
  color: 'primary' | 'secondary';
}

export const StyledChip = styled.div<StyledChipProps>`
  border-radius: 100px;
  cursor: pointer;
  padding: 12px 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  background-color: ${({ color }) =>
    color === 'primary' ? `${Primary[400]}` : `${Grey[600]}`};

  color: ${({ color }) => (color === 'primary' ? 'white' : `${Grey[200]}`)};
`;
