import { AvatarContainer, AvatarImage } from './StyledProfileAvatar';

interface ProfileAvatarProps {
  src: string;
  alt?: string;
  size?: number;
}

export const ProfileAvatar = ({
  src,
  alt = '',
  size = 40,
}: ProfileAvatarProps) => {
  return (
    <AvatarContainer size={size}>
      <AvatarImage src={src} alt={alt} />
    </AvatarContainer>
  );
};
