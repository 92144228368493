import IconButton from '@/components/Button/IconButton';
import { useEditorContext } from '@/pages/screenplay/providers/EditorContextProvider';
import { AudioItemInfo } from '@/pages/screenplay/stores/audios';
import { AudioBufferTake } from '@/pages/screenplay/stores/project';
import { TimelineListItem } from '@/pages/screenplay/stores/timeline';
import { Profile } from '@/pages/screenplay/stores/voice';
import { useVoiceLibraryQuery } from '@/query/useVoiceLibraryQuery';
import { formatSToMMSS } from '@/util/formatter';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ReactComponent as BackwardIcon } from '../assets/icons/BackwardIcon.svg';
import { ReactComponent as ForwardIcon } from '../assets/icons/ForwardIcon.svg';
import { ReactComponent as PauseIcon } from '../assets/icons/PauseIcon.svg';
import { ReactComponent as PlayIcon } from '../assets/icons/PlayIcon.svg';
import {
  AudioSource,
  useMultiAudioController,
} from '../hooks/useMultiAudioController';
import { audioFileMapAtom, audioPlayerStateAtom } from '../stores/atoms/audio';
import {
  currentProjectAtom,
  lineListAtom,
  takeListAtom,
} from '../stores/atoms/project';
import { isLoadingProjectAtom, isTextEditingAtom } from '../stores/atoms/ui';
import { StyledGlobalAudioPlayer } from '../styles/StyledGlobalAudioPlayer';
import VolumeSlider from './VolumeSlider';

const GlobalAudioPlayer = () => {
  const { selectedTakeByLine } = useEditorContext();
  const audioFileMap = useRecoilValue(audioFileMapAtom);
  const [audioPlayerState, setAudioPlayerState] =
    useRecoilState(audioPlayerStateAtom);
  const isTextEditing = useRecoilValue(isTextEditingAtom);
  const lineList = useRecoilValue(lineListAtom);
  const takeList = useRecoilValue(takeListAtom);
  const currentProject = useRecoilValue(currentProjectAtom);
  const [currentPlayingAudioInfoList, setCurrentPlayingAudioInfoList] =
    useState<AudioItemInfo[]>([]);
  const isLoadingProject = useRecoilValue(isLoadingProjectAtom);
  const { voiceProfileList } = useVoiceLibraryQuery();

  const [isLoaded, setIsLoaded] = useState(false);
  const [audioSources, setAudioSources] = useState<AudioSource[]>([]);

  const {
    currentPosition,
    totalDuration,
    gainValue,
    isPlaying,
    isNext,
    isPrev,
    currentAudioInfo,
    play,
    pause,
    stop,
    forward,
    backward,
    updateVolume,
    seek,
  } = useMultiAudioController({
    audioSources,
  });

  const activeTakeList = useMemo(() => {
    return lineList.reduce<AudioBufferTake[]>((acc, line) => {
      const selectedTakeId = selectedTakeByLine[line.id];
      if (selectedTakeId) {
        const take = takeList.find((take) => take.id === selectedTakeId);
        if (take) {
          acc.push({
            ...take,
            audioBuffer: audioFileMap[take.id]?.audioBuffer,
          } as AudioBufferTake);
        }
      }
      return acc;
    }, []);
  }, [selectedTakeByLine, lineList, takeList, audioFileMap]);

  const timelineList = useMemo(() => {
    // duration 및 line 데이터 셋업
    const list = activeTakeList.reduce<TimelineListItem[]>(
      (acc, take, index) => {
        const profile = voiceProfileList?.find((p: Profile) =>
          p.voices.find((v) => v.id === take.voiceId)
        );
        if (!take || !take.audioBuffer) return acc;

        // 여기가 take정보로 치환 하면 되는 부분 => take생성시 position 선행필요 이미 있는 로직이라 Take 생성쪽으로 이동 필요
        const timelineItem = {
          id: take.id,
          type: take.type,
          lineId: take.lineId,
          takeLabel: `${index + 1}`,
          content: take.text,
          position: index,
          duration: take.audioBuffer?.duration || 0,
        };

        const timeline = acc.find((item) => item.voice.id === profile?.id);
        if (timeline) {
          timeline.items.push(timelineItem);
        } else {
          const thumbnail = profile?.thumbnails.find(
            (t) => t.purpose === 'icon'
          )?.path;

          acc.push({
            voice: {
              id: profile?.id || '',
              name: profile?.name || '',
              type: 'voice',
              thumbnail,
            },
            items: [timelineItem],
          });
        }
        return acc;
      },
      []
    );

    return [...list];
  }, [voiceProfileList, activeTakeList]);

  const currentPlayingAudio = useMemo(() => {
    if (!timelineList.length) return null;
    const [info] = currentPlayingAudioInfoList;
    for (const tl of timelineList) {
      const item = tl.items.find((item) => item.id === info?.id);
      if (item) {
        return {
          id: item.id,
          type: item.type,
          lineId: item.lineId,
          content: item.content,
          takeLabel: item.takeLabel,
          thumbnail: tl.voice.thumbnail,
        };
      }
    }
    return null;
  }, [currentPlayingAudioInfoList, timelineList]);

  useEffect(() => {
    setIsLoaded(!isLoadingProject);
  }, [isLoadingProject]);

  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  }, [isPlaying, play, pause]);

  // audioPlayerState에 오디오 재생 상태를 저장
  useEffect(() => {
    setAudioPlayerState({
      type: 'timeline',
      isPlaying,
    });
  }, [isPlaying, setAudioPlayerState]);

  // timeline 외(Line 플레이어 등)에서 재생 중이면 멈춤
  useEffect(() => {
    if (!audioPlayerState) {
      pause();
      return;
    }
    if (audioPlayerState?.type === 'timeline') return;
    if (audioPlayerState?.isPlaying) {
      pause();
    }
  }, [audioPlayerState, pause]);

  useEffect(() => {
    if (!isLoaded || !timelineList?.length) return;
    const sources = timelineList.reduce((acc, { items, voice }) => {
      items.forEach((item) => {
        if (!audioFileMap[item.id] || voice.disabled) return;
        const audioSource = {
          id: item.id,
          fileName: item.content,
          type: item.type,
          position: item.position,
          duration: audioFileMap[item.id].audioBuffer?.duration || 0,
          audioBuffer: audioFileMap[item.id].audioBuffer,
          ...{
            lineId: item.lineId,
          },
        };
        acc.push(audioSource);
      });
      return acc.sort((a, b) => a.position - b.position);
    }, [] as AudioSource[]);
    setAudioSources(sources);
  }, [timelineList, isLoaded, audioFileMap, setAudioSources]);

  useEffect(() => {
    if (!isLoaded) return;
    setCurrentPlayingAudioInfoList(currentAudioInfo);
  }, [currentAudioInfo, setCurrentPlayingAudioInfoList, isLoaded]);

  // TODO: Register hotkeys (tmp code)
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isTextEditing) return;
      switch (e.code) {
        case 'Enter':
          e.preventDefault();
          seek(0);
          break;
        case 'Space':
          e.preventDefault();
          handlePlayPause();
          break;
        case 'ArrowRight':
          e.preventDefault();
          forward();
          break;
        case 'ArrowLeft':
          e.preventDefault();
          backward();
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isTextEditing, forward, backward, handlePlayPause, seek]);

  // audioSource의 변경이 발생한 경우(bgm on/off, timeline에 아이템 추가) 일시정지
  useEffect(() => {
    if (isPlaying) {
      pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioSources]);

  // Reset current playback when selected project changed
  useEffect(() => {
    requestAnimationFrame(() => {
      stop();
    });
  }, [currentProject?.id, stop]);

  const progressPosition = useMemo(() => {
    const position =
      totalDuration === 0
        ? -100
        : ((currentPosition - totalDuration) / totalDuration) * 100;
    return position >= 0 ? 0 : position;
  }, [currentPosition, totalDuration]);

  const hasItem = useMemo(() => {
    return takeList.length > 0;
  }, [takeList]);

  return (
    <StyledGlobalAudioPlayer
      className={classNames('sup-player', !hasItem && 'no-takes')}
    >
      <section className="progress">
        <div
          className="progress-bar"
          style={{
            transform: `translateX(${progressPosition}%)`,
          }}
        ></div>
      </section>
      <section className="control-audio">
        <IconButton
          className={classNames('btn-play', isPlaying && 'active')}
          variant="none"
          onClick={handlePlayPause}
          disabled={!isLoaded || !hasItem}
        >
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </IconButton>
        <IconButton
          className="btn-backward"
          variant="none"
          disabled={!isPrev}
          onClick={backward}
        >
          <BackwardIcon />
        </IconButton>
        <IconButton
          className="btn-forward"
          variant="none"
          disabled={!isNext}
          onClick={forward}
        >
          <ForwardIcon />
        </IconButton>
      </section>
      <section
        className={classNames('scene-info', !currentPlayingAudio && 'empty')}
      >
        {currentPlayingAudio && currentPlayingAudio.type === 'tts' && (
          <div className="scene-info-tts">
            <div className="number">{currentPlayingAudio.takeLabel}</div>
            <div
              className="thumb"
              style={{
                background: `url(${currentPlayingAudio.thumbnail}) center center / cover no-repeat`,
              }}
            ></div>
            <div className="content">{currentPlayingAudio.content}</div>
          </div>
        )}
        {!hasItem && (
          <p className="scene-info-empty">
            There is no take to play it. Please generate a new take.
          </p>
        )}
      </section>
      <section className="time-info">
        {`${formatSToMMSS(currentPosition)} / ${formatSToMMSS(totalDuration)}`}
      </section>
      <VolumeSlider value={gainValue} update={updateVolume} />
    </StyledGlobalAudioPlayer>
  );
};
export default GlobalAudioPlayer;
