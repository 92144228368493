import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Login = () => {
  const { loginWithRedirect } = useSUPAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectUri = window.location.origin + '/after-signup?' + queryParams;

  useEffect(() => {
    // login 페이지의 히스토리를 제거합니다. auth0페이지에서 뒤로가기 눌렀을때 홈으로 이동되도록 함
    window.history.replaceState(null, '', '/');
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        redirect_uri: redirectUri,
      },
    }).then(() => {});
  }, [loginWithRedirect, redirectUri]);

  return <></>;
};

export default Login;
