import Button from '@/components/Button/Button';
import { StyledApiCopy } from '@/pages/screenplay/ControlPanel/StyledApiCopy';
import { activeLineSelector } from '@/pages/screenplay/stores/atoms/project';
import { toastMessageAtom } from '@/pages/screenplay/stores/atoms/ui';
import clipboardCopy from '@/util/clipboardCopy';
import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactComponent as CopyIcon } from '../assets/icons/Copy.svg';

const ApiCopy: React.FC = () => {
  const setToastMessage = useSetRecoilState(toastMessageAtom);
  const activeLine = useRecoilValue(activeLineSelector);

  const copyParameter = useCallback(async () => {
    if (activeLine?.draft?.parameter) {
      await clipboardCopy(
        JSON.stringify({
          voice_settings: {
            ...activeLine.draft.parameter,
          },
        })
      );
      setToastMessage('Voice settings copied');
    }
  }, [activeLine?.draft?.parameter, setToastMessage]);

  const copyVoiceId = useCallback(async () => {
    if (activeLine?.draft?.voiceId) {
      await clipboardCopy(activeLine?.draft?.voiceId);
      setToastMessage('Voice Id copied');
    }
  }, [activeLine?.draft?.voiceId, setToastMessage]);

  return (
    <StyledApiCopy>
      <section className="api-copy">
        <Button
          fullWidth
          variant={'none'}
          color={'white'}
          startIcon={<CopyIcon />}
          onClick={copyVoiceId}
        >
          Copy voice Id
        </Button>
        <Button
          size={'lg'}
          fullWidth
          variant={'none'}
          color={'white'}
          startIcon={<CopyIcon />}
          onClick={copyParameter}
        >
          Copy voice settings
        </Button>
      </section>
    </StyledApiCopy>
  );
};
export default ApiCopy;
