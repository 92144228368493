import useMigration from '@/hooks/useMigration';
import Migration from '@/pages/screenplay/components/Migration';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
} from 'react';
import { useRecoilState } from 'recoil';

import { takeJobIdMapAtom } from '../stores/atoms/project';

interface DataContextProps {
  // globally shared state
  takeJobIdMap: Record<string, string>;
  updateTakeJobIdMap: (value: Record<string, string>) => void;
}

const DataContext = createContext<DataContextProps>({} as DataContextProps);

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('DataContextProvider not supported');
  }
  return context;
};

const DataContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useSUPAuth();
  const [takeJobIdMap, setTakeJobIdMap] = useRecoilState(takeJobIdMapAtom);
  const { migrationStatus } = useMigration(user);

  const updateTakeJobIdMap = useCallback(
    (value: Record<string, string>) => {
      setTakeJobIdMap((prev) => {
        return {
          ...prev,
          ...value,
        };
      });
    },
    [setTakeJobIdMap]
  );

  if (migrationStatus !== '' && migrationStatus !== 'done') {
    return <Migration migrationStatus={migrationStatus} />;
  }

  return (
    <DataContext.Provider
      value={{
        // global state
        takeJobIdMap,
        updateTakeJobIdMap,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
export default DataContextProvider;
