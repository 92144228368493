import Slider from '@/components/Slider/Slider';
import {
  activeLineSelector,
  takeListAtom,
} from '@/pages/screenplay/stores/atoms/project';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { useEditorContext } from '../providers/EditorContextProvider';
import { Parameter } from '../stores/project';

interface SpeechControlsProps {
  controls: {
    value: number;
    change: (value: number) => void;
    id: string;
    title: string;
    min: number;
    max: number;
    step: number;
    defaultValue: number;
  }[];
}
const SpeechControls: React.FC<SpeechControlsProps> = ({ controls }) => {
  const { updateDraft, selectedTakeByLine } = useEditorContext();
  const takeList = useRecoilValue(takeListAtom);
  const activeLine = useRecoilValue(activeLineSelector);
  const onChangeDraft = useCallback(
    (key: string, value: number) => {
      const draftParams = activeLine?.draft?.parameter;
      const activeTakeParams = takeList.find(
        (take) => take.id === selectedTakeByLine[activeLine?.id ?? '']
      )?.parameter;
      const preParams = draftParams || activeTakeParams;
      activeLine &&
        updateDraft(activeLine.id, {
          ...activeLine?.draft,
          parameter: {
            ...(preParams as Parameter),
            [key]: value,
          },
        });
    },
    [activeLine, takeList, updateDraft, selectedTakeByLine]
  );
  return (
    <section className="take-generate-content-section take-speech-control">
      <ul>
        {controls.map((item, index) => (
          <li
            key={index}
            className={classNames(
              'slider-control',
              index === controls.length - 1 && 'speed'
            )}
          >
            <section className="slider-control-header">
              <span className="slider-control-title">{item.title}</span>
              <span className={classNames('slider-value')}>{item.value}</span>
            </section>
            <span className="slider-control-content">
              <span className="control-min">{item.min}</span>
              <Slider
                min={item.min}
                max={item.max}
                value={item.value}
                showMarks={false}
                step={item.step}
                defaultValue={item.defaultValue}
                useDefaultTrack={true}
                onChange={(value) => item.change(value)}
                onEnd={(value) => onChangeDraft(item.id, value)}
              />
              <span className="control-max">{item.max}</span>
            </span>
          </li>
        ))}
      </ul>
    </section>
  );
};
export default SpeechControls;
