import 'swiper/css';
import 'swiper/css/navigation';

import { ReactComponent as ArrowBackIcon } from '@/assets/icons/ArroaBackIcon.svg';
import { ReactComponent as ArrowNextIcon } from '@/assets/icons/ArrowNextIcon.svg';
import { ReactComponent as CycleIcon } from '@/assets/icons/CycleIcon.svg';
import SupButton from '@/components/Button/SupButton';
import Chip from '@/components/Chip/Chip';
import { useLog } from '@/pages/screenplay/hooks/useLog/useLog';
import { StyledEmotionList } from '@/pages/screenplay/styles/StyledEmotionList';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { Swiper as SwiperType } from 'swiper';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// TODO: emotion 을 style로 이름 바꾸기
export default function EmotionList({
  selectedStyle,
  onStyleSelect,
  styles,
  onStyleChange,
}: {
  selectedStyle: string;
  onStyleSelect: (style: string) => void;
  styles: string[];
  onStyleChange: () => void;
}) {
  const { t } = useTranslation();
  const swiperRef = useRef<SwiperType>();
  const { track } = useLog();

  return (
    <StyledEmotionList>
      <div className="swiper-container">
        <Swiper
          onSwiper={(swiper: SwiperType) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation]}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          slidesPerView="auto"
          spaceBetween={8}
          slidesPerGroup={2}
        >
          {styles.map((style) => (
            <SwiperSlide key={style}>
              <Chip
                color={style === selectedStyle ? 'primary' : 'secondary'}
                label={t(`style.${style}`)}
                onClick={() => {
                  track('CLICK_EMOTION_TAB', {
                    style,
                  });
                  onStyleSelect(style);
                }}
              />
            </SwiperSlide>
          ))}
          <div className="swiper-button-prev">
            <SupButton
              width="36px"
              height="36px"
              borderRadius="50%"
              color="black"
            >
              <ArrowBackIcon />
            </SupButton>
          </div>
          <div className="swiper-button-next">
            <SupButton
              width="36px"
              height="36px"
              borderRadius="50%"
              color="black"
            >
              <ArrowNextIcon />
            </SupButton>
          </div>
        </Swiper>
      </div>
      <div className="cycle-icon-container">
        <SupButton
          width="48px"
          height="48px"
          borderRadius="50%"
          color="grey"
          onClick={() => {
            track('CLICK_REFRESH');
            onStyleChange();
            swiperRef.current?.slideTo(0);
          }}
        >
          <CycleIcon />
        </SupButton>
      </div>
    </StyledEmotionList>
  );
}
