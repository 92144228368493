import { Grey, PureBlack } from '@/styles/Colors';
import styled from '@emotion/styled';

export const StyledUserInfo = styled.section`
  padding-right: 1rem;
  .initial {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background-color: ${Grey[100]};
    text-align: center;
    line-height: 150%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${PureBlack};
    font-size: 1.25rem;
  }
`;

export const StyledUserLayer = styled.div`
  padding: 1rem 0.75rem 0.5rem;
  z-index: 100;
  min-width: 20.8rem;
  .info {
    text-align: center;
    white-space: nowrap;
    display: block;
    margin-bottom: 1rem;
    line-height: 100%;
  }
  .status {
    background-color: ${Grey[475]};
    margin-bottom: 2.4rem;
    border-radius: 0.4rem;
    padding: 0.5rem 3rem;
    div {
      height: 1.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .logout {
    width: 100%;
    min-width: 9.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: bold;
    height: 4.3rem;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
`;
