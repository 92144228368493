import styled from '@emotion/styled';

export const SnsContainer = styled.div`
  margin-top: 62px;
  display: flex;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 204px;
  align-items: center;
  justify-content: space-between;
`;
