import { AudioSource } from './index';

export const createAudioNodes = (
  audioContext: AudioContext,
  audioSources: AudioSource[],
  gainValueNode: GainNode,
  startPosition: number,
  onEnded?: () => void
): AudioScheduledSourceNode[] => {
  let runningAudioNodeCount = 0;

  const handleAudioNodeEnded = (event: Event) => {
    const audioNode = event.target as AudioScheduledSourceNode;
    audioNode.removeEventListener('ended', handleAudioNodeEnded);
    audioNode.disconnect();

    runningAudioNodeCount--;

    if (runningAudioNodeCount === 0) {
      onEnded?.();
    }
  };

  return audioSources
    .filter(({ position, duration }) => position + duration > startPosition)
    .map(
      ({ position, duration, audioBuffer, type, startOffset, endOffset }) => {
        const audioNode = audioContext.createBufferSource();
        audioNode.buffer = audioBuffer;
        audioNode.connect(gainValueNode);
        audioNode.loop = true;
        audioNode.addEventListener('ended', handleAudioNodeEnded);

        const when = position - startPosition + (startOffset || 0);
        if (when >= 0) {
          const newDuration = duration - (endOffset || 0);
          audioNode.start(
            when + audioContext.currentTime,
            startOffset || 0,
            newDuration
          );
        } else {
          const newDuration = duration + when - (endOffset || 0);
          audioNode.start(0, -when + (startOffset || 0), newDuration);
        }

        runningAudioNodeCount++;
        return audioNode;
      }
    );
};
