import { useEditorContext } from '@/pages/screenplay/providers/EditorContextProvider';
import { currentProjectAtom } from '@/pages/screenplay/stores/atoms/project';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

export const useVoice = () => {
  const { updateProject } = useEditorContext();
  const currentProject = useRecoilValue(currentProjectAtom);

  const addVoice = useCallback(
    async (voiceId: string) => {
      await updateProject({
        voiceIds: [...(currentProject?.voiceIds ?? []), voiceId],
      });
    },
    [currentProject, updateProject]
  );

  const deleteVoice = useCallback(
    async (deletedVoiceId: string) => {
      await updateProject({
        voiceIds: currentProject?.voiceIds.filter(
          (voiceId: string) => deletedVoiceId !== voiceId
        ),
      });
    },
    [currentProject, updateProject]
  );

  const projectVoiceIds = useMemo(
    () => currentProject?.voiceIds ?? [],
    [currentProject?.voiceIds]
  );

  return {
    addVoice,
    deleteVoice,
    projectVoiceIds,
  };
};
