import useAxios from '@/pages/screenplay/hooks/useAxios';
import { Project } from '@/pages/screenplay/stores/project';
import { useCallback, useEffect, useState } from 'react';

const useProjects = (id?: string) => {
  const { postProject, deleteProject, putProject, fetchProjects } = useAxios();
  const [projectList, setProjectList] = useState<Project[] | null>(null);

  const addProject = useCallback(
    async (project: Partial<Project>) => {
      const addedProject = await postProject({
        name: project.name ?? '',
        language: project.language ?? '',
        voiceIds: project.voiceIds ?? [],
      });
      setProjectList([...(projectList ?? []), addedProject]);
      return addedProject;
    },
    [postProject, projectList, setProjectList]
  );

  const removeProject = useCallback(
    async (projectId: string) => {
      await deleteProject(projectId);
      if (projectList) {
        setProjectList([
          ...projectList.filter((project) => project.id !== projectId),
        ]);
      }
    },
    [deleteProject, projectList, setProjectList]
  );

  const updateProject = useCallback(
    async (updatedProject: Partial<Project>) => {
      await putProject(updatedProject);
      setProjectList((prev) => {
        return (prev ?? []).map((p) => {
          if (p.id === updatedProject.id) {
            return {
              ...p,
              ...updatedProject,
            };
          }
          return p;
        });
      });
    },
    [putProject, setProjectList]
  );

  useEffect(() => {
    fetchProjects().then((projectList) => {
      setProjectList(projectList);
    });
  }, [fetchProjects]);

  useEffect(() => {});

  return {
    updateProject,
    removeProject,
    addProject,
    projectList,
  };
};

export default useProjects;
