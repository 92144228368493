import { KAKAO_SDK_KEY } from '@/consts';
import useLoadKakao from '@/hooks/useLoadKakao';

declare global {
  interface Window {
    Kakao: any;
  }
}

export interface ShareParam {
  shareTitle: string;
  shareDescription: string;
  imageUrl: string;
  linkUrl: string;
  buttonTitle: string;
  imageWidth: number;
  imageHeight: number;
}

export default function useKakaoShare({
  shareTitle,
  shareDescription,
  imageUrl,
  buttonTitle,
  linkUrl,
  imageWidth,
  imageHeight,
}: ShareParam) {
  useLoadKakao();

  const handleClickKaKaoShare = () => {
    const kakao = window.Kakao;

    if (!window.Kakao) {
      void alert('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
      return;
    }
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(KAKAO_SDK_KEY);
    }

    kakao?.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: shareTitle,
        description: shareDescription,
        imageUrl: imageUrl,
        imageWidth,
        imageHeight,
        link: {
          mobileWebUrl: linkUrl,
          webUrl: linkUrl,
        },
      },
      buttons: [
        {
          title: buttonTitle,
          link: {
            mobileWebUrl: linkUrl,
            webUrl: linkUrl,
          },
        },
      ],
      installTalk: true,
    });
  };

  return {
    handleClickKaKaoShare,
  };
}
