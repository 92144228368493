import { Grey } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

export const StyledDesktopFooter = styled.section`
  max-width: 1396px;
  padding: 32px 46px;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  .text-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-size: 1.75rem; // 28px
    font-weight: 700;
    line-height: 150%;
    color: ${Grey[100]};

    .footer-header {
      display: flex;
      align-items: center;
      .slogan {
        font-size: 32px;
        line-height: 48px;
        font-weight: ${FontWeight.Medium};
      }
      .logo-cursor {
        margin-left: 4px;
        animation: blink 1s infinite; /* 1초 간격으로 무한 반복 */
      }
    }

    .description {
      font-size: 18px;
      font-weight: ${FontWeight.Regular};
    }
    .terms-policy {
      color: ${Grey[300]};
      font-size: 12px;
      font-weight: ${FontWeight.Medium};
      .link {
        color: ${Grey[300]};
      }
    }
  }

  .link-container {
    display: flex;
    gap: 5px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
