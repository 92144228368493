'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';

const DEFAULT_RETRY = 2;
export default function ReactQueryProviders({
  children,
}: React.PropsWithChildren) {
  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnMount: false,
          refetchOnWindowFocus: false,
          retry: (failureCount: number, error: Error) => {
            if (error instanceof AxiosError) {
              const { response } = error;
              if (response?.status === 400 || response?.status === 404) {
                return false;
              }
              return failureCount < DEFAULT_RETRY;
            }
            return false;
          },
          staleTime: Infinity,
        },
      },
    });
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
