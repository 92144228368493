import CommonHeader from '@/pages/screenplay/components/CommonHeader';
import QuickMenu from '@/pages/screenplay/components/QuickMenu';
import { StyledCommonLayout } from '@/pages/screenplay/styles/StyledCommonLayout';
import React, { PropsWithChildren } from 'react';

const CommonLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledCommonLayout>
      <CommonHeader />
      <QuickMenu />
      <section className="layout-content">{children}</section>
    </StyledCommonLayout>
  );
};
export default CommonLayout;
