import { Black, Grey } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

const StyledScriptModal = styled.section`
  .script-modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 1.6rem;
      font-weight: ${FontWeight['SemiBold']};
      margin-bottom: 0.75rem;
    }

    .description {
      font-size: 1.1rem;
      color: ${Grey[400]};
      margin-bottom: 5rem;
    }
  }

  .script-modal-body {
    width: 46rem;
    height: 11rem;
    display: flex;

    .left-side {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      justify-content: space-between;
      border-right: 1px solid ${Grey[500]};
      font-size: ${FontSize['Xl']};
      line-height: ${FontSize['3Xl']};
      color: ${Grey[100]};
      padding: 0 1.6rem;

      .btn-import-file {
        border-radius: 4px;
        border: 1px solid ${Grey[400]};
        color: ${Grey[400]};
        cursor: pointer;
        font-size: ${FontSize.Xl};
        line-height: ${FontSize['5Xl']};
        font-weight: ${FontWeight['SemiBold']};
        width: 6rem;
        display: flex;
        justify-content: center;
        input {
          visibility: hidden;
          position: absolute;
          width: 0;
          height: 0;
        }
        &:hover {
          color: ${Grey[50]};
        }
      }
    }

    .right-side {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 1.6rem;
    }

    .action-desc {
      display: flex;
      justify-content: space-between;
      font-size: ${FontSize['2Xl']};
      color: ${Grey[400]};

      .file-format {
        display: flex;
        justify-content: center;
        background: ${Black};
        color: ${Grey[50]};
        width: 4rem;
      }
    }

    .button-layout-container {
      display: flex;
      justify-content: end;
    }
  }
`;

export default StyledScriptModal;
