function findKeysByValue<T extends Record<string, string>, V>(
  obj: T,
  value: V
): keyof T {
  const keys: (keyof T)[] = [];
  for (const key in obj) {
    if (obj[key] === value) {
      keys.push(key);
    }
  }
  return keys[0] ?? '';
}
export default findKeysByValue;
