import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import { useVoiceLibraryQuery } from '@/query/useVoiceLibraryQuery';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as CloseIcon } from '../assets/icons/CloseIcon_40_37.svg';
import { useLog } from '../hooks/useLog/useLog';
import useSingleAudioController from '../hooks/useSingleAudioController';
import { audioPlayerStateAtom } from '../stores/atoms/audio';
import { useToggleVoiceLibrary } from '../stores/recoilHooks/useToggles';
import { useVoice } from '../stores/recoilHooks/useVoice';
import { Profile } from '../stores/voice';
import StyledVoiceLibrary from '../styles/StyledVoiceLibrary';
import { FILTER_OPTIONS } from './const';
import { Filter, VoiceTab, VoiceTabValue } from './types';
import VoiceFilter from './VoiceFilter';
import VoiceLibraryItem from './VoiceLibraryItem';

const VoiceLibrary = () => {
  const { t } = useTranslation();
  const { voiceProfileList } = useVoiceLibraryQuery();
  const { closeVoiceLibrary } = useToggleVoiceLibrary();
  const setAudioPlayerState = useSetRecoilState(audioPlayerStateAtom);
  const { addVoice, deleteVoice, projectVoiceIds } = useVoice();

  const tabList: VoiceTab[] = [{ title: t('All Voices'), value: 'all' }];

  const [selectedTab, setSelectedTab] = useState<VoiceTabValue>(
    tabList[0].value
  );

  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    const escape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeVoiceLibrary();
      }
    };
    window.addEventListener('keydown', escape);
    return () => {
      window.removeEventListener('keydown', escape);
    };
  }, [closeVoiceLibrary]);

  const [openProfileId, setOpenProfileId] = useState<string | null>(null);
  const toggleItem = useCallback(
    (profileId: string) => {
      if (profileId === openProfileId) {
        setOpenProfileId(null);
      } else {
        setOpenProfileId(profileId);
      }
    },
    [openProfileId]
  );

  // voiceEditfileInfo 추가 또는 수정, projectProfileList 업데이트
  const onAdd = useCallback(
    async (voiceId: string) => {
      await addVoice(voiceId);
    },
    [addVoice]
  );

  // voiceEditfileInfo 삭제, projectProfileList 업데이트
  const onDelete = useCallback(
    async (voiceId: string) => {
      await deleteVoice(voiceId);
    },
    [deleteVoice]
  );

  const { play, stop, isPlaying } = useSingleAudioController();
  const [playingId, setPlayingId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const playAudio = useCallback(
    (audioBuffer: AudioBuffer) => {
      if (isPlaying) {
        stop();
      }
      play(0, audioBuffer);
    },
    [isPlaying, play, stop]
  );

  const stopAudio = useCallback(() => {
    stop();
  }, [stop]);

  // 모달이 열릴 때 재생중인 오디오를 정지시키기 위한 로직
  useEffect(() => {
    setAudioPlayerState(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filters, setFilters] = useState<Filter[]>();

  const filteredVoiceProfileList = useMemo(() => {
    let list = selectedTab === 'all' ? voiceProfileList : [];
    // filters 필터링
    const currentFilters = filters?.filter((filter) =>
      filter.options.some((option) => option.value)
    );
    if (currentFilters) {
      list = list?.filter((profile) =>
        currentFilters.every((filter) =>
          filter.options.some(
            (option) =>
              option.value &&
              profile[
                FILTER_OPTIONS.find((item) => item.title === filter.title)!.key
              ] === option.label
          )
        )
      );
    }
    if (searchText) {
      list = list?.filter((profile) =>
        profile.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return list;
  }, [
    searchText,
    voiceProfileList,
    // projectVoiceProfileList,
    selectedTab,
    filters,
  ]);

  const updateFilter = useCallback(
    (filter: Filter) => {
      if (!filters) return;
      setFilters(
        filters.map((item) => (item.title === filter.title ? filter : item))
      );
    },
    [filters]
  );

  const initFilters = useCallback(() => {
    const filtersMap = new Map<string, { label: string; value: boolean }[]>();
    voiceProfileList?.forEach((profile) => {
      FILTER_OPTIONS.forEach((option) => {
        let optionsArray = filtersMap.get(option.title);
        if (!optionsArray) {
          optionsArray = [
            { label: profile[option.key] as string, value: false },
          ];
          filtersMap.set(option.title, optionsArray);
        } else {
          const optionExists = optionsArray.some(
            (item) => item.label === profile[option.key]
          );
          if (!optionExists) {
            optionsArray.push({
              label: profile[option.key] as string,
              value: false,
            });
          }
        }
      });
    });
    const filters: Filter[] = Array.from(filtersMap, ([title, options]) => ({
      title,
      options,
      isCollapsed: false,
    }));
    setFilters(filters);
  }, [voiceProfileList]);

  const { track } = useLog();
  const handleCloseVoiceLibrary = useCallback(() => {
    track('CLOSE_VOICE_LIBRARY');
    closeVoiceLibrary();
  }, [closeVoiceLibrary, track]);

  const handleClearFilters = useCallback(() => {
    filters &&
      setFilters(
        filters.map((item) => ({
          ...item,
          options: item.options.map((option) => ({ ...option, value: false })),
        }))
      );
    searchText && setSearchText('');
  }, [filters, searchText]);

  useEffect(() => {
    initFilters();
  }, [initFilters]);

  return (
    <StyledVoiceLibrary className="voice-library">
      <section className="overlay-layer" onClick={closeVoiceLibrary} />
      <section className="voice-library-container">
        <section className="voice-library-header">
          <section className="voice-library-header-title">
            {t('Voice Library')}
          </section>
          <IconButton
            className="btn-close"
            variant="none"
            color={'#ffffff'}
            onClick={handleCloseVoiceLibrary}
          >
            <CloseIcon />
          </IconButton>
        </section>
        <section className="voice-library-body">
          <section className="voice-library-filter">
            <div className="search">
              <h3 className="search-title">{t('Search & Filter')}</h3>
              <div className="input">
                <i className="search-icon"></i>
                <input
                  type="text"
                  placeholder="Search for name"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <section className="clear-all-area">
                <Button
                  className="btn-clear-all"
                  variant="none"
                  size="lg"
                  onClick={handleClearFilters}
                >
                  {t('Clear All')}
                </Button>
              </section>
            </div>
            <ul className="filter">
              {filters?.map((filter) => (
                <VoiceFilter
                  key={filter.title}
                  filter={filter}
                  updateFilter={updateFilter}
                />
              ))}
            </ul>
          </section>
          <section className="voice-library-list">
            <ul className="voice-library-tab">
              {tabList.map((tab) => (
                <li
                  key={tab.value}
                  className={classNames(selectedTab === tab.value && 'active')}
                >
                  <Button
                    variant="none"
                    onClick={() => setSelectedTab(tab.value)}
                  >
                    {tab.title}
                  </Button>
                </li>
              ))}
            </ul>
            <ul className="voice-library-title">
              <li>{t('Voice Name')}</li>
              <li>{t('Native Language')}</li>
              <li>{t('Gender')}</li>
              <li>{t('Age')}</li>
              <li>{t('Use Case')}</li>
            </ul>
            {filteredVoiceProfileList?.length ? (
              <ul className="voice-library-items">
                {filteredVoiceProfileList.map((profile: Profile) => (
                  <VoiceLibraryItem
                    profile={profile}
                    isOpen={profile.id === openProfileId}
                    toggleItem={toggleItem}
                    key={profile.id}
                    onAdd={onAdd}
                    onDelete={onDelete}
                    playAudio={playAudio}
                    stopAudio={stopAudio}
                    isPlaying={isPlaying}
                    playingId={playingId}
                    updatePlayingId={setPlayingId}
                    isLoading={isLoading}
                    isAdded={projectVoiceIds.includes(profile.id)}
                    updateIsLoading={setIsLoading}
                  />
                ))}
              </ul>
            ) : (
              <div className="voice-library-empty">
                <h3>{t('No Match Found')}</h3>
                <p>{t('We could not find the voice for given criteria.')}</p>
              </div>
            )}
          </section>
        </section>
      </section>
    </StyledVoiceLibrary>
  );
};

export default VoiceLibrary;
