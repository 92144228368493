import PayModal from '@/components/Modal/PayModal/PayModal';
import Paddle from '@/components/Portal/Paddle';
import {
  showPayModalAtom,
  showPaymentOverrayAtom,
} from '@/pages/screenplay/stores/atoms/ui';
import { PropsWithChildren } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

export default function GlobalModal({ children }: PropsWithChildren) {
  const [isShowPayModal, setPayModalOpen] = useRecoilState(showPayModalAtom);
  const isShowPaymentOverRay = useRecoilValue(showPaymentOverrayAtom);
  return (
    <>
      {children}
      <>
        {isShowPayModal && (
          <PayModal isOpen onClose={() => setPayModalOpen(false)} />
        )}
        {isShowPaymentOverRay && <Paddle />}
      </>
    </>
  );
}
