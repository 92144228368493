import { FONT_SIZE } from '@/components/Button/const';
import styled from '@emotion/styled';

export const StyledMigration = styled.section`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: ${FONT_SIZE.xl};
`;
