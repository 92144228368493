import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Grey, White } from '../../styles/Colors';
import { FontSize, FontWeight } from '../../styles/Typography';

interface StyledModalProps {
  isPortal?: boolean;
  backdropColor?: string;
}

export const StyledModal = styled.div(
  <T extends StyledModalProps>({ isPortal, backdropColor }: T) => css`
    .modal-overlay {
      position: ${isPortal ? 'fixed' : 'absolute'};
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      opacity: 0.64;
      background-color: ${backdropColor};
      z-index: 999;
    }
    .modal {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1000;
      transform: translate(-50%, -50%);
      background: ${Grey[600]};
      border-radius: 0.5rem;
      box-sizing: border-box;
      /* TODO: 디자인 시스템의 모달 최소 사이즈 확인 필요 */
      min-width: 11.8rem;
      &-close-button {
        position: absolute;
        padding: 0;
        width: 1.5rem;
        height: 1.5rem;
        top: 1.25rem;
        right: 1rem;
      }
      &-header {
        padding: 0.5rem 1rem;
        border-bottom: 1px solid ${White};
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-title {
          margin: 0;
          font-size: ${FontSize['Lg']};
          font-weight: ${FontWeight['SemiBold']};
          color: ${White};
        }
      }
      &-body {
        padding: 1.25rem;
        font-size: ${FontSize['Md']};
        color: ${White};
      }
      &-footer {
        padding: 1rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .sup-button {
          margin-left: 1rem;
          border-radius: 0.25rem;
          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  `
);
