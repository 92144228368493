import useAxios from '@/pages/screenplay/hooks/useAxios';
import { Profile } from '@/pages/screenplay/stores/voice';
import { useQuery } from '@tanstack/react-query';

export const useVoiceLibraryQuery = () => {
  const { getVoiceLibrary } = useAxios();
  const { isLoading, isError, data } = useQuery<Profile[] | undefined>({
    queryKey: ['voiceLibrary'],
    queryFn: getVoiceLibrary,
  });

  return { voiceProfileList: data, isLoading, isError };
};
