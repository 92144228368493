import { Line, Project, Scene, Take } from '../stores/project';
import { ProfileEditInfo } from '../stores/voice';

const DB_NAME = 'PSP_DB';
const DB_VERSION = 3;
const PROJECT_LIST = 'projects';
const SCENE_LIST = 'scenes';
const LINE_LIST = 'lines';
const VOICE_LIST = 'voices';
const TAKE_LIST = 'takes';
const USER_INFO = 'userInfo';
const MIGRATION_INFO = 'migrationInfo';

const STORE_LIST = [
  PROJECT_LIST,
  LINE_LIST,
  VOICE_LIST,
  TAKE_LIST,
  USER_INFO,
  MIGRATION_INFO,
];

const openDB = async (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(DB_NAME, DB_VERSION);
    openRequest.onupgradeneeded = function (e: IDBVersionChangeEvent) {
      const target = e.target as IDBOpenDBRequest;
      const db = target.result as IDBDatabase;

      for (const store of STORE_LIST) {
        if (!db.objectStoreNames.contains(store)) {
          db.createObjectStore(store);
        }
      }

      // const txn = target.transaction;
      // console.log(e.newVersion, e.oldVersion);
      // if (txn && e.newVersion === 3 && e.newVersion !== e.oldVersion) {
      //   // Get exiting objectStore
      //   const sceneList = txn.objectStore('CatalogItem');
      // }
    };

    openRequest.onerror = function (e) {
      reject('Error opening db');
    };

    openRequest.onsuccess = function (event: Event) {
      const db = (event.target as IDBOpenDBRequest)?.result;
      resolve(db as IDBDatabase);
    };
  });
};
//
const addData = async <T extends { id: string; projectId?: string }>(
  storeName: string,
  data: T
): Promise<void> => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    let tx = db.transaction(storeName, 'readwrite');
    let store = tx.objectStore(storeName);

    store.add({ ...data, createdAt: new Date() }, data.id);

    tx.oncomplete = function () {
      // if (data?.projectId) {
      //   _updateProject(data.projectId);
      // }
      resolve();
    };

    tx.onerror = function () {
      reject('Error adding item to db');
    };
  });
};

const getData = async <T>(
  storeName: string,
  key?: string
): Promise<T | undefined> => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    try {
      let transaction = db.transaction(storeName, 'readonly');
      let store = transaction.objectStore(storeName);

      if (key === undefined) {
        const getRequest = store.getAll();
        getRequest.onerror = function () {
          reject('Error getting item from db');
        };

        getRequest.onsuccess = function (e: any) {
          resolve(e.target.result as T);
        };
      } else {
        const getRequest = store.get(key);
        getRequest.onerror = function () {
          reject('Error getting item from db');
        };

        getRequest.onsuccess = function (e: any) {
          resolve(e.target.result as T);
        };
      }
    } catch (error) {
      resolve(undefined);
    }
  });
};

const curryingWithException = <ResultType>(fn: Function) => {
  return async (...args: any[]) => {
    try {
      return (await fn(...args)) as ResultType;
    } catch (err) {
      console.error(err);
    }
  };
};

// 프로젝트 목록 조회
export const _loadProjects = curryingWithException<Project[]>(async () => {
  // todo project 생성 및 update 타이밍에 따른 처리 필요
  const result = (await getData(PROJECT_LIST)) as Project[];
  result.sort((a, b) => {
    if (a.createdAt && b.createdAt) {
      return a.createdAt > b.createdAt ? -1 : 1;
    }
    return 0;
  });
  return result;
});

// 데이터베이스 연결
export const initIndexedDB = async (
  email: string
): Promise<{
  migrationData: {
    projectList: Project[];
    sceneList: Scene[];
    lineList: Line[];
    takeList: Take[];
    voiceList: ProfileEditInfo[];
  };
}> => {
  await openDB();
  // await resetDB(email);
  const needMigration = await checkNeedMigration();
  if (needMigration) {
    // project에 lineIds 추가
    const projectList = await getData<Project[]>(PROJECT_LIST);
    const sceneList = await getData<Scene[]>(SCENE_LIST);
    const lineList = await getData<Line[]>(LINE_LIST);
    const takeList = await getData<Take[]>(TAKE_LIST);
    const voiceList = await getData<ProfileEditInfo[]>(VOICE_LIST);
    return {
      migrationData: {
        projectList: projectList ?? [],
        sceneList: sceneList ?? [],
        lineList: lineList ?? [],
        takeList:
          takeList?.filter((take) => !!take.resource_id && !!take.text) ?? [],
        voiceList: voiceList ?? [],
      },
    };
  }
  return {
    migrationData: {
      projectList: [],
      sceneList: [],
      lineList: [],
      takeList: [],
      voiceList: [],
    },
  };
};

export const checkNeedMigration = async (): Promise<boolean> => {
  const migratonInfo = await getData<{ id: string; didMigration: boolean }[]>(
    MIGRATION_INFO
  );
  return !migratonInfo?.[0]?.didMigration;
};

export const updateFinishMigration = async (): Promise<void> => {
  await addData(MIGRATION_INFO, { id: 'migration', didMigration: true });
};
//
// // 프로젝트 추가
// export const _addProject = curryingWithException<Project>(
//   async (project?: Project) => {
//     const id = uuid();
//     await addData(
//       PROJECT_LIST,
//       project || {
//         id: id,
//         name: 'New Project',
//         language: defaultLanguage,
//       }
//     );
//     return await getData(PROJECT_LIST, id);
//   }
// );
//
// // 프로젝트 조회
// export const _loadProject = curryingWithException<Project>(
//   async (projectId: string) => {
//     // todo project 생성 및 update 타이밍에 따른 처리 필요
//     const result = await getData(PROJECT_LIST, projectId);
//     return result as Project;
//   }
// );
//
// // 프로젝트 삭제
// export const _deleteProject = curryingWithException<Project[]>(
//   async (projectId: string) => {
//     await deleteData(PROJECT_LIST, projectId);
//     const result = await getData(PROJECT_LIST);
//     return result;
//   }
// );
//
// // 프로젝트 수정
// export const _updateProject = curryingWithException<Project>(
//   async (project: Project | string) => {
//     if (typeof project === 'string') {
//       const result = (await getData(PROJECT_LIST, project)) as Project;
//       if (result) {
//         await updateData(PROJECT_LIST, { ...result, updatedAt: new Date() });
//       }
//       return await getData(PROJECT_LIST, project);
//     } else {
//       await updateData(PROJECT_LIST, project);
//       return await getData(PROJECT_LIST, project.id);
//     }
//   }
// );
//
// // 라인 목록 조회, 라인 순서는 Scene이 가지고 있는다.
// export const _loadLines = curryingWithException<Line[]>(
//   async (projectId?: string): Promise<Line[]> => {
//     const project = await getData<Project>(PROJECT_LIST, projectId);
//     const result = await getData<Line[]>(LINE_LIST);
//     return project
//       ? (project?.lineIds
//           ?.map((id) => result?.find((line) => line.id === id))
//           .filter((line) => line !== undefined) as Line[])
//       : (result as Line[]);
//   }
// );
//
// // 라인 추가
// export const _addLine = curryingWithException<Line>(
//   async (line: Line, standId?: string, updateProject: boolean = true) => {
//     if (standId !== undefined) {
//       const project = await getData<Project>(PROJECT_LIST, line.projectId);
//       const lineIds = project?.lineIds || [];
//       const index = project?.lineIds?.indexOf(standId) || 0;
//       lineIds.splice(index + 1, 0, line.id);
//       await addData(LINE_LIST, line);
//       return await getData(LINE_LIST, line.id);
//     } else {
//       if (line.id === undefined) {
//         line.id = uuid();
//       }
//       // if (updateProject) {
//       //   const project = await getData<Project>(PROJECT_LIST, line.projectId);
//       //   const lineIds = (project?.lineIds || []).concat(line.id);
//       //   await _updateProject({ ...project, lineIds } as Project);
//       //   await addData(LINE_LIST, line);
//       //   return await getData(LINE_LIST, line.id);
//       // } else {
//       //
//       // }
//     }
//   }
// );
//
// // 라인 삭제
// export const _deleteLine = curryingWithException<Project>(
//   async (lineId: string, updateProject: boolean = true) => {
//     if (updateProject) {
//       const line = await getData<Line>(LINE_LIST, lineId);
//       const project = await getData<Project>(PROJECT_LIST, line?.projectId);
//       const lineIds = project?.lineIds?.filter((id) => id !== lineId);
//       await _updateProject({ ...project, lineIds } as Project);
//       await deleteData(LINE_LIST, lineId);
//       return await getData(LINE_LIST);
//     } else {
//       await deleteData(LINE_LIST, lineId);
//       return await getData(LINE_LIST);
//     }
//   }
// );
//
// // 라인 멀티플 삭제
// export const _deleteLines = curryingWithException(
//   async (lineIds: string[], sceneId: string) => {
//     await Promise.all(
//       lineIds.map(async (lineId) => {
//         await deleteData(LINE_LIST, lineId);
//         await _deleteTakes(lineId);
//       })
//     );
//     return await _loadLines(sceneId);
//   }
// );
//
// // 라인 수정
// export const _updateLine = curryingWithException<Line>(async (line: Line) => {
//   await updateData(LINE_LIST, line);
//   return await getData(LINE_LIST, line.id);
// });
//
// export const _updateLines = curryingWithException<Line[]>(
//   async (lines: Line[]) => {
//     Promise.all(
//       lines.map(async (line) => {
//         await updateData(LINE_LIST, line);
//       })
//     );
//     return await _loadLines(lines[0].projectId);
//   }
// );
//
// // 테이크 목록 조회
// export const _loadTakes = curryingWithException<Take[]>(
//   async (projectId?: string) => {
//     const result = await getData<Take[]>(TAKE_LIST);
//     if (!projectId) return result;
//     const takes = result?.filter((take) => take.projectId === projectId);
//     takes?.sort((a, b) => {
//       if (a.createdAt && b.createdAt) {
//         return a.createdAt.getTime() < b.createdAt.getTime() ? -1 : 1;
//       }
//       return 0;
//     });
//     return takes;
//   }
// );
//
// // 테이크 추가
// export const _addTake = curryingWithException<Take[]>(
//   async (take: Take | Take[]) => {
//     if (Array.isArray(take)) {
//       for (const t of take) {
//         await addData(TAKE_LIST, t);
//       }
//     } else {
//       await addData(TAKE_LIST, take);
//     }
//     return await getData<Take[]>(TAKE_LIST);
//   }
// );
//
// // 테이크 삭제
// export const _deleteTake = curryingWithException<Take[]>(
//   async (takeId: string) => {
//     await deleteData(TAKE_LIST, takeId);
//     return await getData(TAKE_LIST);
//   }
// );

// // 라인에 종속된 테이크들 삭제
// export const _deleteTakes = curryingWithException<Take[]>(
//   async (lineId: string) => {
//     const takes = await getData<Take[]>(TAKE_LIST);
//     const lineTakes = takes?.filter((take) => take.lineId === lineId);
//     if (lineTakes) {
//       for (const take of lineTakes) {
//         await deleteData(TAKE_LIST, take.id);
//       }
//     }
//     return await getData(TAKE_LIST);
//   }
// );
//
// // 테이크 수정
// export const _updateTake = curryingWithException<Take[]>(async (take: Take) => {
//   await updateData(TAKE_LIST, take);
//   return await getData(TAKE_LIST);
// });
//
// export const _updateTakes = curryingWithException<Take[]>(
//   async (takes: Take[]) => {
//     Promise.all(
//       takes.map(async (take) => {
//         await updateData(TAKE_LIST, take);
//       })
//     );
//     return await getData(TAKE_LIST);
//   }
// );

// Voice 정보 조회
export const _loadProfileEditInfoList = curryingWithException<
  ProfileEditInfo[]
>(async (projectId: string) => {
  const result = await getData<ProfileEditInfo[]>(VOICE_LIST);
  return result?.filter((voice) => voice.projectId === projectId);
});

// // Voice 정보 추가
// export const _addProfileEditInfo = curryingWithException<ProfileEditInfo[]>(
//   async (editInfo: ProfileEditInfo) => {
//     await addData(VOICE_LIST, editInfo);
//     return await _loadProfileEditInfoList(editInfo.projectId);
//   }
// );
//
// // Voice 정보 삭제
// export const _deleteProfileEditInfo = curryingWithException<ProfileEditInfo[]>(
//   async (editInfo: ProfileEditInfo) => {
//     await deleteData(VOICE_LIST, editInfo.id);
//     return await _loadProfileEditInfoList(editInfo.projectId);
//   }
// );
