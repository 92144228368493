import { activeLineIdAtom } from '@/pages/screenplay/stores/atoms/project';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  isModalOpenAtom,
  isShowVoiceLibraryAtom,
  toastMessageAtom,
} from '../atoms/ui';

export const useResetUI = () => {
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const setIsShowVoiceLibrary = useSetRecoilState(isShowVoiceLibraryAtom);
  const setActiveLineId = useSetRecoilState(activeLineIdAtom);
  const setToastMessage = useSetRecoilState(toastMessageAtom);
  const resetUI = useCallback(() => {
    setIsModalOpen(false);
    setIsShowVoiceLibrary(false);
    setActiveLineId(null);
    setToastMessage('');
  }, [setIsModalOpen, setIsShowVoiceLibrary, setActiveLineId, setToastMessage]);
  return {
    resetUI,
  };
};
