import { StyledSupButton } from '@/components/Button/StyledSupButton';

// 현재는 모든 값을 받아서 처리하지만, 추후 디자인 시스템이 생기면 버튼 type을 받아서 처리할 예정
export default function SupButton({
  color,
  width,
  height,
  borderRadius,
  children,
  onClick,
  disabled,
}: {
  color?: 'grey' | 'white' | 'black';
  width?: string;
  height?: string;
  borderRadius?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | (() => void);
  disabled?: boolean;
}) {
  return (
    <StyledSupButton
      width={width}
      height={height}
      borderRadius={borderRadius}
      color={color}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </StyledSupButton>
  );
}
