import { Grey } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

export const StyledMobileFooter = styled.section`
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  display: flex;
  flex-direction: column;
  font-size: 1.75rem; // 28px
  font-weight: 700;
  color: ${Grey[100]};
  width: 100%;
  padding: 16px 8px;

  .footer-header {
    display: flex;
    align-items: center;
    .slogan {
      font-size: 24px;
      line-height: 36px;
      font-weight: ${FontWeight.Medium};
    }
    .logo-cursor {
      margin-left: 4px;
      animation: blink 1s infinite; /* 1초 간격으로 무한 반복 */
    }
  }

  .description {
    padding: 0 20px;
    display: flex;
    text-align: center;
    font-size: 14px;
    font-weight: ${FontWeight.Regular};
  }
  .terms-policy {
    padding: 0 10px;
    color: ${Grey[300]};
    font-size: 12px;
    font-weight: ${FontWeight.Medium};
    text-align: center;

    .link {
      color: ${Grey[300]};
    }
  }

  .link-container {
    display: flex;
    gap: 5px;

    .link {
      background-color: #767676;
      border-radius: 50%;
      width: 28px;
      height: 28px;
    }
  }
`;
