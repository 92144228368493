import { Grey } from '@/styles/Colors';
import styled from '@emotion/styled';

export const StyledSupButton = styled.button<{
  width?: string;
  height?: string;
  borderRadius?: string;
  color?: 'grey' | 'white' | 'black';
}>`
  border-radius: ${({ borderRadius }) => borderRadius || 'auto'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  ${({ color }) =>
    ({
      grey: `
        background-color: ${Grey[450]};
        color: ${Grey[50]};
        svg {
          fill: ${Grey[50]};
        }
        
        &:hover {
          background-color: ${Grey[400]};
        }
        
        &:disabled {
          background-color: ${Grey[400]};
          cursor: not-allowed;
          svg path {
            fill: ${Grey[450]};
          }
        }
      `,
      white: `
        background-color: ${Grey[50]};
        color: ${Grey[800]};
        svg path {
          fill: ${Grey[800]};
        }
      `,
      black: `
        background-color: ${Grey[800]};
        border: 1px solid ${Grey[450]};
        svg path {
          fill: ${Grey[100]};
        }
        
        &:hover {
          background-color: ${Grey[450]};
          svg path {
            fill: ${Grey[100]};
          }
        }
        
        &:disabled {
          cursor: not-allowed;
          svg path {
            fill: ${Grey[500]};
          }
        }
      `,
    }[color || 'grey'])}
`;
