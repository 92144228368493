import { PUBLIC_ENDPOINTS } from '@/consts';
import PermissionError from '@/error/PermissionError';
import { Line, Project, Take } from '@/pages/screenplay/stores/project';
import {
  exportAudioBufferToFile,
  getAudioBuffer,
  getAudioContext,
} from '@/util/audio';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { useCallback, useEffect, useMemo } from 'react';

import { ResourceFileResponse, ResourceResponse, upload } from '../../../api';
import {
  CVCParams,
  TTSParams,
  TTSPreviewParams,
  ZeroShotParameter,
} from '../api';
import { SPEECH_CONTROL_LIST } from '../ControlPanel/TakeGeneratePanel';
import {
  DEFAULT_VOICE,
  Language,
  MEME_VOICE,
  PERMISSION_ERROR_CODE,
} from '../stores/data/config';
import { Profile, ProfilesResponse, VoiceTtsRequest } from '../stores/voice';
import { languagePriority } from '../VoiceLibrary/const';

type ZeroShotLanguage = 'ko-nn' | 'en-us' | 'ja';
const languageMap: Record<Language, ZeroShotLanguage> = {
  ko: 'ko-nn',
  en: 'en-us',
  ja: 'ja',
};

const createInstances = () => ({
  screenplay: axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
  }),
  play: axios.create({
    baseURL: process.env.REACT_APP_PLAY_API_HOST,
  }),
  voiceLibrary: axios.create({
    baseURL: process.env.REACT_APP_VOICE_LIBRARY_API_HOST,
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      'service-name': 'screenplay',
    },
  }),
  zeroShot: axios.create({
    baseURL: process.env.REACT_APP_ZERO_SHOT_API_HOST,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'arraybuffer',
  }),
});

const useAxios = () => {
  const { getAccessTokenSilently } = useSUPAuth();
  const instances = useMemo(() => createInstances(), []);

  useEffect(() => {
    const interceptorIDs: Record<string, number> = {};

    const setupInterceptors = (instance: AxiosInstance, key: string) => {
      const requestInterceptorId = instance.interceptors.request.use(
        async (config: InternalAxiosRequestConfig) => {
          if (
            PUBLIC_ENDPOINTS.some((endpoint) =>
              config.url?.endsWith(endpoint)
            ) ||
            key === 'zeroShot'
          ) {
            return config;
          }

          try {
            const token = await getAccessTokenSilently();
            if (token) {
              config.headers.Authorization = `Bearer ${token}`;
            }
          } catch (error) {
            return Promise.reject(error);
          }
          return config;
        },
        (error: any) => {
          return Promise.reject(error);
        }
      );
      interceptorIDs[key] = requestInterceptorId;
    };

    Object.entries(instances).forEach(([key, instance]) => {
      setupInterceptors(instance, key);
    });

    return () => {
      Object.entries(instances).forEach(([key, instance]) => {
        instance.interceptors.request.eject(interceptorIDs[key]);
      });
    };
  }, [getAccessTokenSilently, instances]);

  const getVoiceLibrary = useCallback(async () => {
    try {
      const { data } = await instances.voiceLibrary.get<ProfilesResponse>(
        '/profiles'
      );

      // tmp: sort 인터널 툴에서 처리하기 전 임시로 사용
      // a-z, profile.language -> ko > en > ja
      const profiles = data.data.sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name);
        if (nameComparison !== 0) return nameComparison;
        return languagePriority[a.language] - languagePriority[b.language];
      });

      const assignDisplayName = (profile: Profile): Profile => ({
        ...profile,
        displayName: profile.name
          .replace(MEME_VOICE, '')
          .replace(DEFAULT_VOICE, ''),
      });
      /**
       * []Meme], []Deafult]
       * */
      return (profiles || []).map((profile: any) => assignDisplayName(profile));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          // 403번의 경우 권한이 없는 경우로 간주, 관리자 승인 페이지로 리다이렉트
          if (e.response.data.message?.statusCode === PERMISSION_ERROR_CODE) {
            window.location.assign('/message/needs-approval');
          }
        }
      } else {
        console.error('Unexpected error: ', e);
      }
    }
  }, [instances.voiceLibrary]);

  const createTtsZeroShot = async (
    tgt: File,
    text: string,
    lang: Language,
    parameter: ZeroShotParameter,
    num_takes: number
  ) => {
    const { data } = await instances.zeroShot.post('/predictions/tts', {
      text,
      tgt: tgt,
      lang: languageMap[lang],
      pitch_shift: parameter.pitch_shift,
      pitch_variance: parameter.pitch_variance,
      speed: parameter.speed,
      similarity: parameter.similarity,
      num_takes,
      api: 'tts',
      model_type: 'v1.2.1',
    });

    const audioCtx = getAudioContext();
    const audioBuffer = await getAudioBuffer(data);
    let buffers = [];
    for (let i = 0; i < (audioBuffer?.numberOfChannels || 0); i++) {
      const channelData = audioBuffer?.getChannelData(i);
      if (channelData) {
        let buffer = audioCtx.createBuffer(
          1,
          channelData.length,
          audioCtx.sampleRate
        );
        buffer.copyToChannel(channelData, 0);
        buffers.push(buffer);
      }
    }
    return buffers;
  };

  const getUploadInfo = useCallback(
    async (sessionId: string, name: string, type: string) => {
      return (await instances.screenplay.post(
        '/resources',
        {
          name,
          content_type: type,
          purposes: ['playback'],
        },
        { headers: { 'x-session-id': sessionId } }
      )) as AxiosResponse<ResourceResponse>;
    },
    [instances.screenplay]
  );

  const getResourceInfo = useCallback(
    async (resourceId: string) => {
      return (await instances.screenplay.get(
        `/resources/${resourceId}`
      )) as AxiosResponse<ResourceFileResponse>;
    },
    [instances.screenplay]
  );

  const createTts = useCallback(
    async (params: TTSParams, sessionId: string) => {
      try {
        const result = await instances.screenplay.post('/tts', params, {
          headers: { 'X-Session-Id': sessionId },
        });
        return result;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.data.message?.statusCode === PERMISSION_ERROR_CODE) {
            throw new PermissionError('permissionError');
          }
        } else {
          console.error('Unexpected error: ', e);
        }
      }
    },
    [instances.screenplay]
  );

  const createCvc = useCallback(
    async (params: CVCParams, sessionId: string) => {
      try {
        const result = await instances.screenplay.post('/cvc', params, {
          headers: { 'X-Session-Id': sessionId },
        });
        return result;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.data) {
            // 403번의 경우 권한이 없는 경우로 간주, 관리자 승인 페이지로 리다이렉트
            if (e.response.data.message?.statusCode === PERMISSION_ERROR_CODE) {
              window.location.assign('/message/needs-approval');
            }
          }
        } else {
          console.error('Unexpected error: ', e);
        }
      }
    },
    [instances.screenplay]
  );

  // tmp: 추후 cdn 적용되면 대체 예정
  const createTtsForVoice = useCallback(
    async (voice: VoiceTtsRequest, text: string, sessionId: string) => {
      const { data } = await instances.screenplay.post(
        '/tts',
        {
          text,
          language: voice.language,
          voice_id: voice.voice_id,
          take_count: 1,
          parameters: {
            pitch_shift: SPEECH_CONTROL_LIST[0].defaultValue,
            pitch_variance: SPEECH_CONTROL_LIST[1].defaultValue,
            speed: SPEECH_CONTROL_LIST[2].defaultValue,
          },
        } as TTSParams,
        {
          headers: { 'X-Session-Id': sessionId },
        }
      );
      const audioBuffer = await getAudioBuffer(data);
      const file = exportAudioBufferToFile(
        audioBuffer as AudioBuffer,
        'tts.wav'
      );
      const uploadInfo = await getUploadInfo(
        sessionId,
        'audio.wav',
        'audio/wav'
      );
      upload(uploadInfo.data.data.upload_url, file);
      return {
        resourceId: uploadInfo.data.data.resource_id,
        audioBuffer,
      };
    },
    [getUploadInfo, instances.screenplay]
  );

  const checkTrial = useCallback(async () => {
    return await instances.play.post<{ isTrialStarted: boolean }>(
      '/user/check-trial'
    );
  }, [instances.play]);

  /**
   * 유저 데이터 CRUD
   * */

  const fetchProject = useCallback(
    async (id: string) => {
      const result = await instances.play.get<Project>(
        `project/get-project?id=${id}`
      );
      return result.data;
    },
    [instances.play]
  );

  const fetchProjects = useCallback(async () => {
    const result = await instances.play.get<Project[]>('project/list-projects');
    return result.data;
  }, [instances.play]);

  const postProject = useCallback(
    async (param: {
      name: string;
      language: string;
      voiceIds?: string[];
    }): Promise<Project> => {
      const result = await instances.play.post('project/create-project', {
        ...param,
      });
      return result.data.props;
    },
    [instances.play]
  );

  const putProject = useCallback(
    async (param: Partial<Project>): Promise<boolean> => {
      const result = await instances.play.post('project/update-project', {
        ...param,
      });
      return result.data.props;
    },
    [instances.play]
  );

  const deleteProject = useCallback(
    async (projectId: string): Promise<Project> => {
      const result = await instances.play.delete(
        `project/delete-project?id=${projectId}`
      );
      return result.data.props;
    },
    [instances.play]
  );

  const putLine = useCallback(
    async (line: Partial<Line>): Promise<Line> => {
      const result = await instances.play.post('project/update-line', {
        ...line,
      });
      return result.data;
    },
    [instances.play]
  );

  const fetchLines = useCallback(
    async (projectId: string) => {
      const result = await instances.play.get<Line[]>(
        `project/list-lines?projectId=${projectId}`
      );

      return result.data;
    },
    [instances.play]
  );

  const postLine = useCallback(
    async (line: Partial<Line>): Promise<Line> => {
      const result = await instances.play.post('project/create-line', {
        ...line,
      });

      return result.data.props;
    },
    [instances.play]
  );

  const postLines = useCallback(
    async (takes: Partial<Line>[]): Promise<Line[]> => {
      const result = await instances.play.post('project/create-lines', takes);

      return result.data;
    },
    [instances.play]
  );

  const deleteLine = useCallback(
    async (lineId: string) => {
      const result = await instances.play.delete(`project/delete?id=${lineId}`);
      return result.data.props;
    },
    [instances.play]
  );

  const putTake = useCallback(
    async (take: Partial<Take>) => {
      const result = await instances.play.post('project/update-take', {
        ...take,
      });
      return result.data.props;
    },
    [instances.play]
  );

  const fetchTakes = useCallback(
    async (lineIds: string[]) => {
      const result = await instances.play.post<Take[]>(`project/list-takes`, {
        lineIds,
      });
      return result.data;
    },
    [instances.play]
  );

  const postTake = useCallback(
    async (take: Take) => {
      const result = await instances.play.post('project/create-take', {
        ...take,
      });

      return result.data.props;
    },
    [instances.play]
  );

  const postTakes = useCallback(
    async (takes: Partial<Take>[]) => {
      const result = await instances.play.post('project/create-takes', takes);

      return result.data;
    },
    [instances.play]
  );

  const deleteTake = useCallback(
    async (takeId: string) => {
      const result = await instances.play.delete(
        `project/delete-take?id=${takeId}`
      );
      return result.data.props;
    },
    [instances.play]
  );

  const deleteLines = useCallback(
    async (lineIds: string[]) => {
      const result = await instances.play.delete(
        `project/delete-lines?ids=${lineIds.join(',')}`
      );
      return result.data.props;
    },
    [instances.play]
  );

  const fetchVoiceProfiles = useCallback(async () => {
    const result = await instances.play.get(
      '/casting-view/list-voice-profiles'
    );
    return result.data;
  }, [instances.play]);

  const generatePreviewTTS = useCallback(
    async (params: TTSPreviewParams & { signal?: AbortSignal }) => {
      const { signal, ...restParams } = params;
      const result = await instances.play.post(
        '/casting-view/generate-preview-tts',
        restParams,
        {
          responseType: 'arraybuffer',
          signal,
        }
      );
      return result.data;
    },
    [instances.play]
  );

  return {
    getVoiceLibrary,
    createTtsZeroShot,
    getUploadInfo,
    getResourceInfo,
    createTts,
    createCvc,
    createTtsForVoice,
    checkTrial,
    fetchProject,
    fetchProjects,
    postProject,
    putProject,
    deleteProject,
    putLine,
    fetchLines,
    postLine,
    deleteLine,
    putTake,
    fetchTakes,
    postTake,
    postTakes,
    deleteTake,
    deleteLines,
    postLines,
    fetchVoiceProfiles,
    generatePreviewTTS,
  };
};

export default useAxios;
