import Checkbox from '@/components/Checkbox/Checkbox';
import { useVoiceLibraryQuery } from '@/query/useVoiceLibraryQuery';
import { Grey } from '@/styles/Colors';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ReactComponent as LineAddIcon } from '../assets/icons/LineAddIcon.svg';
import { ReactComponent as LinePlayIcon } from '../assets/icons/LinePlayIcon.svg';
import { ReactComponent as LineStopIcon } from '../assets/icons/LineStopIcon.svg';
import { useGenerateInfo } from '../hooks/useGenerateInfo';
import {
  TakeOption,
  useEditorContext,
} from '../providers/EditorContextProvider';
import { activeLineIdAtom } from '../stores/atoms/project';
import { selectedLineIdsAtom } from '../stores/atoms/ui';
import { Line } from '../stores/project';
import { getThumbnailPath } from '../VoiceLibrary/utils';
import LineEditor from './LineEditor';
import { StyledLineItem } from './StyledLineItem';
import TakeItem from './TakeItem';
import useSingleTakePlay from './useSingleTakePlay';

const LineItem: React.FC<{ num: number; line: Line }> = ({ line, num }) => {
  const selectedLineIds = useRecoilValue(selectedLineIdsAtom);
  const { voiceProfileList } = useVoiceLibraryQuery();
  const [selectedLineId, setSelectedLineId] = useRecoilState(activeLineIdAtom);

  const isOpen = useMemo(
    () => selectedLineId === line.id,
    [selectedLineId, line.id]
  );

  // draft 선택 여부를 관리
  const {
    audioBufferTakeLists,
    removeTake,
    addLine,
    updateDraft,
    toggleSelectedLineIds,
    addTakes,
    selectedTakeByLine,
    selectTakeId,
  } = useEditorContext();
  const audioBufferTakeListByLineId = useMemo(
    () => audioBufferTakeLists.filter((take) => take.lineId === line.id),
    [audioBufferTakeLists, line]
  );
  const reversedTakes = useMemo(
    () => audioBufferTakeListByLineId.slice().reverse(),
    [audioBufferTakeListByLineId]
  );
  const activeTake = useMemo(() => {
    return audioBufferTakeListByLineId.find(
      (take) => take.id === selectedTakeByLine[line.id]
    );
  }, [audioBufferTakeListByLineId, line.id, selectedTakeByLine]);

  // 테이크 선택시, 선택 take로 라인 정보 교체
  const onSelect = useCallback(
    (takeId: string) => {
      selectTakeId(line.id, takeId);
    },
    [line.id, selectTakeId]
  );

  const selectedTake = useMemo(() => {
    return audioBufferTakeListByLineId.find(
      (take) => take.id === selectedTakeByLine[line.id]
    );
  }, [audioBufferTakeListByLineId, line.id, selectedTakeByLine]);

  const lineText = useMemo(() => {
    if (isOpen) {
      return selectedTake?.text ?? line.draft?.text;
    } else {
      return line.draft?.text ?? selectedTake?.text;
    }
  }, [isOpen, line.draft?.text, selectedTake?.text]);

  // 라인 선택시
  const onSelectLine = useCallback(() => {
    if (isOpen || selectedLineId === line.id) return;
    setSelectedLineId(line.id);
    // selectedTake(defaultTake)가 있을 경우, 해당 take를 기본 take로 선택
    if (selectedTake?.id) {
      onSelect(selectedTake.id);
    } else {
      // selectedTake가 없을 경우, takes가 있을 경우 첫번째 take를 선택
      if (audioBufferTakeListByLineId[0]) {
        onSelect(audioBufferTakeListByLineId[0].id);
      }
    }
  }, [
    audioBufferTakeListByLineId,
    isOpen,
    line.id,
    onSelect,
    selectedLineId,
    selectedTake?.id,
    setSelectedLineId,
  ]);

  const onDeleteTake = useCallback(
    (id: string) => {
      removeTake(id);
    },
    [removeTake]
  );

  // open 상태에 따른 데이터 노출 셋업
  // line.isOpen 이 false면
  // 1. selectedTake를 먼저 노출
  // 2. selectedTake가 없으면 draft를 노출
  // 3. draft도 없으면 공백 노출, voice는 defaultVoice로 설정
  const profile = useMemo(() => {
    const defaultVoiceProfile = voiceProfileList?.[0];
    // draft가 있으면 draft의 voice를 선택한다.
    const voiceId = isOpen
      ? line.draft?.voiceId || selectedTake?.voiceId
      : selectedTake?.voiceId || line.draft?.voiceId;

    return (
      voiceProfileList?.find((profile) =>
        profile.voices.some(({ id }) => id === voiceId)
      ) || defaultVoiceProfile
    );
  }, [voiceProfileList, isOpen, line.draft?.voiceId, selectedTake?.voiceId]);

  const hasItem = useMemo(
    () => !!audioBufferTakeListByLineId.length,
    [audioBufferTakeListByLineId]
  );

  const onClickNewLine = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      addLine(line);
    },
    [addLine, line]
  );

  const { language, voiceId, style, controlState } = useGenerateInfo();

  const onUpdate = useCallback(
    async (text: string) => {
      addTakes({
        text,
        voiceId,
        language,
        // text update로 인한 generate는 tts고정
        type: 'tts',
        style: style,
        parameter: { ...controlState },
      } as TakeOption);
    },
    [voiceId, language, style, controlState, addTakes]
  );

  const profileIconPath = useMemo(
    () => profile && getThumbnailPath(profile),
    [profile]
  );
  const onClickCheckLineItem = useCallback(() => {
    toggleSelectedLineIds(line.id);
  }, [line.id, toggleSelectedLineIds]);

  const { handlePlayStop, isPlaying } = useSingleTakePlay(
    selectedTakeByLine[line.id] as string
  );
  return (
    <StyledLineItem
      onClick={onSelectLine}
      className={classNames(!selectedTakeByLine[line.id] && 'no-takes')}
    >
      <section
        className={classNames(
          'line-editor-wrap',
          isOpen && 'selected',
          !hasItem && 'empty'
        )}
      >
        <section className="line-editor-left">
          <span className="line-checkbox" onClick={(e) => e.stopPropagation()}>
            <Checkbox
              color={Grey[400]}
              onChange={onClickCheckLineItem}
              checked={selectedLineIds.some((id) => id === line.id)}
            />
          </span>
          <section className="line-num">{num}</section>
          <section className="line-voice">
            {profile?.thumbnails?.length && (
              <span className="thumbnail">
                <img src={profileIconPath} alt={profile?.name} />
              </span>
            )}
          </section>
          {(isOpen && activeTake?.type !== 'cvc') || !line.draft?.sourceId ? (
            <LineEditor
              line={line}
              text={lineText ?? ''}
              onUpdate={onUpdate}
              isOpen={isOpen}
            />
          ) : (
            <section
              className="line-editor cvc"
              onClick={() => {
                onSelectLine();
                updateDraft(line.id, {
                  text: '',
                  sourceId: undefined,
                });
              }}
            >
              This take generated by audio file
            </section>
          )}
        </section>
        <section className="line-button">
          {!isOpen && hasItem && (
            <span className="line-play">
              <button
                color="transparent"
                className="btn-play"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePlayStop();
                }}
              >
                {isPlaying ? <LineStopIcon /> : <LinePlayIcon />}
              </button>
            </span>
          )}
        </section>
      </section>
      {isOpen && hasItem && (
        <section className="line-takes">
          {reversedTakes.map((take, index) => (
            <TakeItem
              key={take.id}
              isSelected={take.id === selectedTakeByLine[line.id]}
              onSelect={onSelect}
              isActive={take.id === selectedTakeByLine[line.id]}
              take={take}
              num={`${num}-${reversedTakes.length - index}`}
              deleteTake={onDeleteTake}
            />
          ))}
        </section>
      )}
      <section className="line-add">
        <button className="add" onClick={onClickNewLine}>
          <LineAddIcon />
        </button>
      </section>
    </StyledLineItem>
  );
};
export default LineItem;
