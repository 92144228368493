import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import { useEditorContext } from '@/pages/screenplay/providers/EditorContextProvider';
import { currentProjectAtom } from '@/pages/screenplay/stores/atoms/project';
import UserInfo from '@/user/UserInfo';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ReactComponent as EditNameIcon } from '../assets/icons/EditNameIcon.svg';
import { isShowLineBannerAtom } from '../stores/atoms/ui';
import LineBanner from './LineBanner';

const SceneWriterHeader = () => {
  const { updateProject } = useEditorContext();

  const navigate = useNavigate();
  const currentProject = useRecoilValue(currentProjectAtom);
  const isShowLineBanner = useRecoilValue(isShowLineBannerAtom);
  const [isEditProjectName, setIsEditProjectName] = useState(false);

  const textRef = useRef<HTMLTextAreaElement>(null);
  const updateTitle = useCallback(async () => {
    if (textRef.current?.innerText.trim()) {
      await updateProject({
        ...currentProject,
        name: textRef.current?.innerText.trim(),
      });
      setIsEditProjectName(false);
    }
  }, [textRef, updateProject, setIsEditProjectName, currentProject]);
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      e.stopPropagation();
      if (e.code === 'Enter') {
        e.preventDefault();
        updateTitle();
      }
      if (e.code === 'Escape') {
        setIsEditProjectName(false);
      }
    },
    [setIsEditProjectName, updateTitle]
  );
  const onBlur = useCallback(() => {
    updateTitle();
  }, [updateTitle]);
  useEffect(() => {
    if (isEditProjectName) {
      if (textRef?.current) {
        textRef.current.focus();
        let range = document.createRange();
        let sel = window.getSelection();
        range.selectNodeContents(textRef.current as Node);
        sel?.removeAllRanges();
        sel?.addRange(range);
      }
    }
  }, [isEditProjectName, textRef]);

  const goBackProjectList = useCallback(() => {
    navigate('/projects');
  }, [navigate]);

  return (
    <header className="layout-header">
      {isShowLineBanner && <LineBanner />}
      <Button
        size="xl"
        color="transparent"
        className="btn-back-project-list"
        onClick={goBackProjectList}
      >{`<- Back to project`}</Button>
      <h1 className="project-name">
        {isEditProjectName ? (
          <section className="title-text-edit">
            <section
              className="text-editor"
              ref={textRef}
              contentEditable={isEditProjectName}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              dangerouslySetInnerHTML={{ __html: currentProject?.name ?? '' }}
            />
          </section>
        ) : (
          currentProject?.name && (
            <section className="project-name-label">
              <span>{currentProject.name}</span>
              <IconButton
                onClick={() => setIsEditProjectName(true)}
                color="transparent"
                className="btn-edit-project-name"
              >
                <EditNameIcon />
              </IconButton>
            </section>
          )
        )}
      </h1>
      <UserInfo />
    </header>
  );
};
export default SceneWriterHeader;
