import AlertModal from '@/components/Modal/AlertModal/AlertModal';
import ScriptModal from '@/components/Modal/ScriptModal/ScriptModal';
import ToastMessage from '@/components/ToastMessage/ToastMessage';
import { AudioFileMap } from '@/pages/screenplay/stores/audios';
import { useWebSocketContext } from '@/providers/WebSocketProvider';
import { useVoiceLibraryQuery } from '@/query/useVoiceLibraryQuery';
import { fetchAudio, getAudioBuffer } from '@/util/audio';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactComponent as QuestionBalloon } from '../screenplay/assets/icons/QuestionBalloon.svg';
import FullScreenLoader from './components/FullScreenLoader';
import GlobalAudioPlayer from './GlobalAudioPlayer/GlobalAudioPlayer';
import useAxios from './hooks/useAxios';
import { useDataContext } from './providers/DataContextProvider';
import EditorContextProvider from './providers/EditorContextProvider';
import VoicePanel from './QuickMenuPanel/VoicePanel';
import SceneWriterPanel from './SceneWriterPanel/SceneWriterPanel';
import { audioFileMapAtom } from './stores/atoms/audio';
import {
  currentProjectAtom,
  lineListAtom,
  takeListAtom,
} from './stores/atoms/project';
import {
  HEADER_HEIGHT,
  isLoadingProjectAtom,
  isModalOpenAtom,
  isShowLineBannerAtom,
  isShowVoiceLibraryAtom,
  showScriptModalAtom,
  toastMessageAtom,
} from './stores/atoms/ui';
import { ZENDESK_URL } from './stores/data/config';
import { Line, Parameter, Take } from './stores/project';
import { useResetUI } from './stores/recoilHooks/useResetUI';
import StyledScreenPlay from './styles/StyledScreenPlay';
import VoiceLibrary from './VoiceLibrary/VoiceLibrary';

const Editor = () => {
  const { sessionId } = useWebSocketContext();
  const { takeJobIdMap, updateTakeJobIdMap } = useDataContext();
  const { projectId } = useParams();
  const {
    voiceProfileList,
    isLoading: isLoadingVoiceLibrary,
    isError: isErrorVoiceLibrary,
  } = useVoiceLibraryQuery();
  const { fetchProject, fetchLines, fetchTakes, createTts, getResourceInfo } =
    useAxios();

  const [isLoadingProject, setIsLoadingProject] =
    useRecoilState(isLoadingProjectAtom);
  const [isModalOpen, setIsModalOpen] = useRecoilState(isModalOpenAtom);
  const [isShowScriptModal, setScriptModalOpen] =
    useRecoilState(showScriptModalAtom);

  const [isShowLineBanner, setisShowLineBanner] =
    useRecoilState(isShowLineBannerAtom);
  const isShowVoiceLibrary = useRecoilValue(isShowVoiceLibraryAtom);
  const [audioFileMap, setAudioFileMap] = useRecoilState(audioFileMapAtom);
  const [currentProject, setCurrentProject] =
    useRecoilState(currentProjectAtom);
  const toastMessage = useRecoilValue(toastMessageAtom);
  const { resetUI } = useResetUI();

  const navigate = useNavigate();

  const setLineList = useSetRecoilState(lineListAtom);
  const setTakeList = useSetRecoilState(takeListAtom);

  const initProject = useCallback(async () => {
    if (!projectId) {
      return false;
    }
    const project = await fetchProject(projectId);
    if (!project) {
      return false;
    }
    setIsLoadingProject(true);

    setCurrentProject(project);

    const lines = await fetchLines(projectId);
    setLineList([...lines]);

    // take list 호출
    const takes = await fetchTakes(lines.map((line: Line) => line.id));
    setTakeList([...takes]);
    if (takes.length) {
      let newMap: AudioFileMap = {};
      let count = 0;
      await Promise.all(
        takes.map(async (take: Take) => {
          if (
            take.id &&
            !take.resourceId &&
            !takeJobIdMap[take.id] &&
            !audioFileMap[take.id]
          ) {
            // todo generating 도중에 이탈한 것으로 간주하여 해당 take를 재생성한다.
            // cvc여부에 따라 호출 필요, 이후 중복 로직 hook으로 분리
            try {
              const res = await createTts(
                {
                  text: take.text,
                  language: take.language,
                  voice_id: take.voiceId,
                  parameters: {
                    ...(take.parameter as Parameter),
                  },
                  take_count: 1,
                },
                sessionId
              );
              const {
                data: {
                  data: { job_id },
                },
              } = res as any;
              updateTakeJobIdMap({ [take.id]: job_id });
            } catch (error) {
              return;
            }
          }
          if (
            audioFileMap[take.id] ||
            (take.resourceId && audioFileMap[take.resourceId])
          )
            return;
          try {
            let url = (take as any).url;
            if (!url) {
              if (!take.resourceId) return;
              const { data } = await getResourceInfo(take.resourceId);
              url = data.data.transcoded[0].url;
            }
            if (url) {
              const audio = await fetchAudio(url);
              const audioBuffer = (await getAudioBuffer(
                audio.arrayBuffer
              )) as AudioBuffer;

              newMap[take.id] = { audioBuffer };

              if (count === takes.length - 1) {
                setIsLoadingProject(false);
              } else {
                count++;
              }
            }
          } catch (error) {
            console.error('Failed to load resource');
            setIsLoadingProject(false);
          }
        })
      );
      setAudioFileMap((prev) => {
        return {
          ...prev,
          ...newMap,
        };
      });
    }
    // 프로젝트 정보를 셋팅, 전체 프로젝트 아이템을 참조하는 곳에서 refresh 호출
    setIsLoadingProject(false);
    return true;
  }, [
    projectId,
    fetchProject,
    setIsLoadingProject,
    setCurrentProject,
    fetchLines,
    setLineList,
    fetchTakes,
    setTakeList,
    setAudioFileMap,
    takeJobIdMap,
    audioFileMap,
    createTts,
    sessionId,
    updateTakeJobIdMap,
    getResourceInfo,
  ]);

  useEffect(() => {
    if (!projectId) {
      navigate('/projects');
      return;
    }
    if (!currentProject && !isErrorVoiceLibrary && !isLoadingVoiceLibrary) {
      initProject().then((isSuccess) => {
        if (!isSuccess) {
          navigate('/projects');
        } else {
          setisShowLineBanner(true);
        }
      });
    }
    return () => {
      // setSelectedProjectId(null);
      // resetActiveInfo();
    };
  }, [
    projectId,
    currentProject,
    isErrorVoiceLibrary,
    isLoadingVoiceLibrary,
    initProject,
    setisShowLineBanner,
    navigate,
  ]);

  useEffect(() => {
    // Editor 페이지를 벗어날때, ui 초기화
    return () => {
      // 프로젝트 변경시 UI 초기화
      resetUI();
      // 프로젝트 변경 시 데이터 초기화
      setLineList([]);
      setTakeList([]);
      setCurrentProject(null);
      // resetActiveInfo && resetActiveInfo();
    };
  }, [resetUI, setCurrentProject, setLineList, setTakeList]);

  const headerHeight = useMemo(
    () => (isShowLineBanner ? HEADER_HEIGHT * 2 : HEADER_HEIGHT),
    [isShowLineBanner]
  );

  if (!currentProject || !voiceProfileList || isLoadingVoiceLibrary) {
    return null;
  }

  return (
    <StyledScreenPlay>
      {isLoadingProject && <FullScreenLoader />}
      {!isLoadingProject && (
        <EditorContextProvider>
          <section className="sp-middle-area">
            <section className="sp-middle-top">
              <section className="scene-editor">
                <section
                  className="scene-editor-top"
                  style={{
                    height: `calc(100vh - ${headerHeight}rem`,
                  }}
                >
                  <VoicePanel />
                  <SceneWriterPanel />
                </section>
              </section>
              {isShowVoiceLibrary && <VoiceLibrary />}
            </section>
            <div className="sp-bottom-area">
              <a
                target="_blank"
                href={ZENDESK_URL}
                rel="noreferrer"
                className="help"
              >
                <QuestionBalloon />
                <span className="text">Help</span>
              </a>
              <GlobalAudioPlayer />
              {toastMessage && <ToastMessage />}
            </div>
          </section>
          {isShowScriptModal && (
            <ScriptModal isOpen onClose={() => setScriptModalOpen(false)} />
          )}
        </EditorContextProvider>
      )}
      {isModalOpen && (
        <AlertModal
          isOpen={isModalOpen}
          onConfirm={() => setIsModalOpen(false)}
          message="현재 버전에서는 사용할 수 없는 기능입니다."
          confirmText="OK"
        />
      )}
    </StyledScreenPlay>
  );
};
export default Editor;
