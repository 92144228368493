import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import { ReactComponent as Meme } from '@/pages/screenplay/assets/images/meme.svg';
import {
  activeLineIdAtom,
  currentProjectAtom,
} from '@/pages/screenplay/stores/atoms/project';
import { useVoiceLibraryQuery } from '@/query/useVoiceLibraryQuery';
import { Grey } from '@/styles/Colors';
import { isShowMemeIcon } from '@/util/voiceLabel';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ReactComponent as SimplePlayIcon } from '../assets/icons/SimplePlayIcon.svg';
import { ReactComponent as SimpleStopIcon } from '../assets/icons/SimpleStopIcon.svg';
import { ReactComponent as DeleteIcon } from '../assets/images/DeleteIcon.svg';
import { useLog } from '../hooks/useLog/useLog';
import useSingleAudioController from '../hooks/useSingleAudioController';
import useVoiceProfile from '../hooks/useVoiceProfile';
import { useEditorContext } from '../providers/EditorContextProvider';
import { audioPlayerStateAtom } from '../stores/atoms/audio';
import { useToggleVoiceLibrary } from '../stores/recoilHooks/useToggles';
import { useVoice } from '../stores/recoilHooks/useVoice';
import { Profile } from '../stores/voice';
import StyledVoiceProfile from '../styles/StyledVoiceProfile';
import LoadingDots from '../VoiceLibrary/Loading';
import { getThumbnailPath } from '../VoiceLibrary/utils';

const VoicesTab = () => {
  const { t } = useTranslation();
  const { voiceProfileList } = useVoiceLibraryQuery();
  const activeLineId = useRecoilValue(activeLineIdAtom);
  const currentProject = useRecoilValue(currentProjectAtom);
  const [audioPlayerState, setAudioPlayerState] =
    useRecoilState(audioPlayerStateAtom);

  const { deleteVoice } = useVoice();

  const { updateDraft, usedProfileIdList } = useEditorContext();
  const { toggleVoiceLibrary } = useToggleVoiceLibrary();
  const { loadVoiceFile } = useVoiceProfile();
  const { play, stop, isPlaying } = useSingleAudioController();
  const [playingProfileId, setPlayingProfileId] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const { track } = useLog();
  const playAudio = useCallback(
    async (profile: Profile) => {
      const voice = profile.voices.find(
        (v) => v.id === profile.default_voice_id
      );
      if (!voice) return;
      if (isPlaying && playingProfileId === profile.id) {
        stop();
        setPlayingProfileId(null);
        return;
      }
      setPlayingProfileId(profile.id);
      setIsLoading(true);

      const defaultVoice = profile.voices.find(
        (v) => v.id === profile.default_voice_id
      );
      if (!defaultVoice) return;

      const { audioBuffer } = await loadVoiceFile(defaultVoice.sample);
      setIsLoading(false);
      play(0, audioBuffer);

      track('PREVIEW_PLAY', {
        profileId: profile.id,
        voice_name: profile.name,
        voiceId: profile.default_voice_id,
      });
      setAudioPlayerState({
        type: 'standalone',
        voiceId: profile.id,
        isPlaying: true,
      });
    },
    [
      loadVoiceFile,
      play,
      stop,
      isPlaying,
      playingProfileId,
      setAudioPlayerState,
      track,
    ]
  );

  const changeProfile = useCallback(
    (profile: Profile) => {
      updateDraft(activeLineId as string, {
        voiceId: profile.default_voice_id,
        language: profile.language,
      });
    },
    [activeLineId, updateDraft]
  );

  useEffect(() => {
    if (!audioPlayerState) return;
    if (
      (audioPlayerState.type === 'timeline' && audioPlayerState.isPlaying) ||
      (audioPlayerState.type === 'standalone' &&
        audioPlayerState.takeId &&
        audioPlayerState.isPlaying)
    ) {
      stop();
    }
  }, [
    playingProfileId,
    audioPlayerState,
    stop,
    isPlaying,
    setAudioPlayerState,
  ]);

  const handleOpenVoiceLibrary = useCallback(() => {
    if (isPlaying) {
      stop();
    }
    track('CLICK_ADD_NEW_VOICE');
    toggleVoiceLibrary();
  }, [toggleVoiceLibrary, isPlaying, stop, track]);
  const profileList = useMemo(() => {
    return currentProject?.voiceIds?.map((voiceId: string) => {
      const profile = voiceProfileList?.find((voiceProfile) => {
        return voiceProfile.id === voiceId;
      });
      return profile;
    });
  }, [currentProject?.voiceIds, voiceProfileList]);

  return (
    <section className="voice-tab">
      <section className="create-new">
        <Button
          size="lg"
          color="transparent"
          className="btn-create-new-item"
          onClick={handleOpenVoiceLibrary}
        >
          {t('Add New Voice')}
        </Button>
      </section>
      <section className="voice-list">
        <ul>
          {profileList?.map((profile: Profile | undefined) => {
            if (!profile) return null;
            const thumbnailPath = getThumbnailPath(profile);
            const hasDefaultVoice = profile.voices.some(
              (voice) => voice.id === profile.default_voice_id
            );

            return (
              <li key={profile.id}>
                <section
                  className={classNames('list-item voice', {
                    disabled: !hasDefaultVoice,
                  })}
                  onClick={() => {
                    activeLineId && hasDefaultVoice && changeProfile(profile);
                  }}
                >
                  <StyledVoiceProfile className="voice-profile">
                    <button
                      className={classNames('voice-image', {
                        empty: !thumbnailPath,
                        disabled: isLoading && playingProfileId !== profile.id,
                      })}
                      style={
                        thumbnailPath
                          ? {
                              backgroundImage: `url(${thumbnailPath})`,
                            }
                          : {}
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        playAudio(profile);
                      }}
                    >
                      <>
                        {(!isLoading || playingProfileId !== profile.id) &&
                          (isPlaying && playingProfileId === profile.id ? (
                            <SimpleStopIcon className="icon icon-stop" />
                          ) : (
                            <SimplePlayIcon className="icon icon-play" />
                          ))}
                        {isLoading && playingProfileId === profile.id && (
                          <LoadingDots />
                        )}
                      </>
                    </button>
                    <span className="voice-name">{profile.displayName}</span>
                    {isShowMemeIcon(profile) && (
                      <span className={'meme-icon'}>
                        <Meme />
                      </span>
                    )}
                  </StyledVoiceProfile>
                  <span className="list-buttons">
                    <IconButton
                      color={Grey[50]}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteVoice(profile.id);
                      }}
                      disabled={
                        (voiceProfileList && voiceProfileList.length <= 1) ||
                        usedProfileIdList?.includes(profile.id)
                      }
                      className="btn-voice-icon btn-voice-delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </section>
              </li>
            );
          })}
        </ul>
      </section>
    </section>
  );
};
export default VoicesTab;
