import { HOME_STYLES } from '@/consts';
import useAxios from '@/pages/screenplay/hooks/useAxios';
import { Profile, Voice } from '@/pages/screenplay/stores/voice';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

interface ProfilesResponse {
  status: string;
  data: Profile[];
}

const getBaseStyle = (style: string) => style.split('-')[0]; // ex: Happy-1 -> Happy

export const useVoiceProfilesQuery = () => {
  const { fetchVoiceProfiles } = useAxios();
  const { isLoading, isError, data } = useQuery<ProfilesResponse>({
    queryKey: ['voiceProfiles'],
    queryFn: fetchVoiceProfiles,
    staleTime: 1000 * 60 * 30, // 30분
  });

  // useAxios 에서 데이터를 변형하면 다양한 형태를 사용하기 힘들기 때문에 여기서 데이터 변형
  const flattenedVoiceProfiles = useMemo(() => {
    if (!data?.data) return [];

    return data.data.flatMap((profile: Profile) =>
      profile.voices
        .map((voice: Voice) => ({
          id: voice.id,
          language: profile.language,
          thumbnailPath: profile.thumbnails[0]?.path || '',
          script: voice.script,
          sample: voice.sample,
          style: getBaseStyle(voice.style),
        }))
        .filter((voice) => HOME_STYLES.includes(voice.style))
    );
  }, [data]);

  const uniqueStyles = useMemo(() => {
    if (!flattenedVoiceProfiles) return [];

    return Array.from(
      new Set(flattenedVoiceProfiles.map((profile) => profile.style))
    );
  }, [flattenedVoiceProfiles]);

  return {
    flattenedVoiceProfiles,
    uniqueStyles,
    isLoading,
    isError,
  };
};
