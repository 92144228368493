export const FontWeight = {
  Regular: 400,
  Medium: 500,
  SemiBold: 600,
  Bold: 700,
} as const;

export const FontSizeRatio = 0.8;
export const BasicFontSize = 16 * FontSizeRatio;

export const FontSize = {
  Xs: '0.625rem', // 10px * 0.8 = 8px
  Sm: '0.75rem', // 12px * 0.8 = 9.6px
  Md: '0.875rem', // 14px * 0.8 = 11.2px
  Lg: '0.94rem', // 15px * 0.8 = 12px
  Xl: '1.1rem', // 18px * 0.8 = 14.4px
  '2Xl': '1.25rem', // 20px * 0.8 = 16px
  '3Xl': '1.5rem', // 24px * 0.8 = 19.2px
  '4Xl': '1.875rem', // 30px * 0.8 = 24px
  '5Xl': '2.25rem', // 36px * 0.8 = 28.8px
  '6Xl': '3rem', // 48px * 0.8 = 38.4px
  '7Xl': '3.75rem', // 60px * 0.8 = 48px
  '8Xl': '4.5rem', // 72px * 0.8 = 57.6px
  '9Xl': '6rem', // 96px * 0.8 = 76.8px
  '10Xl': '8rem', // 128px * 0.8 = 102.4px
  '28px': '1.75rem',
} as const;

export const FontFamily = {
  Inter: '"Inter", sans-serif',
} as const;
