import { DEFAULT_LANGUAGE } from '@/consts';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import screenPlay_en from './en.json';
import screenPlay_ja from './ja.json';
import screenPlay_ko from './ko.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: screenPlay_en,
      },
      ko: {
        translation: screenPlay_ko,
      },
      ja: {
        translation: screenPlay_ja,
      },
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupFromPathIndex: 0,
      convertDetectedLanguage: (lng) => {
        return lng.split('-')[0];
      },
    },
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false,
    },
  });
