import HomeBanner from '@/assets/images/HomeBanner.png';
import { SMALL_TABLET_BREAKPOINT } from '@/consts/breakpoints';
import { BannerBlue, Grey } from '@/styles/Colors';
import styled from '@emotion/styled';

export const StyledBanner = styled.section`
  max-width: 1396px;
  margin-top: 52px;
  width: calc(100% - 7.5rem);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${SMALL_TABLET_BREAKPOINT}px) {
    width: 100%;
    padding: 0;
    margin-top: 0;
  }

  .banner {
    background-color: ${BannerBlue};
    width: 100%;
    height: 108px;
    border-radius: 8px;
    padding: 16px;

    background-image: url(${HomeBanner});
    background-size: cover;
    background-position: top 25px center;
    background-repeat: no-repeat;

    @media (max-width: ${SMALL_TABLET_BREAKPOINT}px) {
      border-radius: 0px;
      padding: 8px 16px;
      height: 74px;
    }
    :hover {
      cursor: pointer;
    }
  }

  h1 {
    color: white;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;

    @media (max-width: ${SMALL_TABLET_BREAKPOINT}px) {
      font-size: 16px;
    }
  }

  p {
    color: ${Grey[100]};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    @media (max-width: ${SMALL_TABLET_BREAKPOINT}px) {
      font-size: 14px;
    }
  }
`;
