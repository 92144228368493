import { Black, Grey } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import { HEADER_HEIGHT } from '../stores/atoms/ui';

export const StyledSceneWriterLayout = styled.section`
  display: flex;
  .gnb-menu {
    margin-top: ${HEADER_HEIGHT}rem;
  }
  .line-banner {
    position: fixed;
    top: 0;
    width: 100%;
    height: ${HEADER_HEIGHT}rem;
    background: linear-gradient(
      90deg,
      #0a5bff -3.62%,
      #5380db -3.62%,
      #7357e1 22.98%,
      #0a5bff 44.5%,
      #0a5bff 51.36%,
      #785bcf 70.84%,
      #0a5bff 102.77%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    .line-banner-text {
      color: ${Grey[50]};
      text-align: center;
      font-weight: 500;
      a {
        margin-left: 2rem;
        color: ${Grey[50]};
        text-decoration: underline;
        font-weight: ${FontWeight.Bold};
      }
    }
    .btn-exit {
      position: absolute;
      right: 1rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .layout-header {
    background-color: ${Black};
    height: ${HEADER_HEIGHT}rem;
    border-bottom: 1px solid ${Grey[450]};
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-back-project-list {
      width: 14rem;
    }
    .project-name {
      width: 100%;
      font-size: 1.25rem;
      text-align: center;
      .title-text-edit {
        display: inline-flex;
        gap: 0.5rem;
        align-items: center;
        &::after {
          content: ' ';
          visibility: hidden;
          width: 1.5rem;
          height: 1.5rem;
        }
        .text-editor {
          height: 1.2rem;
          font-size: 1.25rem;
          background-color: transparent;
          border: 0;
          outline: 0;
          color: ${Grey[50]};
        }
      }
      .project-name-label {
        display: inline-flex;
        gap: 0.5rem;
        align-items: center;
        .btn-edit-project-name {
          visibility: hidden;
          width: 1.5rem;
          height: 1.5rem;
        }
        &:hover {
          .btn-edit-project-name {
            visibility: visible;
          }
        }
      }
    }
  }
  .layout-content {
    margin-top: ${HEADER_HEIGHT}rem;
    display: flex;
    flex: auto;
    justify-content: center;
    width: calc(100% - 7.5rem);
    .sp-middle-area {
      height: calc(100vh - ${HEADER_HEIGHT}rem);
    }
  }
  &.line-banner-open {
    // 위에 피드백 높이만큼 밑으로 내림
    .layout-header {
      top: ${HEADER_HEIGHT}rem;
    }
    .layout-content {
      margin-top: ${HEADER_HEIGHT * 2}rem;
      .sp-middle-area {
        height: calc(100vh - ${HEADER_HEIGHT * 2}rem);
      }
    }
    .gnb-menu {
      margin-top: ${HEADER_HEIGHT * 2}rem;
    }
  }
`;
