import { ReactComponent as ArrowDownIcon } from '@/assets/icons/ArrowDownIcon.svg';
import { ReactComponent as CheckIcon } from '@/assets/icons/CheckIcon.svg';
import {
  Option,
  OptionsList,
  SelectContainer,
  SelectedOption,
} from '@/components/Select/StyledSupSelect';
import { useEffect, useRef, useState } from 'react';

interface OptionType {
  value: string;
  label: string;
}

interface SupSelectProps {
  options: OptionType[];
  value: string | undefined;
  onChange: (value: string) => void;
}

export const SupSelect = ({ options, value, onChange }: SupSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const selectedOption = options.find((option) => option.value === value);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <SelectContainer ref={selectRef}>
      <SelectedOption isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {selectedOption?.label}
        <ArrowDownIcon />
      </SelectedOption>
      <OptionsList className={isOpen ? 'open' : ''}>
        {options.map((option) => (
          <Option
            key={option.value}
            onClick={() => {
              onChange(option.value);
              setIsOpen(false);
            }}
          >
            {option.label}
            {value === option.value && <CheckIcon />}
          </Option>
        ))}
      </OptionsList>
    </SelectContainer>
  );
};
