import { ReactComponent as LogoCursor } from '@/assets/icons/logoCursor.svg';
import PromotionLink from '@/pages/screenplay/Home/PromotionLink';
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICES_URL,
} from '@/pages/screenplay/stores/data/config';
import { StyledMobileFooter } from '@/pages/screenplay/styles/StyledMobileFooter';
import React from 'react';

export default function MobileFooter() {
  return (
    <StyledMobileFooter>
      <div className="footer-header">
        <div className="slogan">Words into a living voice</div>
        <LogoCursor className="logo-cursor" />
      </div>
      <div className="description">
        Join Supertone Play to tell your stories with voices full of emotion.
      </div>
      <PromotionLink />
      <div className="terms-policy">
        By signing in and signing up, you agree to Supertone’s{' '}
        <a
          target={'_blank'}
          href={TERMS_OF_SERVICES_URL}
          className="link"
          rel="noreferrer"
        >
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          target={'_blank'}
          href={PRIVACY_POLICY_URL}
          className="link"
          rel="noreferrer"
        >
          Privacy Policy.
        </a>
      </div>
    </StyledMobileFooter>
  );
}
