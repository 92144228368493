import { MOBILE_BREAKPOINT } from '@/consts/breakpoints';
import { Grey } from '@/styles/Colors';
import styled from '@emotion/styled';

export const StyledHeader = styled.div`
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 70px;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      margin-top: 32px;
    }
  }

  .title-container {
    font-size: 28px;
    font-weight: 700;
    line-height: 150%;
    color: ${Grey[100]};
    width: 100%;

    word-break: keep-all;
    overflow-wrap: break-word;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: 20px;
    }
  }

  .title1 {
    background: linear-gradient(90deg, #5ba2ff 29.19%, #79d875 82.47%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    .title1 {
      display: inline;
    }

    .title2 {
      display: inline;
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 46px;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      gap: 24px;
    }
  }

  .share {
    min-width: 89px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: ${Grey[100]};

    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      gap: 4px;
    }
  }
`;
